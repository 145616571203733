function getNullColor() {
    return "919191"
}
function getCountColorsheet() {
    /* Provides predifined colors for up to 4 different count datatypes.
    Count values do not use minColor because they are always positive */
    const countColorsheet = [
        {
            minColor: "000000",  //Green, new
            midColor: "ffffff",
            maxColor: "00a800",
            nullColor: "919191"
        },
        {
            minColor: "000000",
            midColor: "ffffff",
            maxColor: "a80000",
            nullColor: "919191"
        },
        {
            minColor: "000000",
            midColor: "ffffff",
            maxColor: "7600a8",
            nullColor: "919191"
        },
        {
            minColor: "000000",
            midColor: "ffffff",
            maxColor: "a84c00",
            nullColor: "919191"
        },
    ] 
    /*
    const countColorsheet = [
        {
            minColor: "000000",
            midColor: "ffffff",
            maxColor: "008210",
            nullColor: "919191"
        },
        {
            minColor: "000000",
            midColor: "ffffff",
            maxColor: "9e000",
            nullColor: "919191"
        },
        {
            minColor: "000000",
            midColor: "ffffff",
            maxColor: "a5790a",
            nullColor: "919191"
        },
        {
            minColor: "000000",
            midColor: "ffffff",
            maxColor: "00379e",
            nullColor: "919191"
        },
    ] 
    */
    return countColorsheet;
}

function getFoldChangeColorsheet() {
    /* Provides predifined colors for up to 3 different fold change datatypes. */
    const foldChangeColorsheet = [
        {
            minColor: "000000",  //Green, new
            midColor: "ffffff",
            maxColor: "00a800",
            nullColor: "919191"
        },
        {
            minColor: "0006a8",
            midColor: "ffffff",
            maxColor: "a80000",
            nullColor: "919191"
        },
        {
            minColor: "00a832",
            midColor: "ffffff",
            maxColor: "7600a8",
            nullColor: "919191"
        },
        {
            minColor: "00a8a0",
            midColor: "ffffff",
            maxColor: "a84c00",
            nullColor: "919191"
        },
    ] 
    /*
    const foldChangeColorsheet = [
        {
            minColor: "00379e",
            midColor: "ffffff",
            maxColor: "9e0000",
            nullColor: "919191"
        },
        {
            minColor: "478f65",
            midColor: "ffffff",
            maxColor: "ad007f",
            nullColor: "919191"
        },
        {
            minColor: "006666",
            midColor: "ffffff",
            maxColor: "a5790a",
            nullColor: "919191"
        },
        {
            minColor: "670067",
            midColor: "ffffff",
            maxColor: "00CE00",
            nullColor: "919191"
        }
    ]
    */
    return foldChangeColorsheet;
}

function datatypeFoldChangeColorsheet(targetDatatype, allDatatypes) {
    let index = allDatatypes.indexOf(targetDatatype);
    const foldChangeColorsheet = getFoldChangeColorsheet();
    index = index%foldChangeColorsheet.length;
    return foldChangeColorsheet[index];
}

function datatypeCountColorsheet(targetDatatype, allDatatypes) {
    let index = allDatatypes.indexOf(targetDatatype);
    const countColorsheet = getCountColorsheet();
    index = index%countColorsheet.length;
    return countColorsheet[index];
}

function countColor(value,
                    scaleMinimum,
                    scaleMaximum,
                    targetDatatype,
                    allDatatypes) {
    /* For a given count value and datatype, calculate the appropriate color */
    const { minColor, midColor, maxColor, nullColor } = datatypeCountColorsheet(targetDatatype, allDatatypes);
    const color  = getColor(value, scaleMinimum, scaleMaximum, minColor, midColor, maxColor, nullColor);
    return color
}

function foldChangeColor(value,
                        scaleMinimum,
                        scaleMaximum,
                        targetDatatype,
                        allDatatypes) {
    /* For a given fold change value and datatype, calculate the appropriate color */
    const { minColor, midColor, maxColor, nullColor } = datatypeFoldChangeColorsheet(targetDatatype, allDatatypes);
    const color  = getColor(value, scaleMinimum, scaleMaximum, minColor, midColor, maxColor, nullColor);
    return color
}

function getColor(
    value,
    scaleMinimum,
    scaleMaximum,
    minColor,
    midColor,
    maxColor,
    nullColor)
    /* Calculates a hex color representation of the input value */
{
    //Check edge conditions of value null and value 0
    if (value === null) {
        return '#'+nullColor;
    } else if (value === 0 || value === 0.0) {
        return '#'+midColor;
    }
    //Calculate the percentage of the value between the scale minimum and maximum
    let percentage;
    if (value < 0) {
        if (value < scaleMinimum) {
            percentage = 0;
        } else {
            percentage = (1 - (value / scaleMinimum));
        }
    } else {
        if (value > scaleMaximum) {
            percentage = 1;
        } else {
            percentage = (value / scaleMaximum);
        }
    }
    //Get the red/blue/green values for the min/mid/max colors
    const minRed = parseInt(minColor.substring(0,2), 16);
    const minGreen = parseInt(minColor.substring(2,4), 16);
    const minBlue = parseInt(minColor.substring(4,6), 16);
    const midRed = parseInt(midColor.substring(0,2), 16);
    const midGreen = parseInt(midColor.substring(2,4), 16);
    const midBlue = parseInt(midColor.substring(4,6), 16);
    const maxRed = parseInt(maxColor.substring(0,2), 16);
    const maxGreen = parseInt(maxColor.substring(2,4), 16);
    const maxBlue = parseInt(maxColor.substring(4,6), 16);
    let redDiffStr, greenDiffStr, blueDiffStr;
    //calculate red, green and blue substrings for the color
    if (value < 0) {
        const redDiff = midRed - minRed;
        const greenDiff = midGreen - minGreen;
        const blueDiff = midBlue - minBlue;
        redDiffStr = ( ( redDiff * percentage) + minRed).toString(16).split('.')[0];
        greenDiffStr = ( ( greenDiff * percentage) + minGreen).toString(16).split('.')[0];
        blueDiffStr = ( ( blueDiff * percentage) + minBlue).toString(16).split('.')[0]; 
    } else {
        const redDiff = maxRed - midRed;
        const greenDiff = maxGreen - midGreen;
        const blueDiff = maxBlue - midBlue;
        redDiffStr = ( ( redDiff * percentage) + midRed).toString(16).split('.')[0];
        greenDiffStr = ( ( greenDiff * percentage) + midGreen).toString(16).split('.')[0];
        blueDiffStr = ( ( blueDiff * percentage) + midBlue).toString(16).split('.')[0]; 
    }
    //colors have to be 2 digits
    if (redDiffStr.length === 1) redDiffStr = '0' + redDiffStr;
    if (greenDiffStr.length === 1) greenDiffStr = '0' + greenDiffStr;
    if (blueDiffStr.length === 1) blueDiffStr = '0' + blueDiffStr;
    //assemble the resulting color string as a hex value
    const hexColor = '#' + redDiffStr + greenDiffStr + blueDiffStr;
    return hexColor;
}

function drawFoldChangeColorScale(  canvasContext,
                                    style,
                                    targetDatatype,
                                    allDatatypes,
                                    logScaled,
                                    label,
                                    scaleMinimum,
                                    scaleMid,
                                    scaleMaximum,
                                    xLeft,
                                    steps=9,
                                    totalHeight=200,
                                    totalWidth=30,
                                    yMargin=30,
                                    textOffset=10)
{
    const { minColor, midColor, maxColor, nullColor } = datatypeFoldChangeColorsheet(targetDatatype, allDatatypes);
    drawScale(  canvasContext,
                style,
                logScaled,
                label,
                scaleMinimum,
                scaleMid,
                scaleMaximum,
                minColor,
                midColor,
                maxColor,
                nullColor,
                xLeft,
                steps,
                totalHeight,
                totalWidth,
                yMargin,
                textOffset);
}

function drawCountColorScale(   canvasContext,
                                style,
                                targetDatatype,
                                allDatatypes,
                                logScaled,
                                label,
                                scaleMinimum,
                                scaleMid,
                                scaleMaximum,
                                xLeft,
                                steps=9,
                                totalHeight=200,
                                totalWidth=10,
                                yMargin=30,
                                textOffset=10) {
    const { minColor, midColor, maxColor, nullColor } = datatypeCountColorsheet(targetDatatype, allDatatypes);
    drawScale(  canvasContext,
        style,
        logScaled,
        label,
        scaleMinimum,
        scaleMid,
        scaleMaximum,
        minColor,
        midColor,
        maxColor,
        nullColor,
        xLeft,
        steps,
        totalHeight,
        totalWidth,
        yMargin,
        textOffset);
}

function writeText(canvasContext,
                   text,
                   xLeft,
                   yTop) {
    /* Write text with black outline and white infill */
    canvasContext.fillStyle = 'black';
    canvasContext.strokeText(text, xLeft, yTop);
    canvasContext.fillStyle = 'white';
    canvasContext.fillText(text, xLeft, yTop);
}
                   

function drawScale(canvasContext,
                   style,
                   logScaled,
                   label,
                   scaleMinimum,
                   scaleMid,
                   scaleMaximum,
                   minColor,
                   midColor,
                   maxColor,
                   nullColor,
                   xLeft,
                   steps=9,
                   totalHeight=200,
                   totalWidth=10,
                   yMargin=30,
                   textOffset=10)
{
    /* Draws a colorscale for the selected datatype onto a canvas. scaleType
       has to be either "count" or "foldChange" */
    const rectHeight = totalHeight / steps;
    const valueSteps = (parseFloat(scaleMaximum) - parseFloat(scaleMinimum)) / (steps - 1);
    for (let i = 0; i < steps; i++) {
        const value = parseFloat(scaleMinimum) + (valueSteps * i);
        const color = getColor(value, parseFloat(scaleMinimum), parseFloat(scaleMaximum), minColor, midColor, maxColor, nullColor);
        const yTop = yMargin - ((i+1)*rectHeight) + totalHeight;
        canvasContext.fillStyle = color;
        canvasContext.beginPath();
        canvasContext.fillRect(
            xLeft + (totalWidth / 4),
            yTop,
            totalWidth / 2,
            rectHeight + 1);
    }
    canvasContext.stroke();
    canvasContext.beginPath();    
    let adjustedLabel = label;
    if (logScaled) {
        adjustedLabel = 'log2(' + label + ')';
    }
    
    canvasContext.strokeStyle = style.borderColor;
    canvasContext.lineWidth = style.borderWidth;
    canvasContext.strokeWidth = style.borderWidth;
    writeText(canvasContext,
        scaleMaximum,
        Math.round(xLeft+(totalWidth/2)),
        Math.round(yMargin-textOffset)
    );
    writeText(canvasContext,
        scaleMid,
        Math.round(xLeft+(totalWidth/2)),
        Math.round(yMargin + ( (steps/2) *rectHeight))
    );
    writeText(canvasContext,
        scaleMinimum,
        Math.round(xLeft+(totalWidth/2)),
        Math.round(yMargin + (steps*rectHeight) + textOffset)
    );
    writeText(canvasContext,
        adjustedLabel,
        Math.round(xLeft+(totalWidth/2)),
        Math.round(yMargin + (steps*rectHeight) + textOffset*2)
    )
    canvasContext.stroke();
}

export { countColor, foldChangeColor, drawFoldChangeColorScale, drawCountColorScale	}