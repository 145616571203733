import React from "react";
import MultiSelect from  'react-multiple-select-dropdown-lite';
import  'react-multiple-select-dropdown-lite/dist/index.css';

import MagFilterForm from "./MagFilterForm";
import LoadingPlaceholder from "../general/LoadingPlaceholder";
import MagColumnOptions from "./MagColumnOptions";


import "../../css/general.css"

import { MagPopup, toggleMagPopup } from "../general/MagPopup";
import { TaxonomyPopup, toggleTaxonomyPopup } from "../general/TaxonomyPopup";
import ComparisonOptionsDropdown from "../general/ComparisonOptionsDropdown";
import MagTable from "./MagTable";
import { getStringSeparators } from "../../functions/StringConversion";
import { fetchMagDetails } from "../../functions/Fetching";
import TaxonomyOptions from "./TaxonomyOptions";

class Mags extends React.Component {

    constructor(props) {
        super(props);

        this.relAbundanceColorMax = 6.0; /* this is the percent relative
        abundance at the maximum of the colorscale */

        

        this.flaskAdress = this.props.flaskAdress;
        this.fetchGetInit = { //fetchGetInit from props does not work for some reason, time is short and this works fine
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + this.props.token
            }
        }

        
        this.taxonomicLevels = ["domain", "phylum", "class", "order", "family", "genus", "species"];
        this.defaultTaxonomicIndices = [ 1, 2, 3, 4, 5]; 
        //Careful: the default values have to be manually adjusted in TaxonomyOptions. Some weird bugs with the dropdown library happen when trying to pass it as a prop.

        this.state = {
            magPopupEnabled: false,
            loadingMetadata: true,
            magRows: [],
            //showingDefaultTaxonomy: true,
            metadata: {
                datatypeSamples: {}
            },
            filters: {
                minCompleteness: 0,
                maxContamination: 999,
                datatypeMinimums: {}
            },
            selectedAbundanceColumns: {
            },
            selectedTaxonomyColumns: {

            },
            columnHeaders: [],
        }
        this.changeMinCompleteness = this.changeMinCompleteness.bind(this);
        this.changeMaxContamination = this.changeMaxContamination.bind(this);
        this.changeDatatypeMinimum = this.changeDatatypeMinimum.bind(this);
        this.changeAbundanceColumnSelection = this.changeAbundanceColumnSelection.bind(this);
        this.changeTaxonomyColumnSelection = this.changeTaxonomyColumnSelection.bind(this);
        this.makeColumnHeaders = this.makeColumnHeaders.bind(this);
        this.toggleMagPopup = toggleMagPopup.bind(this);
        this.fetchMagDetails = fetchMagDetails.bind(this);
        
    }

    componentDidMount() {
        this.fetchMetadata();
        this.changeTaxonomyColumnSelection(this.defaultTaxonomicIndices, true);
    }

    /*
    toggleMagPopup(mag) {
        if (!this.state.magPopupEnabled) {
            this.fetchMagDetails(this.props.token, mag);
        }
        this.setState({
            ...this.state,
            magPopupEnabled: !this.state.magPopupEnabled,
        });
    }
    */

    fetchMetadata() {
        this.setState({...this.state, loadingMetadata: true});
        let metadataUrl = this.props.flaskAdress + "mags/metadata";
        fetch(metadataUrl, this.fetchGetInit)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Error fetching MAGs metadata");
                }
            })
            .then( data => {
                this.props.refreshToken(data);
                this.updateMetadata(data)
            })
            .catch(error => this.setState({...this.state, errorMessage: error.message, loadingMetadata: false}));

    }

    fetchMagData() {
        this.setState({...this.state, loadingMagData: true});
        let datatypeMinAbundanceString = ""
        for (let datatype in this.state.filters.datatypeMinimums) {
            datatypeMinAbundanceString += datatype + ":" + this.state.filters.datatypeMinimums[datatype] + ";"
        }
        if (datatypeMinAbundanceString === "") {
            datatypeMinAbundanceString = ";"
        }
        let magDataUrl = this.props.flaskAdress + "mags/mag_data"
                         + "/" + this.state.filters.minCompleteness
                         + "/" + this.state.filters.maxContamination
                         + "/" + datatypeMinAbundanceString;
        fetch(magDataUrl, this.fetchGetInit)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Error fetching MAGs data");
                }
            })
            .then( data => {
                this.props.refreshToken(data);
                this.updateMagData(data)
            })
            .catch(error => this.setState({...this.state, errorMessage: error.message, loadingMagData: false}));
    }

    updateMetadata(data) {
        this.setState({
            metadata: data,
            loadingMetadata: false
        },
        () => {
            this.fetchMagData();
            this.makeColumnHeaders();
        });
    }

    updateMagData(data) {
        this.setState({
            ...this.state,
            magRows: data
        }, () => {
            this.makeColumnHeaders();
        });
    }
    


    makeMenuItems(itemList) {
        let results = [];
        for (let i=0; i<itemList.length; i++) {
            results[i] = {label: itemList[i], value: i};
        }
        return results;
    }

    changeMinCompleteness(value) {
        this.setState({
            ...this.state,
            filters: {
                ...this.state.filters,
                minCompleteness: value
            }
        },
        () => {
            this.fetchMagData();
        });
    }

    changeMaxContamination(value) {
        this.setState({
            ...this.state,
            filters: {
                ...this.state.filters,
                maxContamination: value
            }
        },
        () => {
            this.fetchMagData();
        });
    }

    changeDatatypeMinimum(datatype, value) {
        this.setState({
            ...this.state,
            filters: {
                ...this.state.filters,
                datatypeMinimums: {
                    ...this.state.filters.datatypeMinimums,
                    [datatype]: value
                }
            }
        },
        () => {
            this.fetchMagData();
        });
    }

    changeAbundanceColumnSelection(datatype, choices) {
        let newSelectedAbundanceColumns = this.state.selectedAbundanceColumns;
        const datatypeSamples = this.state.metadata.datatypeSamples[datatype];
        let newDatatypeSamples = []
        for (let i=0; i<choices.length; i++) {
            newDatatypeSamples.push(datatypeSamples[choices[i]]);
        }
        newSelectedAbundanceColumns[datatype] = newDatatypeSamples;
        this.setState({
            ...this.state,
            selectedAbundanceColumns: newSelectedAbundanceColumns
        }, () => {
            this.makeColumnHeaders();
        })
    }

    changeTaxonomyColumnSelection(choiceIndex, isDefault) {
        /*
        let showingDefaultTaxonomy;
        if (isDefault === true) {
            showingDefaultTaxonomy = true;
        } else {
            showingDefaultTaxonomy = false
        }
        */
        const newSelectedTaxonomyColumns = []
        for (let i=0; i<choiceIndex.length; i++) {
            newSelectedTaxonomyColumns.push(this.taxonomicLevels[choiceIndex[i]]);
        }
        this.setState({
            ...this.state,
            //showingDefaultTaxonomy: showingDefaultTaxonomy,
            selectedTaxonomyColumns: newSelectedTaxonomyColumns
        }, () => {
            this.makeColumnHeaders();
        })
    }



    makeColumnHeaders() {
        // Dynamically calculate the width for the MAG column based on the length of the MAG names
        const maxMagLength = Math.max(...this.state.magRows.map(row => row.mag.length));
        const magColumnWidth = Math.min(Math.max(maxMagLength * 7.5, 70), 200); // Adjust the multiplication factor and min/max width as needed

        //Add the fixed column headers
        let headerColumns = [
            {
                Header: "MAG", accessor: "mag", colored: false, width: magColumnWidth,
                Cell: tableProps => (
                    <div>
                        <input
                            className="mini-button"
                            type="button"
                            value={tableProps.value}
                        />
                    </div>
                )
            },
            { Header: "compl.", accessor: "completeness", colored: false, width:70 },
            { Header: "contam.", accessor: "contamination", colored: false, width:70 },
        ]
        //Add taxonomy column headers
        for (let i=0; i<this.state.selectedTaxonomyColumns.length; i++) {
            const taxonomy = this.state.selectedTaxonomyColumns[i];
            const newCol = {
                Header: taxonomy,
                accessor: taxonomy,
                colored: false,
                width: 100,
            };
            headerColumns.push(newCol);
        }
        //Add abundance column headers
        const {datatypeSeparator, conditionSeparator, genericSeparator} = getStringSeparators();
        const datatypes = Object.keys(this.state.selectedAbundanceColumns);

        for (let i=0; i<datatypes.length; i++) {
            const datatype = datatypes[i];
            const samples = this.state.selectedAbundanceColumns[datatype];
            for (let j=0; j<samples.length; j++) {
                const sample = samples[j];
                const datatypeSample = datatype + datatypeSeparator + sample;
                const newCol = {
                    Header: datatypeSample,
                    accessor: datatypeSample,
                    colored: true,
                    datatypeIndex: i,
                    width: 70,
                    datatype: datatype,
                };
                headerColumns.push(newCol);
                // console.log("im pushing to headercolumns")
                // console.log(newCol)
                // console.log("which is now")
                // console.log(headerColumns)
            }
        }

        this.setState({
            ...this.state,
            columnHeaders: headerColumns
        },
        () => {
            
        })
    }


    render() {
        if (this.state.loadingMetadata || this.state.metadata === undefined) {
            return (
                <LoadingPlaceholder/>
            )
        }

        const taxonomicLevelMenuItems = this.makeMenuItems(this.taxonomicLevels); 
        const datatypes = Object.keys(this.state.metadata.datatypeSamples);
        const leftWidth = 350;
        return (
            <div className="padded_flex" style={{"width": leftWidth+"px"}}>
                <div >
                    <div className="padded2">

                        <div className="padded2">
                            Filtering
                        </div>
                        <MagFilterForm
                            datatypes={datatypes}
                            changeMinCompleteness={this.changeMinCompleteness}
                            changeMaxContamination={this.changeMaxContamination}
                            changeDatatypeMinimum={this.changeDatatypeMinimum}
                        /><br/>
                        <div className="padded2">
                            Show Taxonomy
                        </div>
                        <div className="menuBox">
                            <TaxonomyOptions
                                options={taxonomicLevelMenuItems}
                                width={leftWidth}
                                defaultValue={this.defaultTaxonomicIndices.map((index) => 
                                    {
                                        return(
                                        {
                                            label: this.taxonomicLevels[index],
                                            value: index 
                                        })
                                    })}
                                change={ this.changeTaxonomyColumnSelection }
                            />
                        </div><br/>
                        <div className="padded2">
                            Show Abundances
                        </div>
                        <MagColumnOptions
                            singleSelect={false}
                            width={leftWidth}
                            datatypeSamples={ this.state.metadata.datatypeSamples }
                            changeSelection={ this.changeAbundanceColumnSelection }
                        />
                    </div>
                </div>
                <div>
                    <div className="padded2">
                        MAGs
                    </div>
                    <div className="padded2">
                        <div className="menuBox">
                            <MagTable
                                headers={this.state.columnHeaders}
                                rows={this.state.magRows}
                                rowClick={(mag) => {
                                    //console.log("MAGS: clicked on mag "+mag)
                                    this.toggleMagPopup(mag)
                                }}
                                selectedMag={null}
                                coloringEnabled={true}
                                maxRelAbundancePercent={this.relAbundanceColorMax}
                                allDatatypes={Object.keys(this.state.metadata.datatypeSamples)}
                            />
                        </div>
                    </div>
                </div>
                <MagPopup
                    mag={this.state.selectedMag}
                    magData={this.state.magDetails}
                    isOpen={this.state.magPopupEnabled}
                    onRequestClose={this.toggleMagPopup}
                />
            </div>
        )
    }
}

export default Mags;

/* <div className="padded2">
Taxonomic Level
</div>
<div className="menuBox">
<ComparisonOptionsDropdown
    singleSelect={true}
    width={leftWidth-4}
    menuItems={taxonomicLevelMenuItems}
    change={(choices) => this.setState({
        ...this.state,
        taxonomicLevel: choices[0]
    })}
/>
</div><br/> */