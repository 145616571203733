import React from "react";
import { useMemo } from "react";
import { useTable } from "react-table";

function SimpleGenericTable(props) {


    /*
    const datatypeSeparator = ': ';
    const genericSeparator = '_';
    const precision = 5;
    */

    /*const columns = useMemo(
        () => [
            {Header: 'sample', accessor: 'sample', width: "50%"},
            {Header: '% abundance', accessor: 'abundance', width: "50%"}
        ]);
    */

    const columns = useMemo(
        () => props.header, [props.header]
    )


    /*
    const roundValue = (value) => {
        if (value === null) {
            return("n/a");
        } else {
            return(value.toFixed(precision));
        }
    }
    */

    
    const data = useMemo(
        () => props.data, [props.data]
    );
    
   
    //const data = props.rows;
    //const data = [{sample: "a", abundance: 1}, {sample: "b", abundance: 2}];

    /*
    const data = () => {
        if (Object.keys(props.magAbundanceData).length === 0) {
            return [];
        } else {
            const rows = props.magAbundanceData.map((item) => {
                const {condition, name, percent_of_community, type} = item;
                const sampleName = type
                                + datatypeSeparator
                                + condition
                                + genericSeparator
                                + name;
                return {
                    sample: sampleName,
                    abundance: roundValue(percent_of_community),
                }
            });
            return rows;
        }
    }
    */

    const tableInstance = useTable({columns, data});
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;



    return (
        <div className="generalTable">
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps({
                                    style: {width: column.width}
                                })}
                                    
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps({
                                        style: {width: cell.column.width}
                                    })}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )

}

export default SimpleGenericTable;