function updateKoMagData(newData) {
    // Get the current sample selection order
    const sampleOrder = this.state.koMagSampleSelection;

    // Sort the newData.values based on the sampleOrder
    newData.values.sort((a, b) => {
        return sampleOrder.indexOf(a.sample) - sampleOrder.indexOf(b.sample);
    });

    this.setState({
        ...this.state,
        koMagRenderData: newData,
        loadingMags: false
    });
}

export {updateKoMagData};