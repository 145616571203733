import { makeScopeMagAbundanceTableRows } from './Scope.jsx';

function fetchKoDetails(token) {
    this.setState({...this.state, koDetailsLoading: true});
    let url = this.flaskAdress + "/ko_details/" + this.state.selectedKo;
    fetch(url, getInit(token))
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Failed to fetch ko Details: '+url);
            }
        })
        .then(data => {
            this.props.refreshToken(data);
            this.setState({
                ...this.state,
                koDetails: data,
                koDetailsLoading: false
            })
        })
        .catch(error => {
            this.setState({...this.state, koDetailsLoading: false, error: error});
        });
};

function fetchMagDetails(token, mag) {
    this.setState({...this.state, magDetailsLoading: true});
    let url = this.flaskAdress + "/mag_details/" + mag;
    fetch(url, getInit(token))
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Failed to fetch mag Details: '+url);
            }
        })
        .then(data => {
            this.props.refreshToken(data);
            this.setState({
                ...this.state,
                magDetails: data,
                selectedMag: mag,
                magDetailsLoading: false
            })
        })
        .catch(error => {
            this.setState({...this.state, magDetailsLoading: false, error: error});
        });
}

function fetchMagFilterAbundance(token, mag, callback) {
    let url = this.flaskAdress + "/mag_details/" + mag;
    fetch(url, getInit(token))
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch mag Details: '+url);
        }
      })
      .then(data => {
        this.props.refreshToken(data);
        const scopeMagAbundanceTableRows = makeScopeMagAbundanceTableRows(data.abundance)
        this.setState({
          ...this.state,
          magFilterAbundance: data.abundance,
          scopeMagAbundanceTableRows: scopeMagAbundanceTableRows,
        }, callback);
      })
      .catch(error => {
        this.setState({...this.state, error: error});
      });
  }

function fetchTaxonomyDetails(token, taxonomic_level, taxonomy) {
    this.setState({...this.state, taxonomyDetailsLoading: true});
    let url = this.flaskAdress + "/taxonomy_details/" + taxonomic_level + "/" + taxonomy;
    fetch(url, getInit(token))
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Failed to fetch taxonomy Details: '+url);
            }
        })
        .then(data => {
            this.props.refreshToken(data);
            this.setState({
                ...this.state,
                taxonomyDetails: data,
                taxonomyDetailsLoading: false
            })
        })
        .catch(error => {
            this.setState({...this.state, taxonomyDetailsLoading: false, error: error});
        });
}

function getInit(token) {
    return {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + token
        }
    }
}


export {fetchKoDetails, fetchMagDetails, fetchTaxonomyDetails, fetchMagFilterAbundance};