import { useState } from 'react';

function useToken() {

    const [tokenData, setTokenData] = useState(getTokenData());

    //Retrieve the token and expiration from the local storage
    function getTokenData() {
        const myToken = localStorage.getItem('token');
        const expiration = localStorage.getItem('expiration');
        return {token: myToken, expiration: expiration};
    }

    //Retrieve only the token from local storage
    function getToken() {
        return tokenData.token;
    }

    //Save the token and expiration to the local storage
    function saveTokenData(myToken, lifetime_minutes) {
        const offset = 1 //1 minute offset to avoid expiration
        localStorage.setItem('token', myToken);
        //Determine future expiration date based on lifetime_minutes
        const now = new Date().getTime()
        var expiration = new Date(now + ((lifetime_minutes-offset)*60000));
        localStorage.setItem('expiration', expiration);
        setTokenData({token: myToken, expiration: expiration});
    }

    //Remove the token and expiration from the local storage
    function removeTokenData() {
        localStorage.removeItem('token');
        localStorage.removeItem('expiration');
        setTokenData(null, null);
    }

    //Check if the token is expired
    function isTokenExpired() {
        const expiration = new Date(localStorage.getItem('expiration')).getTime();
        const now = new Date().getTime();
        //console.log("Running isTokenExpired(): expiration = " + expiration);
        //console.log("type of expiration = " + typeof expiration);
        //console.log("Running isTokenExpired(): now = " + now);
        //console.log("type of now = " + typeof now);
        //console.log("result = " + (expiration < now));
        const isExpired = (expiration < now);
        //if (isExpired) {
        //    removeTokenData();
        //}
        return isExpired;
    }

    //Refresh token if "access_token" is present in server response
    function refreshToken(data) {
        if (data.access_token) {
            saveTokenData(data.access_token, data.lifetime_minutes);
            console.log("USETOKEN: Refreshed token, extended expiration by " + data.lifetime_minutes + " minutes");
        }
    }

    return {
        setTokenData: saveTokenData,
        tokenData,
        removeTokenData,
        getToken,
        isTokenExpired,
        refreshToken
    }

}
export default useToken;


/*
<Header token={removeToken} />
{!token && token !== "" && token !== undefined?
<Login setToken={setToken} />
:(
  <Routes>
    <Route exact path="/profile" element={<Profile token={token} setToken={setToken}/>}></Route>
  </Routes>
)}
*/