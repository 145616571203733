import React from 'react';
import ReactModal from 'react-modal';
import "../../css/general.css";
import FancyGenericTable from './FancyGenericTable';
import {koLink, ecLink} from "../../functions/Linkmaker";

class KoPopup extends React.Component {

    constructor(props) {
        super(props);

        this.interfaceScale = 1.0;
        this.scaleWidth = this.scaleWidth.bind(this);

        this.koBaseURL = "https://www.genome.jp/dbget-bin/www_bget?ko+";

        this.modulesTableHeader = [
            { Header: 'Module', accessor: 'module', width: this.scaleWidth(25)},
            { Header: 'Description', accessor: 'description', width: this.scaleWidth(100)},
        ]
        this.genesTableHeader = [
            { Header: 'Gene', accessor: 'gene', width: this.scaleWidth(100)},
            { Header: 'MAG', accessor: 'mag', width: this.scaleWidth(40)},
            { Header: 'Domain', accessor: 'domain', width: this.scaleWidth(60)},
            { Header: 'Phylum', accessor: 'phylum', width: this.scaleWidth(60)},
            { Header: 'Class', accessor: 'class', width: this.scaleWidth(60)},
            { Header: 'Order', accessor: 'order', width: this.scaleWidth(60)},
            { Header: 'Family', accessor: 'family', width: this.scaleWidth(60)},
            { Header: 'Genus', accessor: 'genus', width: this.scaleWidth(60)},
            { Header: 'Species', accessor: 'species', width: this.scaleWidth(60)},
        ]
        this.countTableHeader = [
            { Header: 'Type', accessor: 'type', width: this.scaleWidth(70)},
            { Header: 'Condition', accessor: 'condition', width: this.scaleWidth(100)},
            { Header: 'Sample', accessor: 'sample_name', width: this.scaleWidth(100)},
            { Header: 'Count', accessor: 'count', width: this.scaleWidth(60)},
        ]
        this.foldChangeTableHeader = [
            { Header: 'Type', accessor: 'type', width: this.scaleWidth(70)},
            { Header: 'Base Condition', accessor: 'base_condition', width: this.scaleWidth(100)},
            { Header: 'Experimental Condition', accessor: 'experimental_condition', width: this.scaleWidth(100)},
            { Header: 'log2(FC)', accessor: 'l2fc', width: this.scaleWidth(60)},
            { Header: 'adj. log2(FC)', accessor: 'adjl2fc', width: this.scaleWidth(60)},
            { Header: 'p-value', accessor: 'pvalue', width: this.scaleWidth(60)},
            { Header: 'adj. p-value', accessor: 'adjpvalue', width: this.scaleWidth(60)},
        ]
    }

    scaleWidth(width) {
        return (width * this.interfaceScale);
    }


    render() {
        if (this.props.koData === undefined) {
            return (
                <div></div>
            )
        }
        const koData = this.props.koData;
        //console.log("KOPOPUP: koData: ");
        //console.log(koData);

        return (
            <ReactModal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.onRequestClose}
                contentLabel={"KEGG Orthology" + this.props.ko}
                style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.75)',
                      zIndex: 1000
                    },
                    content: {
                      display: "block",
                      top: "5%",
                      left: "15%",
                      border: '1px solid #ccc',
                      background: '#fff',
                      overflow: 'auto',
                      WebkitOverflowScrolling: 'touch',
                      borderRadius: '4px',
                      outline: 'none',
                      padding: '20px',
                      width: "70%"
                    }
                  }}
            >
                <div className="padded_flex">
                    <div className="padded4 text16">
                        <strong>KEGG Orthology:</strong><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<a href={koLink(this.props.ko)} target="_blank">{this.props.ko}</a><br/>
                        <strong>Shorthand:</strong><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;{koData.shorthand}<br/>
                        <strong>Related ECs:</strong><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;{koData.ec_list && koData.ec_list.map((ec, index) => <span key={index} ><a href={ecLink(ec)} target="_blank">{ec}</a>&nbsp;</span>)}<br/>
                        <strong>Description:</strong><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;{koData.description}<br/>
                    </div>
                    <div style={{"width": "50%", "margin-left": "auto", "margin-right": 0}}>
                        <div className="text16">Orthology Modules</div>
                        <FancyGenericTable
                            header={this.modulesTableHeader}
                            data={koData.modules}
                            customWidth={true}
                            key="modules"
                        />
                    </div>
                </div>
                <div className="padded_flex">
                    <div>
                        <div className="text16">Orthology Counts</div>
                        <FancyGenericTable
                                header={this.countTableHeader}
                                data={koData.counts}
                                customWidth={true}
                                key="counts"
                        />
                    </div>
                    <div>
                        <div className="text16">Orthology Fold Changes</div>
                        <FancyGenericTable
                            header={this.foldChangeTableHeader}
                            data={koData.fold_changes}
                            customWidth={true}
                            key="fold_changes"
                        />
                    </div>

                </div>
                <div>
                    <div className="text16">Orthology Genes</div>
                    <FancyGenericTable
                        header={this.genesTableHeader}
                        data={koData.genes}
                        customWidth={true}
                        key="genes"
                    />
                </div>


            </ReactModal>
        )
    }
}

export default KoPopup;