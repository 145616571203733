import React from "react";
import "../../css/general.css"

class KoSearchForm extends React.Component {

    constructor(props) {
        super(props)
        this.buttonWidth = 100;
        this.formPlaceholder = "KO, EC or keyword";

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
        }
    }

    componentDidMount() {
        if (!((this.props.searchTerm === undefined) || (this.props.searchTerm === ""))) {
            this.setState({
                ...this.state,
                formPlaceholder: this.props.searchTerm
            })
        } else {
            this.setState({
                ...this.state,
                formPlaceholder: this.formPlaceholder
            })
        }
    }

    handleChange(e) {
        this.setState({
            ...this.state,
            searchTerm: e.target.value
        })
    }

    handleSubmit(e) {
        const minLength = 3;
        e.preventDefault();
        const searchTerm = this.state.searchTerm;
        if (searchTerm.length < minLength) {
            alert("Search term needs to have at least "+minLength+" characters.")
        } else {
            //this.props.initiateSearch(this.state.searchTerm);
            this.props.setSearchTerm(this.state.searchTerm)
        }
    }

    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <input
                        className="textInput"
                        type="text"
                        style={{width: (this.props.width - this.buttonWidth)+"px"}}
                        placeholder={this.state.formPlaceholder}
                        value={this.state.searchTerm}
                        onChange={this.handleChange} 
                    />
                    <input
                        className="button"
                        style={{width: this.buttonWidth+"px"}}
                        type="submit"
                        value="Search"
                    />
                </form>
            </div>
        )
    }

}

export default KoSearchForm;