import React, { useState } from 'react';

const Gene = ({ gene, selected, onMouseEnter, onMouseLeave }) => {

  const { start, end, ko, viewerWidth } = gene;
  const width = (end - start) / viewerWidth * 100;
  const left = (start / viewerWidth) * 100;
  //const width = (end - start + 1);
  //const left = start;
  console.log("Gene with width and left", ko, width, left)
  const backgroundColor = selected ? 'orange' : 'lightblue';

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        position: 'absolute',
        left: `${left}%`,
        width: `${width}%`,
        height: '80%',
        backgroundColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '10%',
      }}
    >
      <span style={{ color: 'black' }}>{ko || 'unknown'}</span>
    </div>
  );
};

const ContigViewer = ({ data }) => {
  const viewerWidth = 2000;

  const [offset, setOffset] = useState(0);
  const [hoveredGene, setHoveredGene] = useState(null);

  const handleMouseDown = (e) => {
    const startX = e.clientX;
    const startOffset = offset;

    const handleMouseMove = (e) => {
      const dx = e.clientX - startX;
      const newOffset = Math.max(0, startOffset - dx);
      console.log("new offset is", newOffset)
      console.log("offset limit is", (data.contigLength-viewerWidth))
      setOffset(Math.min(newOffset, (data.contigLength-viewerWidth)));
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const contigRatio = viewerWidth / data.contigLength;

  return (
    <div>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '50px',
          backgroundColor: 'transparent',
          overflow: 'hidden',
          cursor: 'grab',
        }}
        onMouseDown={handleMouseDown}
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '10%',
            backgroundColor: 'black',
            top: '45%',
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            width: `${(data.contigLength / viewerWidth) * 100}%`,
            height: '100%',
            left: `-${offset}px`,
          }}
        >
          {data.genes.map((gene) => (
            <Gene
              key={gene.id}
              gene={{
                ...gene,
                viewerWidth: viewerWidth
              }}
              selected={gene.id === data.selectedGene}
              onMouseEnter={() => setHoveredGene(gene)}
              onMouseLeave={() => setHoveredGene(null)}
            />
          ))}
        </div>
      </div>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '10px',
          backgroundColor: 'black',
          marginTop: '10px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: `${contigRatio * 100}%`,
            height: '100%',
            backgroundColor: 'rgba(0, 255, 0, 0.5)',
            left: `${(offset / data.contigLength) * 100}%`,
          }}
        ></div>
      </div>
      {hoveredGene && (
        <div
          style={{
            position: 'absolute',
            top: '70px',
            left: '10px',
            backgroundColor: 'white',
            padding: '5px',
            border: '1px solid black',
          }}
        >
          <div>Gene: {hoveredGene.id}</div>
          <div>Length: {hoveredGene.end - hoveredGene.start +1}</div>
          <div>KO: {hoveredGene.ko || 'unknown'}</div>
        </div>
      )}
    </div>
  );
};

export default ContigViewer;