import React from "react";
import ModuleDiagram from "./ModuleDiagram";
import ModuleIdForm from "./ModuleIdForm";
import DisplayOptions from "./DisplayOptions";
import KoDataTable from "./KoDataTable";
import TaxonomicLeveLDropdown from "./TaxonomicLeveLDropdown";
import MultiSelectDropdown from "../general/MultiSelectDropdown";
import KoMagsBarChart from "./KoMagsBarChart";
import KoPopup from "../general/KoPopup";
import { MagPopup, toggleMagPopup } from "../general/MagPopup";
import { TaxonomyPopup, toggleTaxonomyPopup } from "../general/TaxonomyPopup";
import { fetchKoDetails, fetchMagDetails, fetchTaxonomyDetails, fetchMagFilterAbundance } from "../../functions/Fetching";
import { makeScopeMagAbundanceTableRows } from "../../functions/Scope";
import { makeMenuItems } from "../../functions/StringConversion";
import "../../css/general.css";
import LoadingPlaceholder from "../general/LoadingPlaceholder";
import SingleSelectDropdown from "../general/SingleSelectDropdown";
import { MagFilter, toggleShowMagAbundance } from "../general/MagFilter";
import OrthologyDetails from "../general/OrthologyDetails";
import ChoicesPopup from '../general/ChoicesPopup.jsx';
import { updateKoMagData } from "../../functions/KoContributions";



class KeggModules extends React.Component {
    constructor(props) {
        super(props);
        this.fetchGetInit = { //fetchGetInit from props does not work for some reason, time is short and this works fine
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + this.props.token
            }
        }
        this.leftWidth = 280;
        this.defaultModule = "M00001"

        this.flaskAdress = this.props.flaskAdress;
        this.updateMetadata = this.updateMetadata.bind(this);
        this.moduleChangeHandler = this.moduleChangeHandler.bind(this);
        this.fetchModuleData = this.fetchModuleData.bind(this);
        this.updateModuleData = this.updateModuleData.bind(this);
        this.updateRenderData = this.updateRenderData.bind(this);
        this.selectKo = this.selectKo.bind(this);
        this.updateScaleRange = this.updateScaleRange.bind(this);
        this.updateKoMagData = updateKoMagData.bind(this);
        this.changeKoMagTaxonomicLevel = this.changeKoMagTaxonomicLevel.bind(this);
        this.changeKoMagDatatype = this.changeKoMagDatatype.bind(this);
        this.changeKoMagSampleSelection = this.changeKoMagSampleSelection.bind(this);
        this.fetchKoMagData = this.fetchKoMagData.bind(this);
        this.toggleKoPopup = this.toggleKoPopup.bind(this);
        this.toggleMagPopup = toggleMagPopup.bind(this);
        this.fetchKoDetails = fetchKoDetails.bind(this);
        this.fetchMagDetails = fetchMagDetails.bind(this);
        this.toggleTaxonomyPopup = toggleTaxonomyPopup.bind(this);
        this.fetchTaxonomyDetails = fetchTaxonomyDetails.bind(this);
        this.fetchMagFilterAbundance = fetchMagFilterAbundance.bind(this);
        this.makeScopeMagAbundanceTableRows = makeScopeMagAbundanceTableRows.bind(this);
        this.toggleMagFilter = this.toggleMagFilter.bind(this);
        this.setMagFilterValue = this.setMagFilterValue.bind(this);
        this.toggleShowMagAbundance = toggleShowMagAbundance.bind(this);
        this.changeContentType = this.changeContentType.bind(this);
        this.changeDataChoices = this.changeDataChoices.bind(this);
        this.getKoInfo = this.getKoInfo.bind(this);
        this.updateLogScale = this.updateLogScale.bind(this);
        this.fetchModuleSearch = this.fetchModuleSearch.bind(this);
        this.toggleChoicesPopup = this.toggleChoicesPopup.bind(this);



        this.state = {
            loadingModuleData: false,
            choicesPopupEnabled: false,
            countChoices: [],
            comparisonChoices: [],
            logScale: false,
            activeModule: this.defaultModule,
            errorMessage: "no-error",
            activeComparison: "no-comp",
            contentType: "counts",
            moduleChoices: {},
            selectedKo: null,
            koInfoboxData: null,
            choices: null,
            koMagData: null,
            scaleRange: {FoldChange: 3},
            communityScaleRange: {},
            magScaleRange: {},
            koMagRenderData: {samples_without_data: [], taxonomic_level: "", taxonomies: [], values: []},
            koMagTaxonomicLevel: null,
            koMagSampleSelection: null,
            koPopupEnabled: false,
            taxonomyPopupEnabled: false,
            magFilterActive: this.props.magFilterActive,
            magFilterValue: this.props.magFilterValue,
            magFilterAbundance: this.props.magFilterAbundance,
            showMagAbundance: this.props.showMagAbundance,
            scopeMagAbundanceTableRows: this.props.scopeMagAbundanceTableRows,
        };
    }

    componentDidMount() {
        this.fetchMetadata();
        this.fetchModuleData(this.state.activeModule);
    }

    componentWillUnmount() {
        this.props.setMagFilterActive(this.state.magFilterActive);
        this.props.setMagFilterValue(this.state.magFilterValue);
        this.props.setMagFilterAbundance(this.state.magFilterAbundance);
        this.props.setShowMagAbundance(this.state.showMagAbundance);
        this.props.setScopeMagAbundanceTableRows(this.state.scopeMagAbundanceTableRows);
    }

    setMagFilterValue(magIdentifier) {
        //Check if value is legal
        const legalValues = Object.keys(this.state.metadata.mag_attributes);
        if (!(legalValues.includes(magIdentifier) || magIdentifier === "unbinned")) {
            alert("No MAG with name " + magIdentifier + " found.");
            return;
        }
        //Change value
        this.setState(
            {
                ...this.state,
                magFilterValue: magIdentifier
            }, function() {
                this.fetchMagFilterAbundance(this.props.token, this.state.magFilterValue);
                this.fetchModuleData(this.state.activeModule);            }
        );
    }

    moduleChangeHandler(moduleId) {
        this.setState(
            {
                ...this.state,
                activeModule: moduleId,
                choicesPopupEnabled: false,
            },
            function() {
                this.fetchModuleData(moduleId);
            }
        );
    }

    updateScaleRange(contentType, newScaleValue) {
        let newScaleRange = this.state.scaleRange;
        newScaleRange[contentType] = newScaleValue;

        this.setState({...this.state, scaleRange: newScaleRange},
            function() {
            this.updateRenderData();
        })
    }

    /*
    showCounts(choices) {
        this.setState(
            {
                ...this.state,
                choices: choices,
                contentType: "counts"
            },
            function() {
                this.updateRenderData();
            }
        )
    }

    showComparisons(choices) {;
        this.setState(
            {
                ...this.state,
                choices: choices,
                contentType: "compare"
            },
            function() {
                this.updateRenderData();
            }
        )
    }
    */

    updateChoices(oldChoices, newDatatype, newChoices) {
        //Remove any choices that have the input datatype.
        let updatedChoices = oldChoices;
        for (let i = 0; i < updatedChoices.length; i++) {
            if (updatedChoices[i].datatype === newDatatype) {
                updatedChoices.splice(i, 1);
                i--;
            }
        }
        //Add the new choices.
        for (let i = 0; i < newChoices.length; i++) {
            updatedChoices.push(newChoices[i]);
        }
        return updatedChoices;
    }

    changeDataChoices(itemsType, datatype, newChoices) {
        if (!(itemsType === this.contentType)) {
            console.log("WARNING: KeggModules: changeDataChoices: itemsType does not match contentType.")
        }
        let updatedChoices;
        //get old choices and remove any that have the input datatype
        if (itemsType === "counts") {
            updatedChoices = this.updateChoices(
                this.state.countChoices,
                datatype,
                newChoices)
            this.setState({
                ...this.state,
                countChoices: updatedChoices
            }, function() {
                this.updateRenderData();
            });            
        } else if (itemsType === "compare") {
            updatedChoices = this.updateChoices(
                this.state.comparisonChoices,
                datatype,
                newChoices)
            this.setState({
                ...this.state,
                comparisonChoices: updatedChoices
            }, function() {
                this.updateRenderData();
            });
        }
    }



    updateRenderData() {
        /* Updates the data that is used to render the module diagram based
        on the current state of this component. */
        //const displayChoices = this.state.choices;
        let renderData;
        if (this.state.contentType === "counts") {
            const displayChoices = this.state.countChoices;
            renderData = this.state.moduleData.map((nodeData) => {
                // console.log("Data for node is");
                // console.log(nodeData);
                let datatypeValues;
                if (displayChoices === null) {
                    datatypeValues = {datatype: null, value: null};
                } else {
                    datatypeValues = displayChoices.map((choice) => {
                        const {datatype, condition, name} = choice
                        let value = null;
                        if (datatype in nodeData.count_values) {
                            value = nodeData.count_values[datatype][condition][name]
                            if (this.state.logScale[datatype] && value != 0) {
                                if (value < 1) {   
                                    value = 0;
                                } else {
                                    value = Math.log2(value);
                                }
                            }
                        }
                        return {datatype: datatype, value: value}
                    })
                }
                return {
                    ko: nodeData.ko,
                    xleft: nodeData.coordinates.xleft,
                    xright: nodeData.coordinates.xright,
                    ytop: nodeData.coordinates.ytop,
                    ybottom: nodeData.coordinates.ybottom,
                    datatypeValues: datatypeValues
                }
            });
        } else if (this.state.contentType === "compare") {
            const displayChoices = this.state.comparisonChoices;
            let datatypeValues;

            renderData = this.state.moduleData.map((nodeData) => {
                if (displayChoices === null) {
                    datatypeValues = {datatype: null, value: null};
                } else {
                    datatypeValues = displayChoices.map((choice) => {
                        const {datatype, base_condition, experimental_condition, primaryValueType, secondaryValueType} = choice;
                        let value = null;
                        if (datatype in nodeData.fold_change_values) {
                            value = nodeData.fold_change_values[datatype][base_condition][experimental_condition][primaryValueType];
                            if (value === null) {
                                value = nodeData.fold_change_values[datatype][base_condition][experimental_condition][secondaryValueType];
                            }
                        }
                        return {datatype: datatype, value: value}
                    })            
                }
                return {
                    ko: nodeData.ko,
                    xleft: nodeData.coordinates.xleft,
                    xright: nodeData.coordinates.xright,
                    ytop: nodeData.coordinates.ytop,
                    ybottom: nodeData.coordinates.ybottom,
                    datatypeValues: datatypeValues
                }
            });
        } else {
            throw new Error("Illegal contentType: "+this.state.contentType);
        }
        this.setState({
            ...this.state,
            renderData: renderData
        })
    }
    
    // updateRenderData() {
    //     //updates render data based on state

    //     const choices = this.state.choices;
    //     let renderData = null;
    //     console.log("updateRenderData and content type and  module data is");
    //     console.log(this.state.contentType);
    //     console.log(this.state.moduleData);
    //     console.log(this.state.choices);
    //     if (this.state.contentType === "counts") {
    //         renderData = this.state.moduleData.map((nodeData) => {
    //             let datatypeValues = [null];
    //             if (!(choices === null)) {
    //                 datatypeValues = choices.map((comparison) => {
    //                     const {datatype, condition, name} = comparison;
    //                     let value = null;
    //                     if (datatype in nodeData.count_values) {
    //                         value = nodeData.count_values[datatype][condition][name];
    //                     }
    //                     return({"datatype": datatype, "value": value});
    //                 })
    //             }
    //             return(
    //                 {
    //                     ko: nodeData.ko,
    //                     xleft: nodeData.coordinates.xleft,
    //                     xright: nodeData.coordinates.xright,
    //                     ytop: nodeData.coordinates.ytop,
    //                     ybottom: nodeData.coordinates.ybottom,
    //                     values: datatypeValues
    //                 }
    //             );
    //         })
    //     } else if (this.state.contentType === "compare") {
    //         renderData = this.state.moduleData.map((nodeData) => {
    //             let datatypeValues = [null];
    //             if (!(choices === null)) {
    //                 datatypeValues = choices.map((comparison) => {
    //                     const {datatype, base_condition, experimental_condition, primaryValueType, secondaryValueType} = comparison;
    //                     let value = null;
    //                     if (datatype in nodeData.fold_change_values) {
    //                         value = nodeData.fold_change_values[datatype][base_condition][experimental_condition][primaryValueType];
    //                         if (value === null) {
    //                             value = nodeData.fold_change_values[datatype][base_condition][experimental_condition][secondaryValueType];
    //                         }
                            
    //                     }
    //                     return({"datatype": datatype, "value": value});
    //                 })
    //             }
    //             return(
    //                 {
    //                     ko: nodeData.ko,
    //                     xleft: nodeData.coordinates.xleft,
    //                     xright: nodeData.coordinates.xright,
    //                     ytop: nodeData.coordinates.ytop,
    //                     ybottom: nodeData.coordinates.ybottom,
    //                     datatypeValues: datatypeValues
    //                 }
    //             );
    //         })
    //     } else {
    //         const message = "Illegal KeggModules.state.contentType "+this.state.contentType;
    //         throw message;
    //     }
    //     this.setState({
    //         ...this.state,
    //         renderData: renderData
    //     })
    // }

    fetchMetadata() {
    //fetches metadata on mount
        const metadataUrl = this.flaskAdress + "module_map_metadata";

        fetch(metadataUrl, this.fetchGetInit)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch module maps metadata.');
                }
            })
            .then(data => {
                this.props.refreshToken(data);
                this.updateMetadata(data);
            })
            .catch(error => this.setState({...this.state, errorMessage: error.message}));
    }

    toggleChoicesPopup() {
        this.setState({...this.state, choicesPopupEnabled: !this.state.choicesPopupEnabled});
    }

    fetchModuleSearch(searchTerm) {
        /* For the user provided searchTerm, fetch the list of matching results.
           Display the results in a popup and let the user choose one. */
        const url = this.flaskAdress + "module_search/" + searchTerm;
        fetch(url, this.fetchGetInit)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Error fetching module search results");
                }
            })
            .then(data => {
                this.props.refreshToken(data);
                this.setState({
                    ...this.state,
                    moduleChoices: data,
                    choicesPopupEnabled: true
                });
            })
            .catch(error => this.setState({
                ...this.state,
                errorMessage: error.message}));    
    }

    updateMetadata(newMetadata) {
        /* Derives scaleRange and logScale from metadata. Writes them to
           state along with the metadata. */
        let scaleRange = this.state.scaleRange;
        let logScale = {}
        const datatypes = Object.keys(newMetadata.count_ranges);
        datatypes.forEach((datatype) => {
            scaleRange[datatype] = newMetadata.count_ranges[datatype].percentile75;
            logScale[datatype] = false;
        })
        this.setState({
            ...this.state,
            metadata: newMetadata,
            communityScaleRange: scaleRange,
            scaleRange: scaleRange,
            logScale: logScale,
        });
     }

     updateLogScale(datatype, newLogScaleValue) {
        /* Update the log scale for the given datatype */
        let newLogScale = this.state.logScale;
        newLogScale[datatype] = newLogScaleValue;
        // log2 or unlog2 the scaleRange value of the datatype. Set to 1
        // if scaleRange would be <1
        let newScaleRange = this.state.scaleRange;
        if (newLogScaleValue) {
            newScaleRange[datatype] = Math.log2(newScaleRange[datatype]);
            if (newScaleRange[datatype] < 1) {
                newScaleRange[datatype] = 1.0;
            }
        } else {
            newScaleRange[datatype] = Math.pow(2, newScaleRange[datatype]);
        }
        this.setState({
            ...this.state,
            logScale: newLogScale,
            scaleRange: newScaleRange
        }, function() {
            this.updateRenderData();
        });
    }

    fetchModuleData(moduleId) {
        this.setState({...this.state, loadingModuleData: true})
        let moduleUrl;
        if (this.state.magFilterActive) {
            moduleUrl = this.flaskAdress + "mag_module_map/" + this.state.magFilterValue + "/" + moduleId;
        } else {
            moduleUrl = this.flaskAdress + "module_map/" + moduleId;
        }
        fetch(moduleUrl, this.fetchGetInit)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch module data from '+moduleUrl);
                }
            })
            .then(data => {
                this.props.refreshToken(data);
                this.updateModuleData(data, moduleId)
            })
            .catch(error => this.setState({...this.state, errorMessage: error.message, loadingModuleData: false}));
    }

    fetchKoMagData() {
        if (this.state.selectedKo === null || this.state.koMagSampleSelection === null || this.state.koMagTaxonomicLevel === null) {
            return;
        }
        if (this.state.selectedKo === "" || this.state.koMagSampleSelection === "" || this.state.koMagTaxonomicLevel === "") {
            return;
        }
        if (this.state.koMagTaxonomicLevel === undefined || this.state.koMagSampleSelection === undefined) {
            return;
        }
        let samples = this.state.koMagSampleSelection;
        samples = samples.toString();
        const koMagUrl = this.flaskAdress
                         + "mag_ko_plot_data/"
                         + this.state.selectedKo + '/'
                         + this.state.koMagTaxonomicLevel + '/'
                         + this.state.koMagDatatype + '/'
                         + samples;
        const fetchGetInit= { //fetchGetInit from props does not work for some reason, time is short and this works fine
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + this.props.token
            }
        }
        fetch(koMagUrl, fetchGetInit)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch MAG abundances from '+koMagUrl);
                }
            })
            .then(data => {
                this.props.refreshToken(data);
                this.updateKoMagData(data)
            })
            .catch(error => this.setState({...this.state, errorMessage: error.message}))
    }

    /*
    updateKoMagData(newData) {
        this.setState({
            ...this.state,
            koMagRenderData: newData
        })
    }
    */

    selectKo(targetKo) {
        if (targetKo == null) {
            this.setState({
                ...this.state,
                koInfoboxData: null,
                koMagData: null,
                selectedKo: null
            });
            return
        }
        const targetData = this.state.moduleData.find(node => (node["ko"] === targetKo) );
        this.setState({
            ...this.state,
            koInfoboxData: targetData,
            selectedKo: targetKo
            },
            function() {
                this.fetchKoMagData();
                this.fetchKoDetails(this.props.token);
            }
        )

    }

    updateModuleData(newModuleData, newModuleId) {
        const description = newModuleId + ": " + this.state.metadata.modules[newModuleId];
        if (this.state.magFilterActive) {
            //In this case, remove the last element of the moduleData array
            //which is the new value for scaleRange
            const magScaleRange = newModuleData.pop();
            //Add a fold change scale range
            magScaleRange.FoldChange = 3;
            //If a scale range is present in the metadata but not present in the
            //mag data, add a dummy value (1) to the magScaleRange
            for (let datatype in this.state.metadata.count_ranges) {
                if (!(datatype in magScaleRange)) {
                    magScaleRange[datatype] = 1;
                }
            }
            this.setState(
                {
                    ...this.state,
                    moduleData: newModuleData,
                    currentDescription: description,
                    magScaleRange: magScaleRange,
                    scaleRange: magScaleRange,
                    loadingModuleData: false,
                },
                function() {
                    this.updateRenderData();
                }
            );
        } else {
            this.setState(
                {
                    ...this.state,
                    moduleData: newModuleData,
                    currentDescription: description,
                    loadingModuleData: false,
                },
                function() {
                    this.updateRenderData();
                }
            );
        }
    }

    changeKoMagTaxonomicLevel(taxonomicLevel) {
        this.setState({
            ...this.state,
            koMagTaxonomicLevel: taxonomicLevel
        },
        function() {
                this.fetchKoMagData();
        })
    }

    changeKoMagSampleSelection(sampleSelection) {
        this.setState({
            ...this.state,
            koMagSampleSelection: sampleSelection
        },
        function() {
            if (!(this.state.koMagTaxonomicLevel === undefined) && !(this.state.koMagTaxonomicLevel === null)) {
                this.fetchKoMagData();
            }
        })
    }

    toggleKoPopup() {
        this.setState({
            ...this.state,
            koPopupEnabled: !this.state.koPopupEnabled
        })
    }
/*
    toggleMagPopup(mag) {
        console.log(mag)
        if (mag === 'unbinned') {
            alert("The 'unbinned' section represents non-binned contigs. It is not possible to view the MAG details these.")
            return
        }
        if (!this.state.magPopupEnabled) {
            this.fetchMagDetails(this.props.token, mag);
        }
        this.setState({
            ...this.state,
            magPopupEnabled: !this.state.magPopupEnabled,
        });
    }

    toggleTaxonomyPopup(taxonomic_level, taxonomy) {
        if (taxonomy === 'unbinned') {
            alert("The 'unbinned' section represents non-binned contigs. It is not possible to view the taxonomy details these.")
            return
        }
        if (!this.state.taxonomyPopupEnabled) {
            this.fetchTaxonomyDetails(this.props.token, taxonomic_level, taxonomy);
        }
        this.setState({
            ...this.state,
            taxonomyPopupEnabled: !this.state.taxonomyPopupEnabled
        });
    }
*/

    changeKoMagDatatype(datatype) {
        this.setState({
            ...this.state,
            koMagDatatype: datatype
        },
        function() {
            this.fetchKoMagData()
        })
    }

    toggleMagFilter() {
        let newScaleRange, newMagScaleRange, newCommunityScaleRange;
        if (this.state.magFilterActive) {
            newScaleRange = this.state.communityScaleRange;
            newMagScaleRange = this.state.scaleRange;
            newCommunityScaleRange = this.state.communityScaleRange;
        } else {
            newScaleRange = this.state.magScaleRange;
            newMagScaleRange = this.state.magScaleRange;
            newCommunityScaleRange = this.state.scaleRange;
        }
        this.setState(
            {
            ...this.state,
            magFilterActive: !this.state.magFilterActive,
            scaleRange: newScaleRange,
            magScaleRange: newMagScaleRange,
            communityScaleRange: newCommunityScaleRange,
            comparisonChoices: [],
            contentType: "counts",
            renderData: undefined,
            }, function() {
                this.fetchModuleData(this.state.activeModule);
            }
        );
    }

    changeContentType(newType) {
        if (!(newType === "counts" || newType === "compare")) {
            throw new Error("Invalid content type: " + newType);
        }
        if (newType === "compare" && this.state.magFilterActive) {
            alert("Fold change data only available on community level.");
            newType = "counts";
        }
        this.setState({
            ...this.state,
            countChoices: [],
            comparisonChoices: [],
            contentType: newType,
        }, function() {
            this.updateRenderData();
        });
    }

    getKoInfo() {
        let koEcList = [];
        let koModuleList= [];
        let koPathwayList = [];
        let koShorthand = "";
        let koDescription = "";
        if (!(this.state.koDetails === undefined)) {
            koEcList = this.state.koDetails.ec_list;
            koShorthand = this.state.koDetails.shorthand;
            koDescription = this.state.koDetails.description;
            //go through all objects in this.state.koDetails.modules and push the value behind the "module" key to koModuleList
            koModuleList = [];
            for (let i = 0; i < this.state.koDetails.modules.length; i++) {
                koModuleList.push(this.state.koDetails.modules[i].module);
            }
        }
        return {koEcList, koModuleList, koPathwayList, koShorthand, koDescription};
    }

    render() {
        if (this.state.metadata === undefined) {
            return (
                <LoadingPlaceholder/>
            )
        }

        const {koEcList, koModuleList, koPathwayList, koShorthand, koDescription} = this.getKoInfo();

        const leftWidth = this.leftWidth

        let err=this.state.errorMessage;
        //let comp=this.comparisonToString(this.state.activeComparison);
        const infoBoxData = this.state.koInfoboxData;
        let moduleDescriptions, comparisons, count_samples;
        if ( !(this.state.metadata === undefined) && "modules" in this.state.metadata) {
           moduleDescriptions = this.state.metadata.modules;
           comparisons = this.state.metadata.comparisons;
           count_samples = this.state.metadata.count_samples;
        }
        let selectedKo = null;
        if (!(infoBoxData == null)) {
            selectedKo = infoBoxData["ko"]
        }
        let scaleRangeMin, scaleRangeMax;
        if (this.state.contentType === "counts") {
            scaleRangeMin = this.state.scaleRange.countMin;
            scaleRangeMax = this.state.scaleRange.countMax;
        } else if (this.state.contentType === "compare") {
            scaleRangeMin = this.state.scaleRange.foldChangeMin;
            scaleRangeMax = this.state.scaleRange.foldChangeMax;
        } else {
            throw new Error("Illegal contentType "+this.state.contentType);
        }
        let koNotFoundMessage = ""
        if (this.state.koMagRenderData.samples_without_data.length > 0) {
            koNotFoundMessage = "KO not found in the following samples: "
        }
        let mag_abundance_samples = [""];
        if ( !(this.state.metadata === undefined) ) {
            mag_abundance_samples = this.state.metadata.mag_abundance_samples;
        }
        const comparisonMenuItems = makeMenuItems(this.state.metadata.comparisons, "compare");
        const countMenuItems = makeMenuItems(this.state.metadata.count_samples, "counts");
        let koModules;
        if (this.state.koDetails === undefined) {
            koModules = ([]);
        } else {
            koModules = this.state.koDetails.modules.map((moduleData) => {
                return (moduleData.module);
            });
        }

        return (

            <div>
                <div className="kegg_modules">

                    <div className="padded2">
                        Scope
                    <div className="menuBox" style={{width: leftWidth}}>
                        <div style={{"font": "400 14px system-ui"}}>
                            <MagFilter
                                    width={leftWidth}
                                    magFilterActive={this.state.magFilterActive}
                                    magFilterValue={this.state.magFilterValue}
                                    setMagFilterValue={this.setMagFilterValue}
                                    toggleMagFilter={this.toggleMagFilter}
                                    toggleShowMagAbundance={this.toggleShowMagAbundance}
                                    showMagAbundance={this.state.showMagAbundance}
                                    magAbundanceRows={this.state.scopeMagAbundanceTableRows}
                                />                     
                        </div>
                    </div>

                    <br/>
                        Search Modules
                        <ModuleIdForm
                            width={leftWidth}
                            searchModule={(searchTerm) => this.fetchModuleSearch(searchTerm)}
                            changeModule={(moduleId) => this.moduleChangeHandler(moduleId)}
                            moduleDescriptions={moduleDescriptions}
                            moduleId={this.state.activeModule}
                            currentDescription={this.state.currentDescription}
                        />
                        <br/>
                        Show in Nodes
                        <div className="menuBox" style={{width: leftWidth}}>
                            <DisplayOptions
                                width={leftWidth}
                                changeContentType={this.changeContentType}
                                contentType={this.state.contentType}
                                magFilterActive={this.state.magFilterActive}
                                rangeValues={this.state.scaleRange}
                                countItems={this.state.metadata.count_samples}
                                comparisonItems={this.state.metadata.comparisons}
                                changeDataChoices={this.changeDataChoices}
                                changeRange={this.updateScaleRange}
                                logScale={this.state.logScale}
                                changeLogScale={this.updateLogScale}
                            />
                        </div>
                        <br/>
                        {this.state.selectedKo} Values
                        <div className="infobox" style={{width: leftWidth}}>
                            <KoDataTable
                                width={leftWidth}
                                koData={this.state.koInfoboxData}
                                koModules={koModules}
                                comparisons={comparisons}
                                count_samples={count_samples}
                                toggleKoPopup={this.toggleKoPopup}
                            />
                        </div>
                    </div>

                    <div className="padded2">
                        Module Map
                        {this.state.loadingModuleData?
                            <LoadingPlaceholder
                                minWidth={2*leftWidth}
                            />
                            :
                            <ModuleDiagram
                                renderData={this.state.renderData}
                                selectedKo={selectedKo}
                                selectKo={this.selectKo}
                                scaleRange={this.state.scaleRange}
                                contentType={this.state.contentType}
                                logScale={this.state.logScale}
                            />
                        }
                    </div>

                    <div className="padded2" >
                        <div>
                        Selected Orthology
                            <div className="infobox" style={{"overflow": "hidden", "width": "600px", "font": "400 14px system-ui"}}>
                                <OrthologyDetails
                                    width={600}
                                    ko={this.state.selectedKo}
                                    ecList={koEcList}
                                    moduleList={koModuleList}
                                    pathwayList={koPathwayList}
                                    shorthand={koShorthand}
                                    description={koDescription}
                                    toggleKoPopup={this.toggleKoPopup}
                                />
                            </div>
                        </div>
                        <br/>
                        <div>
                            Taxonomic Contributions to {this.state.selectedKo} Abundance
                        </div>
                        <div className="infobox" style={{"width": "605px"}}>

                            <div className="padded4">
                                <div className="padded4" style={{"display": "flex", "font": "400 14 px system-ui"}}>
                                <SingleSelectDropdown
                                    title="Datatype"
                                    change={this.changeKoMagDatatype}
                                    values={Object.keys(this.state.metadata.count_ranges)}
                                    width={120}
                                />
                                <TaxonomicLeveLDropdown
                                    title="Tax. Level"
                                    change={this.changeKoMagTaxonomicLevel}
                                    width={100}
                                />
                                <MultiSelectDropdown
                                    title="Taxonomy Data"
                                    change={this.changeKoMagSampleSelection}
                                    datasets={mag_abundance_samples}
                                    width={370}
                                />
                                </div>
                                <KoMagsBarChart
                                    values={this.state.koMagRenderData.values}
                                    taxonomicLevel={this.state.koMagRenderData.taxonomic_level}
                                    taxonomies={this.state.koMagRenderData.taxonomies}
                                    loading={this.state.loadingMags}
                                    yAxisLabel={this.state.koMagDatatype + " abundance"}
                                    magClick={this.toggleMagPopup}
                                    taxonomyClick={this.toggleTaxonomyPopup}
                                />
                                <div className="padded4" style={{"display": "flex", "font": "400 14 px system-ui"}}>
                                    {koNotFoundMessage}{this.state.koMagRenderData.samples_without_data.join(", ")}
                                </div>

                            </div>
                        </div>
                    </div>
    
                </div>
                <ChoicesPopup
                        isOpen={this.state.choicesPopupEnabled}
                        onRequestClose={() => this.toggleChoicesPopup()}
                        choices={this.state.moduleChoices}
                        commitChoice={(moduleId) => this.moduleChangeHandler(moduleId)}
                /> 
                <KoPopup
                    ko={this.state.selectedKo}
                    koData={this.state.koDetails}
                    isOpen={this.state.koPopupEnabled}
                    onRequestClose={this.toggleKoPopup}
                />
                <MagPopup
                    mag={this.state.selectedMag}
                    magData={this.state.magDetails}
                    isOpen={this.state.magPopupEnabled}
                    onRequestClose={this.toggleMagPopup}
                />
                <TaxonomyPopup
                    data={this.state.taxonomyDetails}
                    isOpen={this.state.taxonomyPopupEnabled}
                    onRequestClose={this.toggleTaxonomyPopup}
                />
            </div>
        )
    }

}

export default KeggModules;
