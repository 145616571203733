import React from "react";
import KoValuesTable from "./KoValuesTable";
import ComparisonOptionsDropdown from "../general/ComparisonOptionsDropdown";
import TaxonomicLeveLDropdown from "../moduleMaps/TaxonomicLeveLDropdown";
import MultiSelectDropdown from "../general/MultiSelectDropdown";
import KoMagsBarChart from "../moduleMaps/KoMagsBarChart";
import LoadingPlaceholder from "../general/LoadingPlaceholder";
import QueryForms from "./QueryForms";
import ScaleRangeForm from "./ScaleRangeForm";
import { fetchKoDetails, fetchMagDetails, fetchTaxonomyDetails, fetchMagFilterAbundance } from "../../functions/Fetching";
import { makeScopeMagAbundanceTableRows } from "../../functions/Scope";
import { getStringSeparators, makeMenuItems } from "../../functions/StringConversion";
import {koLink, ecLink} from "../../functions/Linkmaker";
import SingleSelectDropdown from "../general/SingleSelectDropdown";
import { MagFilter, toggleShowMagAbundance } from "../general/MagFilter";
import { errorAlert } from "../../functions/ErrorHandling";
import { updateKoMagData } from "../../functions/KoContributions";


import "../../css/general.css";

import KoPopup from "../general/KoPopup";
import { MagPopup, toggleMagPopup } from "../general/MagPopup";
import { TaxonomyPopup, toggleTaxonomyPopup } from "../general/TaxonomyPopup";

import OrthologyDetails from "../general/OrthologyDetails";
import { isCompositeComponentWithType } from "react-dom/test-utils";
import DatatypeSelector from "../general/DatatypeSelector";

class KeggOrthologies extends React.Component {

    constructor(props) {
        super(props);

        this.flaskAdress = this.props.flaskAdress;
        this.fetchGetInit = { //fetchGetInit from props does not work for some reason, time is short and this works fine
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + this.props.token
            }
        }
        this.leftWidth = 280;

        this.updateKoMagData = updateKoMagData.bind(this);
        this.fetchMetadata = this.fetchMetadata.bind(this);
        this.changeSampleSelection = this.changeSampleSelection.bind(this);
        this.fetchSearchResults = this.fetchSearchResults.bind(this);
        this.selectRow = this.selectRow.bind(this);
        this.makeColumnHeaders = this.makeColumnHeaders.bind(this);
        this.communityScopeMakeColumnHeaders = this.communityScopeMakeColumnHeaders.bind(this);
        this.magScopeMakeColumnHeaders = this.magScopeMakeColumnHeaders.bind(this);
        this.updateColumnHeaders = this.updateColumnHeaders.bind(this);
        //this.changeCountChoices = this.changeCountChoices.bind(this);
        //this.changeComparisonChoices = this.changeComparisonChoices.bind(this);
        this.changeKoMagTaxonomicLevel = this.changeKoMagTaxonomicLevel.bind(this);
        this.changeKoMagSampleSelection = this.changeKoMagSampleSelection.bind(this);
        this.fetchKoMagData = this.fetchKoMagData.bind(this);
        // this.submitColorMaxCounts = this.submitColorMaxCounts.bind(this);
        // this.submitColorMaxFoldChanges = this.submitColorMaxFoldChanges.bind(this);
        this.toggleColoring = this.toggleColoring.bind(this);
        this.toggleKoPopup = this.toggleKoPopup.bind(this);
        this.toggleMagPopup = toggleMagPopup.bind(this);
        this.toggleTaxonomyPopup = toggleTaxonomyPopup.bind(this);
        this.fetchKoDetails = fetchKoDetails.bind(this);
        this.fetchMagDetails = fetchMagDetails.bind(this);
        this.fetchCountToplist = this.fetchCountToplist.bind(this);
        this.fetchFoldChangeToplist = this.fetchFoldChangeToplist.bind(this);
        this.fetchTaxonomyDetails = fetchTaxonomyDetails.bind(this);
        this.fetchMagFilterAbundance = fetchMagFilterAbundance.bind(this);
        this.submitToplistLength = this.submitToplistLength.bind(this);
        this.changeToplistChoice = this.changeToplistChoice.bind(this);
        this.setQueryType = this.setQueryType.bind(this);
        this.updateScaleRange = this.updateScaleRange.bind(this);
        this.changeKoMagDatatype = this.changeKoMagDatatype.bind(this);
        this.setMagFilterValue = this.setMagFilterValue.bind(this);
        this.toggleMagFilter = this.toggleMagFilter.bind(this);
        this.setSearchTerm = this.setSearchTerm.bind(this);
        this.toggleShowMagAbundance =  toggleShowMagAbundance.bind(this);
        this.makeScopeMagAbundanceTableRows = makeScopeMagAbundanceTableRows.bind(this);
        this.toggleHideNoDataRows = this.toggleHideNoDataRows.bind(this);
        this.changeDataChoices = this.changeDataChoices.bind(this);
        this.updateLogScale = this.updateLogScale.bind(this);
        this.errorAlert = errorAlert.bind(this);

        this.state = {
            hideNoDataRows: false,
            lastQuery: undefined,
            logScale: {},
            selectedKo: this.props.selectedKo,
            selectedGene: this.props.selectedGene,
            searchTerm: this.props.searchTerm,
            columnHeaders: this.props.columnHeaders,
            searchHits: this.props.searchHits,
            countChoices: this.props.countChoices,
            comparisonChoices: this.props.comparisonChoices,
            countData: [],
            comparisonData: [],
            koMagRenderData: {samples_without_data: [], taxonomic_level: "", taxonomies: [], values: [], other_taxonomies: []},
            scaleRange: this.props.scaleRange,
            // colorMaxCounts: 1,
            // colorMaxFoldChanges: 1.5,
            coloringEnabled: this.props.coloringEnabled,
            koMagSampleSelection: this.props.koMagSampleSelection,
            koMagTaxonomicLevel: this.props.koMagTaxonomicLevel,
            koMagDatatype: this.props.koMagDatatype,
            loadingMetadata: false,
            loadingMags: false,
            loadingOrthologies: false,
            koPopupEnabled: false,
            magPopupEnabled: false,
            taxonomyPopupEnabled: false,
            toplistLength: this.props.toplistLength,
            toplistType: this.props.toplistType,
            toplistChoice: this.props.toplistChoice,
            queryType: this.props.queryType,
            magFilterActive: this.props.magFilterActive,
            magFilterValue: this.props.magFilterValue,
            magFilterAbundance: this.props.magFilterAbundance,
            showMagAbundance: this.props.showMagAbundance,
            scopeMagAbundanceTableRows: this.props.scopeMagAbundanceTableRows,
        }
    }

    componentDidMount() {
        this.fetchMetadata();
    }

    componentWillUnmount() {
        /*
        this.props.setSelectedKo(this.state.selectedKo);
        this.props.setSelectedGene(this.state.selectedGene);
        this.props.setSearchTerm(this.state.searchTerm);
        this.props.setColumnHeaders(this.state.columnHeaders);
        this.props.setSearchHits(this.state.searchHits);
        this.props.setCountChoices(this.state.countChoices);
        this.props.setComparisonChoices(this.state.comparisonChoices);
        this.props.setScaleRange(this.state.scaleRange);
        this.props.setColoringEnabled(this.state.coloringEnabled);
        this.props.setKoMagSampleSelection(this.state.koMagSampleSelection);
        this.props.setKoMagTaxonomicLevel(this.state.koMagTaxonomicLevel);
        this.props.setKoMagDatatype(this.state.koMagDatatype);
        this.props.setToplistLength(this.state.toplistLength);
        this.props.setToplistType(this.state.toplistType);
        this.props.setToplistChoice(this.state.toplistChoice);
        this.props.setQueryType(this.state.queryType);
        this.props.setMagFilterActive(this.state.magFilterActive);
        this.props.setMagFilterValue(this.state.magFilterValue);
        this.props.setMagFilterAbundance(this.state.magFilterAbundance);
        this.props.setShowMagAbundance(this.state.showMagAbundance);
        this.props.setScopeMagAbundanceTableRows(this.state.scopeMagAbundanceTableRows);
        */
    }

    fetchMetadata() {
        this.setState({...this.state, loadingMetadata: true});
        let metadataUrl = this.flaskAdress + "/orthologies/metadata";
        fetch(metadataUrl, this.fetchGetInit)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch orthologies metadata.');
                }
            })
            .then(data => {
                this.props.refreshToken(data);
                this.updateMetadata(data)
            })
            .catch(error => this.setState({...this.state, errorMessage: error.message, loadingMetadata: false}));
    }

    fetchSearchResults(searchTerm) {
        let url;
        if (this.state.magFilterActive) {
            if (this.state.magFilterValue === undefined) {
                alert("Please select a MAG or switch to community scope.");
                return;
            }
            url = this.flaskAdress + "/gene_keyword_search_for_mag/" + this.state.magFilterValue + "/" + searchTerm;
        } else {
            url = this.flaskAdress + "/ko_keyword_search/" + searchTerm;
        }
        this.setState({...this.state, loadingOrthologies: true});
        //console.log("XXXXXXXXXXXXXXXXX Trying to fetch searchResults for " + searchTerm + " from " + url + "")
        fetch(url, this.fetchGetInit)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Failed to fetch data search '+ searchTerm);
            }
        })
        .then(data => {
            this.props.refreshToken(data);
            const keys = Object.keys(data);
            if (keys.includes("error")) {
                this.errorAlert(data);
                this.setState({...this.state, searchHits: [], loadingOrthologies: false})
            } else {
                let newScaleRange = this.state.scaleRange;
                if (this.state.magFilterActive) {
                    newScaleRange = data.pop();
                    newScaleRange["FoldChange"] = this.state.scaleRange["FoldChange"];
                }
                    //WIE RÜSSEL ICH DIE DATEN HIER RAUS. ERSTMAL IM BACKEND HINEIN
                this.setState({
                    ...this.state,
                    searchHits: data,
                    scaleRange: newScaleRange,
                    loadingOrthologies: false
                })
            }
        })
        .catch(error => this.setState({...this.state, errorMessage: error.message}));
    }

    fetchCountToplist() {
        if (this.state.toplistChoice === undefined) {
            return;
        }
        const {datatype, condition, name} = this.state.toplistChoice;
        let url;
        if (this.state.magFilterActive) {
            if (this.state.magFilterValue === undefined) {
                alert("Please select a MAG or switch to community scope.");
                return;
            }
            url = (this.flaskAdress + "/orthologies/gene_count_toplist_for_mag/" +
                datatype + "/" +
                condition + "/" +
                name + "/" +
                this.state.toplistLength + "/" +
                this.state.magFilterValue);
        } else {
            url = (this.flaskAdress + "/orthologies/ko_count_toplist/" +
                datatype + "/" +
                condition + "/" +
                name + "/" +
                this.state.toplistLength);
        }
        this.setState({...this.state, loadingOrthologies: true});
        fetch(url, this.fetchGetInit)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Failed to fetch count toplist '+ datatype + " " + condition + " " + name);
            }
        })
        .then(data => {
            this.props.refreshToken(data);
            const keys = Object.keys(data);
            if (keys.includes("error")) {
                this.errorAlert(data);
                this.setState({...this.state, searchHits: [], loadingOrthologies: false})
            } else {
                let newScaleRange = this.state.scaleRange;
                let newData = data;
                if (this.state.magFilterActive) {
                    newScaleRange = data['count_ranges']
                    newScaleRange["FoldChange"] = this.state.scaleRange["FoldChange"];
                    newData = data['data']
                }
                this.setState({
                    ...this.state,
                    searchHits: newData,
                    loadingOrthologies: false,
                    scaleRange: newScaleRange,
                    lastQuery: {type: "countToplist"}
                })
            }
        })
        .catch(error => this.setState({...this.state, errorMessage: error.message}));
    }

    fetchFoldChangeToplist() {
        if (this.state.toplistChoice === undefined) {
            return;
        }
        this.setState({...this.state, loadingOrthologies: true});
        const {datatype, base_condition, experimental_condition} = this.state.toplistChoice;
        if (this.state.magFilterActive) {
            /*
            const message = `Since Fold Change Data for Orthologies is not available on MAG-Level,
the toplist will be based on the fold changes on the community level. The MAG filter will only be appliedto the count- data shown in the table.`;
            */
            const message = 'Fold change data is not available on MAG-level.'
        alert(message);
        }
        let url = (this.flaskAdress +
        "/orthologies/fold_change_toplist/" +
        datatype + "/" +
        base_condition + "/" +
        experimental_condition + "/" +
        this.state.toplistLength);
        fetch(url, this.fetchGetInit)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Failed to fetch fold change toplist '+ datatype + " " + base_condition + " " + experimental_condition);
            }
        }).then(data => {
            this.props.refreshToken(data);
            const keys = Object.keys(data);
            if (keys.includes("error")) {
                this.errorAlert(data);
                this.setState({...this.state, searchHits: [], loadingOrthologies: false})
            } else {
                this.setState({
                    ...this.state,
                    searchHits: data,
                    loadingOrthologies: false,
                    lastQuery: {type: "foldChangeToplist"}
                })
            }
        }).catch(error => this.setState({...this.state, errorMessage: error.message}));
    }

    // fetchKoMagData() {
    //     this.setState({...this.state, loadingMags: true});
    //     if (this.state.selectedKo === null || this.state.koMagSampleSelection === null || this.state.koMagTaxonomicLevel === null) {
    //         return;
    //     }
    //     if (this.state.selectedKo === "" || this.state.koMagSampleSelection === "" || this.state.koMagTaxonomicLevel === "") {
    //         return;
    //     }
    //     let samples = this.state.koMagSampleSelection;
    //     samples = samples.toString();
    //     const koMagUrl = this.flaskAdress
    //                      + "mag_ko_plot_data/"
    //                      + this.state.selectedKo + '/'
    //                      + this.state.koMagTaxonomicLevel + '/'
    //                      + samples
    //     fetch(koMagUrl, this.fetchGetInit)
    //         .then(response => {
    //             if (response.ok) {
    //                 return response.json();
    //             } else {
    //                 throw new Error('Failed to fetch MAG abundances from '+koMagUrl);
    //             }
    //         })
    //         .then(data => {
    //             this.props.refreshToken(data);
    //             this.updateKoMagData(data)
    //         })
    //         .catch(error => this.setState({...this.state, errorMessage: error.message}))
    // }

    fetchKoMagData() {
        if (this.state.selectedKo === null || this.state.koMagSampleSelection === null || this.state.koMagTaxonomicLevel === null) {
            return;
        }
        if (this.state.selectedKo === "" || this.state.koMagSampleSelection === "" || this.state.koMagTaxonomicLevel === "") {
            return;
        }
        if (this.state.koMagTaxonomicLevel === undefined || this.state.koMagSampleSelection === undefined) {
            return;
        }
        let samples = this.state.koMagSampleSelection;
        samples = samples.toString();
        const koMagUrl = this.flaskAdress
                         + "mag_ko_plot_data/"
                         + this.state.selectedKo + '/'
                         + this.state.koMagTaxonomicLevel + '/'
                         + this.state.koMagDatatype + '/'
                         + samples;
        const fetchGetInit= { //fetchGetInit from props does not work for some reason, time is short and this works fine
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + this.props.token
            }
        }
        fetch(koMagUrl, fetchGetInit)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch MAG abundances from '+koMagUrl);
                }
            })
            .then(data => {
                this.props.refreshToken(data);
                this.updateKoMagData(data)
            })
            .catch(error => this.setState({...this.state, errorMessage: error.message}))
    }

    setSearchTerm(searchTerm) {
        this.setState({
            ...this.state,
            lastQuery: {
                type: "search",
                searchTerm: searchTerm
            }
        },
        function() {
            this.fetchSearchResults(searchTerm);
        });
    }

        /*
    updateKoMagData(newData) {
        this.setState({
            ...this.state,
            koMagRenderData: newData,
            loadingMags: false
        })
    }
        */

    updateMetadata(newMetadata) {
        // Adjuste Scale Ranges
        let scaleRange = this.state.scaleRange;
        //const knownRanges = Object.keys(scaleRange);
        const datatypes = Object.keys(newMetadata.count_ranges);
        let newLogScale = {}
        datatypes.forEach((datatype) => {
            //Check if datatype is not already in scaleRange
            scaleRange[datatype] = newMetadata.count_ranges[datatype].percentile75;
            newLogScale[datatype] = false;
            //if (knownRanges.indexOf(datatype) === -1) {
            //    scaleRange[datatype] = newMetadata.count_ranges[datatype].percentile75;
            //    logScale[datatype] = false;
            //}
        })
        const columnHeaders = this.makeColumnHeaders(newMetadata,
            this.props.countChoices,
            this.props.comparisonChoices,
            this.state.magFilterActive);
        // Update State
        this.setState({
            ...this.state,
            metadata: newMetadata,
            scaleRange: scaleRange,
            columnHeaders: columnHeaders,
            loadingMetadata: false,
            logScale: newLogScale
        },
        function() {
            this.fetchKoDetails(this.props.token);
            // this.makeColumnHeaders();
            this.fetchKoMagData();
            /*
            console.log("XXXX4")
            console.log(this.state)
            console.log(this.state.queryType)
            if (this.state.queryType === "search") { //wenn ich das auf this.props.queryType ändere ist alles kaputt
                console.log("XXXXX my queryType is search so i should fetch search ")
                console.log("XXXXX my searchTerm is " + this.props.searchTerm + " so i should fetch search")
                this.fetchSearchResults(this.props.searchTerm);
            } else if (this.queryType === "toplist") {
                if (this.state.toplistType === "count") {
                    this.fetchCountToplist();
                } else if (this.state.toplistType === "comparison") {
                    this.fetchFoldChangeToplist();
                }
            }
            //this.fetchKoDetails();
            console.log("ORTHOLOGIES: my state is: ", this.state);
            console.log("ORTHOLOGIES: Metadata set to")
            console.log(this.state.metadata)
            */
        })
    }

    updateScaleRange(datatype, newScaleValue) {
        let newScaleRange = this.state.scaleRange;
        newScaleRange[datatype] = newScaleValue;
        this.setState({...this.state, scaleRange: newScaleRange},
            function() {
        })
    }

    updateLogScale(datatype, newLogScalevalue) {
        /* update the log scale for a given datatype */
        let newLogScale = this.state.logScale;
        newLogScale[datatype] = newLogScalevalue;
        /* log2 or unlog2 the scaleRange value of the datatype. Set to 1
           if the value would be <1 */
        let newScaleRange = this.state.scaleRange;
        if (newLogScalevalue === true) {
            newScaleRange[datatype] = Math.max(1, Math.log2(newScaleRange[datatype]));
        } else {
            newScaleRange[datatype] = Math.pow(2, newScaleRange[datatype]);
        }
        this.setState({
            ...this.state,
            logScale: newLogScale,
            scaleRange: newScaleRange
        }, function() {
            
        })
    }

    changeSampleSelection(newSampleSelection) {
        this.setState({
            ...this.state,
            sampleSelection: newSampleSelection
        },
        function() {
            this.updateColumnHeaders(); // initiate a refresh
        })
    }

    selectRow(gene, ko) {
        //Called when a row of the table is clicked.
        this.setState({
            ...this.state,
            selectedKo: ko,
            selectedGene: gene,
        }, () => {
            this.fetchKoMagData(this.props.token);
            this.fetchKoDetails(this.props.token);
        })
    }

    /*
    changeCountChoices(choiceIndices) {
        this.setState({
            ...this.state,
            countChoices: choiceIndices
        },
        function() {
            this.updateColumnHeaders();
        });
    }

    changeComparisonChoices(choiceIndices) {
        this.setState({
            ...this.state,
            comparisonChoices: choiceIndices
        },
        function() {
            this.updateColumnHeaders();
        });
    }
    */

    changeKoMagTaxonomicLevel(taxonomicLevel) {
        this.setState({
            ...this.state,
            koMagTaxonomicLevel: taxonomicLevel
        },
        function() {
            if (!(this.state.koMagTaxonomicLevel === undefined) && !(this.state.koMagTaxonomicLevel === null)) {
                this.fetchKoMagData(this.props.token);
            }
        })
    }

    changeKoMagSampleSelection(sampleSelection) {
        this.setState({
            ...this.state,
            koMagSampleSelection: sampleSelection
        },
        function() {
            if (!(this.state.koMagTaxonomicLevel === undefined) && !(this.state.koMagTaxonomicLevel === null)) {
                this.fetchKoMagData(this.props.token);
            }
        })
    }

    makeColumnHeaders(metadata, countChoices, comparisonChoices, magFilterActive) {
        const tableWidth = 390;
        return (magFilterActive ?
            this.magScopeMakeColumnHeaders(tableWidth, metadata, countChoices, comparisonChoices) :
            this.communityScopeMakeColumnHeaders(tableWidth, metadata, countChoices, comparisonChoices)            
            );
    }

    magScopeMakeColumnHeaders(tableWidth, metadata, countChoices, comparisonChoices) {
        const {datatypeSeparator, conditionSeparator, genericSeparator} = getStringSeparators();
        let headerColumns = [
            {
                Header: 'Gene',
                accessor: 'gene',
                width: 90,
                colored: false,
                Cell: tableProps => (
                    tableProps.value
                    /*<div>
                        <input
                            className="mini-button"
                            type="button"
                            value={tableProps.value}
                        />
                    </div>*/
                )
            },
            { Header: 'KEGG Orthology', accessor: 'ko', width: 70, colored: false},
            { Header: 'Description', accessor: 'function', width: 200, colored: false},
            { Header: 'ECs', accessor: 'eclist', width: 70, colored: false}
        ]
        // Make headers for the count columns
        //const countSamples = metadata.count_samples;
        for (let i=0; i<countChoices.length; i++) {
            //const index = countChoices[i];
            //const {condition, datatype, name} = countSamples[index];
            const {datatype, condition, name} = countChoices[i];
            const label = datatype + datatypeSeparator + condition + genericSeparator + name;
            const col = {
                Header: label,
                accessor: label,
                width: 60,
                colored: true,
                type: "count",
                datatype: datatype
            };
            tableWidth = tableWidth + 70;
            headerColumns.push(col);
        }
        // Make headers for the comparison columns
        //const comparisons = metadata.comparisons;
        for (let i=0; i<comparisonChoices.length; i++) {
            //const index = comparisonChoices[i]
            //const {base_condition, datatype, experimental_condition} = comparisons[index];
            const {base_condition, datatype, experimental_condition} = comparisonChoices[i];
            const label = datatype + datatypeSeparator + base_condition + conditionSeparator + experimental_condition;
            const col = {
                Header:
                label,
                accessor:
                label,
                width: 60,
                colored: true,
                datatype: datatype,
                type: "foldChange"
            };
            tableWidth = tableWidth + 70;
            headerColumns.push(col);
        }
        return headerColumns;
    }
    
    communityScopeMakeColumnHeaders(tableWidth, metadata, countChoices, comparisonChoices) {
        const {datatypeSeparator, conditionSeparator, genericSeparator} = getStringSeparators();
        let headerColumns = [
            { 
                Header: 'KEGG Orthology',
                accessor: 'ko',
                width: 70,
                colored: false,
                Cell: tableProps => (
                    <div>
                        <input
                            className="mini-button"
                            type="button"
                            value={tableProps.value}
                        />
                    </div>
                )
            },
            { Header: 'Description', accessor: 'function', width: 200, colored: false},
            { Header: 'ECs', accessor: 'eclist', width: 70, colored: false}
        ]
        //console.log("i am making column headers with the following metadata")
        //console.log(metadata)
        ///const countSamples = metadata.count_samples;
        //const comparisons = metadata.comparisons;
        for (let i=0; i<countChoices.length; i++) {
            //const index = countChoices[i]
            //const {condition, datatype, name} = countSamples[index];
            const {datatype, condition, name} = countChoices[i];
            const label = datatype + datatypeSeparator + condition + genericSeparator + name;
            const col = {
                Header: label,
                accessor: label,
                width: 60,
                colored: true,
                type: "count",
                datatype: datatype
//                    color:  this.colorForCount(value)
//                    Cell: ({value}) => {
//                        const color = this.colorForCount(value);
//                        return (<div style={{background: color}}>{value}</div>);
//                    }
            };
            tableWidth = tableWidth + 70;
            headerColumns.push(col);
        }
        for (let i=0; i<comparisonChoices.length; i++) {
            //const index = comparisonChoices[i]
            //const {base_condition, datatype, experimental_condition} = comparisons[index];
            const {base_condition, datatype, experimental_condition} = comparisonChoices[i];
            const label = datatype + datatypeSeparator + base_condition + conditionSeparator + experimental_condition;
            const col = {
                Header:
                label,
                accessor:
                label,
                width: 60,
                colored: true,
                datatype: datatype,
                type: "foldChange"
//                    color: this.colorForFoldChange(value)
//                    Cell: ({value}) => {
//                        const color = this.colorForFoldChange(value);
//                        return (<div style={{background: color}}>{value}</div>);
//                    }
            };
            tableWidth = tableWidth + 70;
            headerColumns.push(col);
        }
        
        return (headerColumns)
        /*
        this.setState({
            ...this.state,
            columnHeaders: headerColumns,
            //valuesTableWidth: tableWidth
        });
        */
    }

    updateColumnHeaders() {
        if (!(this.state.metadata === undefined)) {
            const columnHeaders = this.makeColumnHeaders(this.state.metadata,
                this.state.countChoices,
                this.state.comparisonChoices,
                this.state.magFilterActive)
            this.setState({
                ...this.state,
                columnHeaders: columnHeaders
            });
        }
    }

    setMagFilterValue(magIdentifier) {
        //check if value is legal
        const legalValues = Object.keys(this.state.metadata.mag_attributes);
        if (!(legalValues.includes(magIdentifier) || magIdentifier === "unbinned")) {
          alert("No MAG with name " + magIdentifier + " found.");
          return;
        }
      
        //Change value
        this.setState({
          ...this.state,
          magFilterValue: magIdentifier
        }, () => {
          this.fetchMagFilterAbundance(this.props.token, magIdentifier, () => {
            this.repeatQuery();
          });
        });
      }

    repeatQuery() {
        const lastQuery = this.state.lastQuery;
        if (lastQuery === undefined) {
            return;
        }
        if (this.state.magFilterActive) {
            if (lastQuery.type === "countToplist") {
                this.fetchCountToplist()
            } else if (lastQuery.type === "comparisonToplist")  {
                return;
            } else if (lastQuery.type === "search") {
                this.fetchSearchResults(lastQuery.searchTerm)
            }
        } else {
            if (lastQuery.type === "countToplist") {
                this.fetchCountToplist()
            } else if (lastQuery.type === "comparisonToplist")  {
                this.fetchComparisonToplist()
            } else if (lastQuery.type === "search") {
                this.fetchSearchResults(lastQuery.searchTerm)
            }
        }
    }

    // submitColorMaxCounts(value) {
    //     this.setState({
    //         ...this.state,
    //         colorMaxCounts: value
    //     });
    // }

    // submitColorMaxFoldChanges(value) {
    //     this.setState({
    //         ...this.state,
    //         colorMaxFoldChanges: value
    //     })
    // }
    
    toggleColoring() {
        this.setState({
            ...this.state,
            coloringEnabled: !this.state.coloringEnabled
        })
    }

    toggleKoPopup() {
        this.setState({
            ...this.state,
            koPopupEnabled: !this.state.koPopupEnabled
        }, () => { console.log("new state: " + this.state.koPopupEnabled) });
    }

    /*
    toggleMagPopup(mag) {
        console.log(mag)
        if (mag === 'unbinned') {
            alert("The 'unbinned' section represents non-binned contigs. It is not possible to view the MAG details these.")
            return
        }
        if (!this.state.magPopupEnabled) {
            this.fetchMagDetails(this.props.token, mag);
        }
        this.setState({
            ...this.state,
            magPopupEnabled: !this.state.magPopupEnabled,
        });
    }

    toggleTaxonomyPopup(taxonomic_level, taxonomy) {
        if (taxonomy === 'unbinned') {
            alert("The 'unbinned' section represents non-binned contigs. It is not possible to view the taxonomy details these.")
            return
        }
        if (!this.state.taxonomyPopupEnabled) {
            this.fetchTaxonomyDetails(this.props.token, taxonomic_level, taxonomy);
        }
        this.setState({
            ...this.state,
            taxonomyPopupEnabled: !this.state.taxonomyPopupEnabled
        });
    }
    */

    submitToplistLength(value) {
        this.setState({
            ...this.state,
            toplistLength: value
        }, function() {
            if (this.state.toplistType === "counts") {
                this.fetchCountToplist()
            } else if (this.state.toplistType === "comparisons") {
                this.fetchFoldChangeToplist()
            }
        });
    }

    changeToplistChoice(type, choice) {
        if (type === "counts") {
            this.setState({
                ...this.state,
                toplistType: "counts",
                toplistChoice: choice[0]
            }, function() {
                this.fetchCountToplist()
            });
        }
        else if (type === "comparisons") {
            this.setState({
                ...this.state,
                toplistType: "comparisons",
                toplistChoice: choice[0]
            }, function() {
                this.fetchFoldChangeToplist()
            });
        }
    }

    setQueryType(type) {
        this.setState({
            ...this.state,
            queryType: type
        });
    }

    changeKoMagDatatype(datatype) {
        this.setState({
            ...this.state,
            koMagDatatype: datatype
        },
        function() {
            this.fetchKoMagData()
        })
    }

    toggleShowMagAbundance() {
        this.setState({
            ...this.state,
            showMagAbundance: !this.state.showMagAbundance
        })
    }

    toggleMagFilter() {
        console.log(this.state.scaleRange);
        let newtoplistType = this.state.toplistType
        let newScaleRange = this.state.scaleRange;
        if (!(this.state.magFilterActive)) { // mag filter will be active after this
            newtoplistType = "counts";
        } else {
            const datatypes = Object.keys(this.state.metadata.count_ranges);
            newScaleRange = {};
            datatypes.forEach((datatype) => {
                newScaleRange[datatype] = this.state.metadata.count_ranges[datatype].percentile75;
            });
            newScaleRange["FoldChange"] = this.state.scaleRange.FoldChange;
        }
        this.setState({
            ...this.state,
            toplistType: newtoplistType,
            magFilterActive: !this.state.magFilterActive,
            countChoices: [],
            comparisonChoices: [],
            searchHits: [],
            scaleRange: newScaleRange,
        },
        function() {
            this.updateColumnHeaders();
            //this.fetchSearchResults(this.state.searchTerm);
            //if (this.state.magFilterActive) {
            //    this.changeComparisonChoices([]);
            //}
        })
    }

    toggleHideNoDataRows() {
        this.setState({
            ...this.state,
            hideNoDataRows: !this.state.hideNoDataRows
        })
    }

    updateChoices(oldChoices, newDatatype, newChoices) {
        //Remove any choices that have the input datatype
        let updatetChoices = oldChoices;
        for (let i=0; i<updatetChoices.length; i++) {
            if (updatetChoices[i].datatype === newDatatype) {
                updatetChoices.splice(i, 1);
                i--;
            }
        }
        //Add new choices
        for (let i=0; i<newChoices.length; i++) {
            updatetChoices.push(newChoices[i]);
        }
        return updatetChoices;
    }

    changeDataChoices(itemsType, datatype, newChoices) {
        let updatedChoices;
        //get old choices and remove any that have the input datatype
        if (itemsType === "counts") {
            updatedChoices = this.updateChoices(
                this.state.countChoices,
                datatype,
                newChoices
            )
            this.setState({
                ...this.state,
                countChoices: updatedChoices
            }, function() {
                this.updateColumnHeaders();
            });
        } else if (itemsType === "compare") {
            updatedChoices = this.updateChoices(
                this.state.comparisonChoices,
                datatype,
                newChoices
            )
            this.setState({
                ...this.state,
                comparisonChoices: updatedChoices
            }, function() {
                this.updateColumnHeaders();
            });
        }
        console.log(updatedChoices)
    }

    filteredRows(searchHits) {
        //If filtering is disabled then just return everything
        if (!this.state.hideNoDataRows) {
            return searchHits;
        }        
        //iterate through entries in searchHits
        let filteredRows = [];
        for (let i=0; i<searchHits.length; i++) {
            const item = searchHits[i];
            //Investigate the keys of item. If it has any keys besides
            //eclist, ko and function add it to filteredRows
            let keys = Object.keys(item);
            let hasData = false;
            for (let j=0; j<keys.length; j++) {
                if (keys[j] !== "eclist" && keys[j] !== "ko" && keys[j] !== "function") {
                    hasData = true;
                }
            }
            if (hasData) {
                filteredRows.push(item);
            }
        }
        return filteredRows;
    } 





    render() {
        const leftWidth = this.leftWidth;
        let koEcList = [];
        let koModuleList= [];
        let koPathwayList = [];
        let koShorthand = "";
        let koDescription = "";
        if (!(this.state.koDetails === undefined)) {
            koEcList = this.state.koDetails.ec_list;
            koShorthand = this.state.koDetails.shorthand;
            koDescription = this.state.koDetails.description;
            //go through all objects in this.state.koDetails.modules and push the value behind the "module" key to koModuleList
            koModuleList = [];
            for (let i = 0; i < this.state.koDetails.modules.length; i++) {
                koModuleList.push(this.state.koDetails.modules[i].module);
            }
        }

        if (this.state.metadata === undefined || this.state.metadata === null) {
            return (
                <LoadingPlaceholder/>
            )
        }
        let koNotFoundMessage = ""
        if (this.state.koMagRenderData.samples_without_data.length > 0) {
            koNotFoundMessage = "KO not found in the following samples: "
        }
        let mag_abundance_samples = [""];
        let mag_identifiers = []
        if ( !(this.state.metadata === undefined) ) {
            mag_abundance_samples = this.state.metadata.mag_abundance_samples;
            mag_identifiers = Object.keys(this.state.metadata.mag_attributes);
        }
        let comparisonMenuItems;
        if (this.state.magFilterActive) {
            comparisonMenuItems = makeMenuItems(this.state.metadata.comparisons, "compare");
        } else {
            comparisonMenuItems = makeMenuItems(this.state.metadata.comparisons, "compare");
        }
        const countMenuItems = makeMenuItems(this.state.metadata.count_samples, "counts");
        return (
            <div className="padded_flex">
                <div>

                    <div className="padded2">
                        Scope
                    </div>
                    <div className="menuBox">
                        <div style={{"font": "400 14px system-ui"}}>
                            <MagFilter
                                width={leftWidth}
                                magFilterActive={this.state.magFilterActive}
                                magFilterValue={this.state.magFilterValue}
                                setMagFilterValue={this.setMagFilterValue}
                                toggleMagFilter={this.toggleMagFilter}
                                toggleShowMagAbundance={this.toggleShowMagAbundance}
                                showMagAbundance={this.state.showMagAbundance}
                                magAbundanceRows={this.state.scopeMagAbundanceTableRows}
                                extraText={true}
                            />                           
                        </div>
                    </div>
                    <br/>
                    <div className="padded2">
                        Search Functions
                    </div>
                    <div className="menuBox">
                        <div>
                            <QueryForms
                                queryType={this.state.queryType}
                                setQueryType={this.setQueryType}
                                leftWidth={leftWidth}
                                //fetchSearchResults={this.fetchSearchResults}
                                searchTerm={this.state.searchTerm}
                                setSearchTerm={this.setSearchTerm}
                                countMenuItems={countMenuItems}
                                comparisonMenuItems={comparisonMenuItems}
                                changeToplistChoice={this.changeToplistChoice}
                                submitToplistLength={this.submitToplistLength}
                                magFilterActive={this.state.magFilterActive}
                                />
                        </div>
                    </div>
                    <br/>
                    <div className="menuBox">
                        <div className="padded4" style={{"textAlign": "left", "font": "400 14px system-ui"}}>
                            <input
                                type="checkbox"
                                checked={this.state.hideNoDataRows}
                                onChange={this.toggleHideNoDataRows}
                            />
                            <span className="padded2">Hide rows with no values</span>
                            <br/><input
                                type="checkbox"
                                checked={this.state.coloringEnabled}
                                onChange={this.toggleColoring}
                            />
                            <span className="padded2">Colored cell values</span>
                        </div>
                    </div>
                    <br/>
                    Show in Table
                    <div className="menuBox">
                        <div className="menuBox" style={{ "font": "400 14px system-ui"}}>
                            <div style={{"textAlign": "left", "font": "400 16px system-ui"}}>
                                Count Data
                            </div>

                            <DatatypeSelector
                                key="counts"
                                width={leftWidth}
                                rangeValues={this.state.scaleRange}
                                itemsData={this.state.metadata.count_samples}
                                changeDataChoices={this.changeDataChoices}
                                changeRange={this.updateScaleRange}
                                logScale={this.state.logScale}
                                changeLogScale={this.updateLogScale}
                                subunitMediansInterface={false}
                                subunitMedians={undefined}
                                changeSubunitMedians={undefined}
                                itemsType="counts"
                                magFilterActive={this.state.magFilterActive}
                                defaultValue={this.state.countChoices}
                            />
                        </div>
                        <br/>
                        <div className="menuBox" style={{"font": "400 14px system-ui"}}>
                            <div style={{"textAlign": "left", "font": "400 16px system-ui"}}>
                                Fold Change Data
                            </div>
                            <DatatypeSelector
                                key="counts"
                                width={leftWidth}
                                rangeValues={this.state.scaleRange}
                                itemsData={this.state.metadata.comparisons}
                                changeDataChoices={this.changeDataChoices}
                                changeRange={this.updateScaleRange}
                                logScale={{}}
                                changeLogScale={() => {}}
                                subunitMediansInterface={false}
                                subunitMedians={undefined}
                                changeSubunitMedians={undefined}
                                itemsType="compare"
                                magFilterActive={this.state.magFilterActive}
                                defaultValue={this.props.comparisonChoices}
                            />
                        </div>
                    </div>
                </div>
                <div className="padded4">
                    <div>
                        Results {this.state.magFilterActive ? "(MAG "+this.state.magFilterValue+")" : "(Community Scope)"}
                    </div>
                    <div className="infobox" style={{"width": "auto"}}>
                        <div className="padded4">
                            <KoValuesTable
                                headers={this.state.columnHeaders}
                                rows={this.filteredRows(this.state.searchHits)}
                                hideNoDataRows={this.state.hideNoDataRows}
                                rowClick={this.selectRow}
                                scaleRange={this.state.scaleRange}
                                logScale={this.state.logScale}
                                // colorMaxCounts={this.state.colorMaxCounts}
                                // colorMaxFoldChanges={this.state.colorMaxFoldChanges}
                                loading={this.state.loadingOrthologies}
                                selectedKo={this.state.selectedKo}
                                selectedGene={this.state.selectedGene}
                                coloringEnabled={this.state.coloringEnabled}
                            />
                        </div>
                    </div>
                </div>
                <div className="padded4" style={{"position": "sticky"}}>
                    <div>
                        Selected Orthology
                        <div className="infobox" style={{"overflow": "hidden", "width": "600px", "font": "400 14px system-ui"}}>
                            <OrthologyDetails
                                width={600}
                                ko={this.state.selectedKo}
                                ecList={koEcList}
                                moduleList={koModuleList}
                                pathwayList={koPathwayList}
                                shorthand={koShorthand}
                                description={koDescription}
                                toggleKoPopup={this.toggleKoPopup}
                            />
                        </div>
                        <br/>
                        Taxonomic Contributions to {this.state.selectedKo} Abundance
                        <div className="infobox" style={{width: "600px"}}>
                            <div>
                            <div className="padded4" style={{"display": "flex","font": "400 14px system-ui"}}>
                                <SingleSelectDropdown
                                    title="Datatype"
                                    change={this.changeKoMagDatatype}
                                    values={Object.keys(this.state.metadata.count_ranges)}
                                    width={120}
                                />
                                <TaxonomicLeveLDropdown
                                    title="Tax. Level"
                                    change={this.changeKoMagTaxonomicLevel}
                                    width={100}
                                />
                                <MultiSelectDropdown
                                    title="Taxonomy Data"
                                    change={this.changeKoMagSampleSelection}
                                    datasets={mag_abundance_samples}
                                    width={370}
                                />
                            </div>
                            </div>
                            <div className="padded4">
                                <KoMagsBarChart
                                    values={this.state.koMagRenderData.values}
                                    other_taxonomies={this.state.koMagRenderData.other_taxonomies}
                                    taxonomicLevel={this.state.koMagRenderData.taxonomic_level}
                                    taxonomies={this.state.koMagRenderData.taxonomies}
                                    loading={this.state.loadingMags}
                                    magClick={this.toggleMagPopup}
                                    taxonomyClick={this.toggleTaxonomyPopup}
                                />
                                {koNotFoundMessage}{this.state.koMagRenderData.samples_without_data.join(", ")}
                            </div>
                        </div>
                    </div>
                </div>
                <KoPopup
                    ko={this.state.selectedKo}
                    koData={this.state.koDetails}
                    isOpen={this.state.koPopupEnabled}
                    onRequestClose={this.toggleKoPopup}
                />
                <MagPopup
                    mag={this.state.selectedMag}
                    magData={this.state.magDetails}
                    isOpen={this.state.magPopupEnabled}
                    onRequestClose={this.toggleMagPopup}
                />
                <TaxonomyPopup
                    data={this.state.taxonomyDetails}
                    isOpen={this.state.taxonomyPopupEnabled}
                    onRequestClose={this.toggleTaxonomyPopup}
                />
            </div>
            
        )
    }
}

export default KeggOrthologies;