import { useState } from 'react';
import "../../css/general.css"

function Login(props) {

    const [loginForm, setloginForm] = useState({
      username: "",
      password: "",
      message: ""
    });

    function fetchLoginToken(event) {
        const url = props.flaskAdress + "token";
        const body = JSON.stringify({
            username: loginForm.username,
            password: loginForm.password
        });
        const settings = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: body
        } 
        fetch(url, settings)
            .then(response => {
                if (response.ok) {
                    setloginForm({...loginForm, message: ""});
                    return response.json();
                } else {
                    throw new Error('Failed to fetch login token: '+url);
                }
            })
            .then (data => {
                props.setTokenData(data.access_token, data.lifetime_minutes);
            })
            .catch(error => {
                console.log("LOGIN: Error when trying to fetch login token: ");
                console.log(error);
                setloginForm({...loginForm, message: "login failed"});
            });

        setloginForm(({
            username: "",
            password: ""
        }))
        event.preventDefault();
    }

    function handleChange(event) {
        const {value, name} = event.target;
        setloginForm(prevNote => ({
            ...prevNote, [name]: value
        }));
    }

    return (
        <div className="login">
            <h4>authentication required</h4>
            <form>
                <div className="padded4">
                <input
                    className="textInput"
                    onChange={handleChange} 
                    type="username"
                    text={loginForm.username} 
                    name="username" 
                    placeholder="Username" 
                    value={loginForm.username} />
                </div>
                <div className="padded4">
                <input
                    className="textInput"
                    onChange={handleChange} 
                    type="password"
                    text={loginForm.password} 
                    name="password" 
                    placeholder="Password" 
                    value={loginForm.password} />
            </div>
            <div className="padded4">
                <button
                className="button"
                onClick={fetchLoginToken}>Login</button>
            </div>
            </form>
            <br/>
            <div style={{color: "red"}}>{loginForm.message}</div>
        </div>
    );
}

export default Login;

