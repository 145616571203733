import React from 'react';
import { Tooltip } from 'react-tooltip'


import '../../css/general.css';

import { countColor } from '../../functions/Colors';

class NodeDetails extends React.Component {
    constructor(props) {
        super(props);

        /*
        this.countColorsheet = [
            {
                minColor: "fbceb1",
                midColor: "ffffff",
                maxColor: "008210",
                nullColor: "919191"
            },
            {
                minColor: "fbceb1",
                midColor: "ffffff",
                maxColor: "9e000",
                nullColor: "919191"
            },
            {
                minColor: "fbceb1",
                midColor: "ffffff",
                maxColor: "a5790a",
                nullColor: "919191"
            },
            {
                minColor: "fbceb1",
                midColor: "ffffff",
                maxColor: "00379e",
                nullColor: "919191"
            },
        ]
        */

    }

    /*
    getColorsheet(datatype) {
        let datatypesInUse = [];
        for (let i=0; i<this.props.displayChoices.length; i++) {
            const datatype = this.props.displayChoices[i].datatype;
            if (!datatypesInUse.includes(datatype)) {
                datatypesInUse.push(datatype);
            }
        }
        const datatypes = datatypesInUse;
        let index = datatypes.indexOf(datatype);
        index = index%this.countColorsheet.length;
        return this.countColorsheet[index];
    }
    */

    getOrthologyLink(orthology) {
        const link = 'http://www.genome.jp/dbget-bin/www_bget?' +orthology;
        return link;
    }

    /*
    colorScale(fcValue,
            min=-1,
            max=1,
            minColor = "00379e",
            midColor="ffffff",
            maxColor = "9e0000",
            nullColor="919191") {
    if (fcValue === null) {
    return "#"+nullColor;
    }
    let percent;
    if (fcValue === 0 || fcValue === 0.0) {
    return "#"+midColor;
    }
    if (fcValue < 0) {
    if (fcValue < (min)) {
    percent = 0;
    } else {
    percent = (1- (fcValue / (min) )  );
    }
    } else {
    if (fcValue > max) {
    percent = 1;
    } else {
    percent = (fcValue / max);
    }
    }
    const minRed = parseInt(minColor.substring(0,2), 16);
    const minGreen = parseInt(minColor.substring(2,4), 16);
    const minBlue = parseInt(minColor.substring(4,6), 16);
    const midRed = parseInt(midColor.substring(0,2), 16);
    const midGreen = parseInt(midColor.substring(2,4), 16);
    const midBlue = parseInt(midColor.substring(4,6), 16);
    const maxRed = parseInt(maxColor.substring(0,2), 16);
    const maxGreen = parseInt(maxColor.substring(2,4), 16);
    const maxBlue = parseInt(maxColor.substring(4,6), 16);
    let redDiffStr, greenDiffStr, blueDiffStr;
    if (fcValue < 0) {
    const redDiff = midRed - minRed;
    const greenDiff = midGreen - minGreen;
    const blueDiff = midBlue - minBlue;
    redDiffStr = ( ( redDiff * percent) + minRed).toString(16).split('.')[0];
    greenDiffStr = ( ( greenDiff * percent) + minGreen).toString(16).split('.')[0];
    blueDiffStr = ( ( blueDiff * percent) + minBlue).toString(16).split('.')[0]; 
    } else {
    const redDiff = maxRed - midRed;
    const greenDiff = maxGreen - midGreen;
    const blueDiff = maxBlue - midBlue;
    redDiffStr = ( ( redDiff * percent) + midRed).toString(16).split('.')[0];
    greenDiffStr = ( ( greenDiff * percent) + midGreen).toString(16).split('.')[0];
    blueDiffStr = ( ( blueDiff * percent) + midBlue).toString(16).split('.')[0]; 
    }
    //colors have to be 2 digits
    if (redDiffStr.length === 1) redDiffStr = '0' + redDiffStr;
    if (greenDiffStr.length === 1) greenDiffStr = '0' + greenDiffStr;
    if (blueDiffStr.length === 1) blueDiffStr = '0' + blueDiffStr;
    //assembly result
    const hexColor = '#' + redDiffStr + greenDiffStr + blueDiffStr;
    return hexColor;
    }
    */

    renderCountsTable() {
        const { node, orderedConditions } = this.props;
        if (!node || !node.counts) {
            return null;
        }
        const counts = node.counts;
        return (
            <div data-tooltip-id="tooltip-counts">
                <span style={{"font": "400 14px system-ui"}}>
                    Counts
                </span>
                <table style={{ width: '100%', tableLayout: 'fixed' }}>
                    <colgroup>
                        <col style={{ width: '70%' }} />
                        <col style={{ width: '30%' }} />
                    </colgroup>
                    <tbody>
                        {orderedConditions.map((conditionObj) => {
                            const { datatype, condition, name } = conditionObj;
                            if (counts[datatype] && counts[datatype][condition] && counts[datatype][condition][name] !== undefined) {
                                const count = counts[datatype][condition][name];
                                const countValue = count > 99999 ? count : Number(count).toPrecision(5);
                                return (
                                    <tr key={`${datatype}-${condition}-${name}`}>
                                        <td style={{wordWrap: "break-word"}}>{`${datatype} ${condition} ${name}`}</td>
                                        <td>{countValue}</td>
                                    </tr>
                                );
                            } else {
                                return null;
                            }
                        })}

                    </tbody>
                </table>
            </div>
        )
    }

    extractValues(counts, datatype, condition, name) {
        /* Try to get the value for the given datatype, condition, and name 
        from the counts object. If it doesn't exist, return null. */
        if (datatype in counts) {
            if (condition in counts[datatype]) {
                if (name in counts[datatype][condition]) {
                    return counts[datatype][condition][name];
                }
            }
        }
        return null;
    }

    renderAbundanceIndicators(orthology) {
        /* Render colored '|' indicating KO abundance for displayChoices */
        return (
            <div>
                <span style={{"font": "22px system-ui"}}>
                    {this.props.displayChoices.map((displayChoice, i) => {
                        const {datatype, condition, name} = displayChoice;
                        let value = this.extractValues(orthology.counts, datatype, condition, name);
                        if (!(value === null)) {
                            if (this.props.logScale[datatype] && value != 0) {
                                value = Math.log2(value);
                                if (value < 1 ) {
                                    value = 0;
                                }
                            }
                        }
                        const color = countColor(value,
                                                 0,
                                                 this.props.scaleRange[datatype],
                                                 datatype,
                                                 Object.keys(this.props.scaleRange));
                        return (
                            <span key={i} style={{color: color}}>
                                <strong>|</strong>
                            </span>
                        )
                    })}
                </span>
            </div>
        )
    }

    render() {
        const { node, width } = this.props;
        if (!node) {
            return <div></div>;
        }
        const orthologies = node.orthologies;
        return (
            <div>
                <div style={{ width: width }}>
                    {this.renderCountsTable()}
                </div>
                <div style={{ width: width }} data-tooltip-id="tooltip-orthologies">
                    <span style={{"font": "400 14px system-ui"}}>
                        <br/>
                        Orthologies
                    </span>
                    <table style={{ width: '100%', tableLayout: 'fixed' }}>
                        <colgroup>
                            <col style={{ width: '23%' }} />
                            <col style={{ width: '60%' }} />
                            <col style={{ width: '17%'}} />
                        </colgroup>
                        <tbody>
                            {Object.keys(orthologies).map((koNumber) => (
                                <tr key={koNumber} onClick={() => this.props.koClick(koNumber)}>
                                    <td style={{wordWrap: "break-word"}}>
                                        <button className="button" style={{wordWrap: "break-word"}}>
                                            {koNumber} ({
                                                orthologies[koNumber].shorthand.length > 7
                                                    ? orthologies[koNumber].shorthand.slice(0, 5) + "..."
                                                    : orthologies[koNumber].shorthand
                                                })
                                        </button>
                                    </td>
                                    <td style={{wordWrap: "break-word"}}>
                                        {orthologies[koNumber].description}
                                    </td>
                                    <td style={{wordWrap: "break-word", letterSpacing: "-0.15rem", font: "18px system-ui"}}>
                                        {this.renderAbundanceIndicators(orthologies[koNumber])}
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Tooltip id="tooltip-counts" place="top" content="Sum of count values of all orthologies assigned to this node." />
                <Tooltip id="tooltip-orthologies" place="top" content="All orthologies assigned to this node. Colored bars indicate count. Click names to select an orthology." />


            </div>
        )
    }
}

export default NodeDetails;