import "../../css/general.css";
import React from 'react';

class MagFilterForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            minCompleteness: 0,
            maxContamination: 999,
            datatypeMinimums: {
                dna_agcn: 0,
                rna: 0,
                protein: 0
            }
        }
    }

    checkFloatInput(value) {
        const validValue = parseFloat(value);
        if (isNaN(validValue)) {
            alert("Please enter a number. Use '.' for decimals.");
            return false;
        } else {
            return true;
        }
    }

    changeMinCompleteness(event) {
        event.preventDefault();
        const value = event.target.value;
        this.setState({
            ...this.state,
            minCompleteness: value
        })
    }

    submitMinCompleteness() {
        const value = this.state.minCompleteness;
        if (this.checkFloatInput(value)) {
            this.props.changeMinCompleteness(value);
        }
    }

    changeMaxContamination(event) {
        event.preventDefault();
        const value = event.target.value;
        this.setState({
            ...this.state,
            maxContamination: value
        })
    }

    submitMaxContamination() {
        const value = this.state.maxContamination;
        if (this.checkFloatInput(value)) {
            this.props.changeMaxContamination(value);
        }
    }

    handleDatatypeChange(datatype, event) {
        event.preventDefault();
        const value = event.target.value;
        this.setState({
            ...this.state,
            datatypeMinimums: {
                ...this.state.datatypeMinimums,
                [datatype]: value
            }
        })
    }

    handleDatatypeSubmit(datatype) {
        const value = this.state.datatypeMinimums[datatype];
        if (this.checkFloatInput(value)) {
            this.props.changeDatatypeMinimum(datatype, value);
        }
    }




    render() {
        return(
            <div className="menuBox">
                <div style={{"display":"flex", "justifyContent":"space-between"}}>
                    <div className="padded4" style={{"font": "400 14px system-ui"}}>
                        min completeness
                    </div>
                    <div>
                        <input
                            className="textInput"
                            type="number"
                            step="0.01"
                            min="0"
                            max="100"
                            style={{"width":"60px"}}
                            placeholder="min"
                            value={this.state.minCompleteness}
                            onChange={e => this.changeMinCompleteness(e)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    this.submitMinCompleteness()
                                }
                            }}
                        />
                        <button
                            className="button"
                            type="button"
                            value="Set"
                            onClick={() => this.submitMinCompleteness()}
                            style={{"width":"30px"}}
                        >Set</button>
                    </div>
                </div>
                <div style={{"display":"flex", "justifyContent":"space-between"}}>
                    <div className="padded4" style={{"font": "400 14px system-ui"}}>
                        max contamination
                    </div>
                    <div>
                        <input
                            className="textInput"
                            type="number"
                            step="0.01"
                            min="0"
                            max="100"
                            style={{"width":"60px"}}
                            placeholder="min"
                            value={this.state.maxContamination}
                            onChange={e => this.changeMaxContamination(e)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    this.submitMaxContamination()
                                }
                            }}
                        />
                        <button
                            className="button"
                            type="button"
                            value="Set"
                            onClick={() => this.submitMaxContamination()}
                            style={{"width":"30px"}}
                        >Set</button>
                    </div>
                </div>
                { this.props.datatypes.map((datatype) => {
                    return(
                        <div key={datatype} style={{"display":"flex", "justifyContent":"space-between"}}>
                            <div className="padded4" style={{"font": "400 14px system-ui"}}>
                                min&nbsp;{datatype}&nbsp;abundance
                            </div>
                            <div>
                                <input
                                    className="textInput"
                                    type="number"
                                    min="0"
                                    max="100"
                                    step="0.01"
                                    style={{"width":"60px"}}
                                    placeholder="min"
                                    value={this.state.datatypeMinimums[datatype]}
                                    onChange={e => this.handleDatatypeChange(datatype, e)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            this.handleDatatypeSubmit(datatype);
                                        }
                                    }}
                                />
                                <button
                                    className="button"
                                    type="button"
                                    value="Set"
                                    onClick={() => this.handleDatatypeSubmit(datatype)}
                                    style={{"width":"30px"}}
                                >Set</button>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default MagFilterForm;