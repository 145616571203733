import React from "react";
import "../../css/general.css";

function LoadingPlaceholder(props) {
  return (
    <div className="loading-placeholder" style={{'minWidth': props.minWidth}}>
        <br/>
        fetching data...
        <br/>
        <br/>
        <div className="loading-placeholder__spinner">
          🧬
        </div>
        <br/>
    </div>
  );
}

export default LoadingPlaceholder;