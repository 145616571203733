import React from "react";
import "../../css/general.css";
import ComparisonOptionsDropdown from "../general/ComparisonOptionsDropdown";
import { decodeComparisonChoices, decodeCountChoices } from "../../functions/StringConversion";

class ToplistOptions extends React.Component {
    constructor(props) {
        super(props);
        this.countMode = this.countMode.bind(this);
        this.compareMode = this.compareMode.bind(this);
        this.changeCountChoices = this.changeCountChoices.bind(this);
        this.changeComparisonChoices = this.changeComparisonChoices.bind(this);
        this.state = {
            mode: "counts", //mode can either be counts or comparisons
        }

    }

    countMode() {
        this.setState({...this.state, mode: "counts"});
    }

    compareMode() {
        if (this.props.magFilterActive) {
            alert("Fold-Change-Toplist only available for community scope.");
            this.setState({...this.state, mode: "counts"});
        } else {
            this.setState({...this.state, mode: "comparisons"});
        }
    }

    changeCountChoices(choices) {
        const decodedChoices = decodeCountChoices(choices, this.props.countMenuItems);
        this.props.changeToplistChoice("counts", decodedChoices);
    }

    changeComparisonChoices(choices) {
        const decodedChoices = decodeComparisonChoices(choices, this.props.comparisonMenuItems);
        this.props.changeToplistChoice("comparisons", decodedChoices);
    }

    render() {
        let mode = this.state.mode;
        if (this.props.magFilterActive) {
            mode = "counts";
        }
        if (mode === "counts") {
            return(
                <div>
                    <div>

                        <button
                            className="buttonActive"
                            style={{"width": this.props.width/2+"px"}}
                            onClick={this.countMode}>
                            Count-Toplist
                        </button>
                        <button
                            className="buttonInactive"
                            style={{"width": this.props.width/2+"px"}}
                            onClick={this.compareMode}>
                            Change-Toplist
                        </button>
                    </div>
                    <div className="padded4">
                        <ComparisonOptionsDropdown
                            key="counts"
                            content="counts"
                            placeholder={"please choose"}
                            menuItems={this.props.countMenuItems}
                            change={this.changeCountChoices}
                            width={this.props.width+8}
                            singleSelect={true}
                        />
                    </div>
                </div>
            )
        } else if (mode === "comparisons") {
            return(
                <div>

                    <button
                        className="buttonInactive"
                        style={{"width": this.props.width/2+"px"}}
                        onClick={this.countMode}>
                        Count-Toplist
                    </button>
                    <button
                        className="buttonActive"
                        style={{"width": this.props.width/2+"px"}}
                        onClick={this.compareMode}>
                        Change-Toplist
                    </button>
                    <div className="padded4">
                        <ComparisonOptionsDropdown
                            key="comparisons"
                            content="comparisons"
                            placeholder={"please choose"}
                            menuItems={this.props.comparisonMenuItems}
                            change={this.changeComparisonChoices}
                            width={this.props.width+8}
                            singleSelect={true}
                        />
                    </div>
                </div>
            )
        } else {
            return(
                <div>
                    <h3>INVALID MODE IN ToplistOptions</h3>
                </div>
            )
        }
    }
}

export default ToplistOptions;