import React, {useState} from 'react';
import "../../css/general.css";

const IntegerInputForm = (props) => {

    const [value, setValue] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        const validValue = parseInt(value);
        if (isNaN(validValue) || validValue<0) {
            alert("Please enter a positive integer value");
        } else {
            props.onSubmit(validValue);
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label>
                    <input
                        className="textInput"
                        type="text"
                        style={{"width": (props.width/2)+"px"}}
                        value={value}
                        onChange={
                            e => setValue(e.target.value)
                        }
                        placeholder={props.placeholder}
                    />
                </label>
                <input
                    className="button"
                    type="submit"
                    value="Set"
                    style={{"width": (props.width/2)+"px"}}
                />
            </form>
        </div>
    )

}

export default IntegerInputForm;