import "../../css/general.css";
import React from 'react';
import MultiSelect from  'react-multiple-select-dropdown-lite';
import  'react-multiple-select-dropdown-lite/dist/index.css';

const MultiSelectDropdown = (props) => {

    const handleOnchange = selections => {
        props.change(selections);
    }

    const makeMenuItems = (options) => {
        let items = []
        let option
        for (var i=0; i<options.length; i++) {
            option = options[i]
            items[i] = {label: option, value: option}
        }
        return(items)
    }

    const menuItems = makeMenuItems(props.datasets)

    return (
        <div style={{"font": "400 14px system-ui"}}>
            {props.title}
            <div className="dropdown">
                <MultiSelect
                    style={{
                        "paddingTop": "0px",
                        "--input-width": props.width+"px",
                        "--font-size": "12px",
                        "--border-radius": "1px 1px 1px 1px",
                        "--line-height": "1",
                    }}
                    onChange={handleOnchange}
                    options={menuItems}
                />
            </div>
        </div>
    )

}

export default MultiSelectDropdown;