import React, { useState, useEffect } from "react";
import "../../css/general.css";

const SimpleSearchForm = (props) => {

    const [state, setState] = useState({ searchTerm: "", formPlaceholder: "" });

    const buttonWidth = 70;

    useEffect(() => {
        if (props.searchTerm) {
            setState((prevState) => ({
            ...prevState,
            formPlaceholder: props.searchTerm,
            }));
        }
    }, [props.searchTerm]);
    
    const handleChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            searchTerm: e.target.value,
        }));
    };
    
    const handleSubmit = (e) => {
        const minLength = 3;
        e.preventDefault();
        const searchTerm = state.searchTerm;
        if (searchTerm.length < minLength) {
            alert("Search term needs to have at least " + minLength + " characters.");
        } else {
            props.setSearchTerm(state.searchTerm);
        }
    };

    let placeholder;
    if (props.formPlaceholder === undefined) {
    placeholder = "Enter Search Term";
    } else {
    placeholder = props.formPlaceholder;
    }

    return (
        <div style={{"width": props.width}}>
            <form onSubmit={handleSubmit}>
                <input
                    className="textInput"
                    type="text"
                    style={{"width": (props.width - buttonWidth + "px")}}
                    placeholder={placeholder}
                    value={state.searchTerm}
                    onChange={handleChange}
                />
                <input
                    className="button"
                    style={{"width": buttonWidth + "px"}}
                    type="submit"
                    value="Search" />
            </form>
        </div>
    );
};

export default SimpleSearchForm;