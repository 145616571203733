import React from "react";
import "../../css/general.css";

class Imprint extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <br/>
                <div className="imprint">
                    <h1>Imprint</h1>
                    <p>
                        <h2>External link disclaimer</h2>
                        Some links provided on these pages will lead you to external web pages that we did not implement. We do not have any influence on the contents of these web pages, they are provided for your information only. Although carefully checked, we are not responsible in any way for contents or layout of these pages. We are also not responsible for electronic messages that you may receive from the owners of these web pages.
                    </p>
                    <p>
                    <h2>Contact</h2>
                        Tom Tubbesing<br/>
                        CeBiTec Bielefeld University<br/>
                        D-33594 Bielefeld Germany<br/>
                        Mail: t.tubbesing@uni-bielefeld.de
                    </p>
                    <p>
                        See also <a href="http://www.uni-bielefeld.de/Universitaet/impressum.html">Disclaimer / Impressum Bielefeld University</a>.
                    </p>
                </div>
            </div>
        );
    }                
}

export default Imprint;

/*
                        <h2>Contact</h2>
                        Dr. Alexander Sczyrba<br/>
                        CeBiTec Bielefeld University<br/>
                        D-33594 Bielefeld Germany<br/>
                        Mail: asczyrba@techfak.uni-bielefeld.de
*/