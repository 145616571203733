import React from "react";
import { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';

function FancyGenericTable(props) {

//    console.log("My generic table props");
//    console.log(props);

    //console.log("GENERICTABLE: my header is PROPS.HEADER");
    //console.log(props.header);

    const columns = useMemo(
        () => props.header, [props.header]);

    let data = useMemo(
        () => props.data, [props.data]);

    /* let columns = useMemo(
        () => [
        { Header: 'sample', accessor: 'sample' },
        { Header: 'log2FC', accessor: 'l2fc' },
        { Header: 'adj. log2FC', accessor: 'adj_l2fc'},
        { Header: 'p', accessor: 'p'},
        { Header: 'adj. p', accessor: 'adjusted_p'}
    ], []);
*/

//    data = useMemo(
//        () => [
//        {sample: "1sample", l2fc: 2, "adj_l2fc": 1.5, p_value: 0.0032, adj_p_value: 0.0078}
//    ], []);


    const tableInstance = useTable({columns, data}, useSortBy)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;

    if (props.customWidth===true) {
        return (
            <div className = "padded4">
                <table {...getTableProps()} style={{ border: 'solid 1px blue', 'tableLayout': "fixed", "wordWrap": "break-word", "overflow": "hidden" }}>
                <thead>
                    {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                        <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            style={{
                            background: '#5f8aa3',
                            color: 'white',
                            fontWeight: 'bold',
                            width: column.width + 'px'
                            }}
                        >
                            <span>
                                {column.isSorted ? (column.isSortedDesc ? "🔽 " : "🔼 ") : "🟦 "}
                            </span>
                            {column.render('Header')}
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return (
                            <td
                                {...cell.getCellProps()}
                                style={{
                                padding: '10px',
                                border: 'solid 0.6px gray',
                                background: '#fff'
                                }}
                            >
                                {cell.render('Cell')}
                            </td>
                            );
                        })}
                        </tr>
                    );
                    })}
                </tbody>
                </table>
            </div>
        );
    } else if (typeof(props.customWidth) === "number") {
        //console.log("GENERICTABLE: Rendering custom width table");
        return (
            <div className = "padded4">
                <table {...getTableProps()} style={{ border: 'solid 1px blue', 'tableLayout': "fixed", "wordWrap": "break-word", "overflow": "hidden" }}>
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                    <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                        <th key={index}
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            style={{
                            background: '#5f8aa3',
                            color: 'white',
                            fontWeight: 'bold',
                            width: props.customWidth + 'px'
                            }}
                        >
                            <span>
                                {column.isSorted ? (column.isSortedDesc ? "🔽 " : "🔼 ") : "🟦 "}
                            </span>
                            {column.render('Header')}
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                        <tr key={index} {...row.getRowProps()}>
                        {row.cells.map((cell, index) => {
                            return (
                            <td key={index}
                                {...cell.getCellProps()}
                                style={{
                                padding: '10px',
                                border: 'solid 0.6px gray',
                                background: '#fff'
                                }}
                            >
                                {cell.render('Cell')}
                            </td>
                            );
                        })}
                        </tr>
                    );
                    })}
                </tbody>
                </table>
            </div>
        );
    } else {
        return (
            <div className = "padded4">
                <table {...getTableProps()} style={{ border: 'solid 1px blue'}}>
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                    <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                        <th key={index}
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            style={{
                            background: '#5f8aa3',
                            color: 'white',
                            fontWeight: 'bold',
                            }}
                        >
                            <span>
                                {column.isSorted ? (column.isSortedDesc ? "🔽 " : "🔼 ") : "🟦 "}
                            </span>
                            {column.render('Header')}
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                        <tr key={index} {...row.getRowProps()}>
                        {row.cells.map((cell, index) => {
                            return (
                            <td key={index}
                                {...cell.getCellProps()}
                                style={{
                                padding: '10px',
                                border: 'solid 0.6px gray',
                                background: '#fff'
                                }}
                            >
                                {cell.render('Cell')}
                            </td>
                            );
                        })}
                        </tr>
                    );
                    })}
                </tbody>
                </table>
            </div>
        );
    }

}


export default FancyGenericTable;