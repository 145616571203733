import React from "react";
import { ResponsiveBar } from '@nivo/bar'
import "../../css/general.css";
import LoadingPlaceholder from "../general/LoadingPlaceholder";

class KoMagsBarChart extends React.Component {

    constructor(props) {
        super(props);
        
        this.handleClick = this.handleClick.bind(this);
        /*const taxonomic_level = "genus";
        const values = [
            {sample: "r1_derived", herbert: 0, karin: 2, guenther: 7},
            {sample: "r2_derived", herbert: 10, karin: 1, guenther: 3},
            {sample: "r3_derived", herbert: 1, karin: 12, guenther: 2}
        ]
        const taxonomies = ["herbert", "karin", "guenther"]*/
    }

    handleClick(data) {
        let id = data.id;
        if (id.startsWith("others")) {
            alert("Other taxonomies are: " + this.props.other_taxonomies + ".\n\nPlease use that \"MAGs\" tab to investigate them.");
        }    else {
            if (this.props.taxonomicLevel == "mag") {
                this.props.magClick(id);
            } else {
                this.props.taxonomyClick(this.props.taxonomicLevel, id);
            }
        // if (id.startsWith("MAG ")) {
        //     id = id.substring(4);
        //     this.props.magClick(id);
        // } else {
        //     this.props.taxonomyClick(this.props.taxonomicLevel, id);
        // }
        }
    }

    //                    colors={{ scheme: 'category10' }}
    render() {
        //const colors = ["#75eab6", "#166d2a", "#82d1f4", "#33547a", "#b4a1e2", "#af5fe4", "#643176", "#ea3ffc", "#1932bf", "#91da4d", "#93a470", "#7b4419", "#f8a395", "#ae301f", "#20f53d", "#474a09", "#fb7810", "#ff1c5d", "#dbc907", "#3f16f9"]
        const colors = ["#35618f", "#5cf070", "#b14274", "#a2d665", "#823db1", "#428621", "#de19f7", "#ebc30e", "#2b3fff", "#e78607", "#62d7e1", "#900e08", "#dad2de", "#6e390d", "#e590c7"]
        const values = this.props.values;
        const taxonomic_level = this.props.taxonomicLevel;
        const leftAxis = {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: this.props.yAxisLabel,
            legendPosition: 'middle',
            legendOffset: -40
        };
        const taxonomies = this.props.taxonomies;
        if (values.length == 0) {
            return (<div className="padded4"><br/>No data to display.</div>)
        } else if (this.props.loading) {
            return (
                <div>
                <LoadingPlaceholder/>
                </div>
            )
        } else {
            return (
                <div style={{ height: "500px"}}>
                    <ResponsiveBar
                    data={values}
                    keys={taxonomies}
                    indexBy='sample'
                    margin={{ top: 20, right: 170, bottom: 120, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={colors}

                    onClick={(data) => {this.handleClick(data);}}

                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -20,
                        //legend: 'sample',
                        //legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={leftAxis}
                    labelSkipWidth={40}
                    labelSkipHeight={12}
                    labelTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                10
                            ]
                        ]
                    }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    role="application"
                    ariaLabel="MAG contributions to KO"
                    barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
                    />
                </div>
            )
        }
    }
}

export default KoMagsBarChart;