function errorAlert(data) {
    const errorType = data.error;
    //case: oo many hits for search
    if (errorType === "too_many_hits") {
        const hits = data.hits;
        const maxHits = data.max_hits;
        alert("Too many hits (" + hits + ") for this search. Maximum hits is " + maxHits + ". Please refine your search / lower the number of requested items.");
    } else { //case: other
        alert("Error: " + errorType + ". Please try again or contact the administrator.");
    }
}

export {errorAlert};