function koLink(ko) {
    const keggKoUrl = "http://www.kegg.jp/dbget-bin/www_bget?ko:"
    return keggKoUrl + ko;
}

function ecLink(ec) {
    const keggEcUrl = "https://www.genome.jp/entry/"
    return keggEcUrl + ec;
}

function moduleLink(module) {
    const keggModuleUrl = "http://www.kegg.jp/dbget-bin/www_bget?md:"
    return keggModuleUrl + module;
}

function pathwayLink(pathway) {
    const keggPathwayUrl = "https://www.genome.jp/entry/ko"
    return keggPathwayUrl + pathway;
}

export { koLink, ecLink, moduleLink, pathwayLink };