import  "../../css/general.css"
import React from 'react';
import MultiSelect from  'react-multiple-select-dropdown-lite';
import  'react-multiple-select-dropdown-lite/dist/index.css';

const SingleSelectDropdown = (props) => {
    
    const handleOnchange = value => {
        props.change(value);
    }

    const makeMenuItems = (values) => {
        let items = []
        let value
        for (var i=0; i<values.length; i++) {
            value = values[i]
            items[i] = {label: value, value: value}
        }
        return(items)
    }

    const menuItems = makeMenuItems(props.values)

    return (
        <div style={{"font": "400 14px system-ui"}}>
            {props.title}
            <div className="dropdown">
                <MultiSelect
                    style={{
                        "paddingTop": "0px",
                        "--input-width": props.width + "px",
                        "--font-size": "12px",
                        "--border-radius": "1px 1px 1px 1px",
                        "--line-height": "1"
                    }}
                    onChange={handleOnchange}
                    options={menuItems}
                    singleSelect={true}
                />
            </div>
        </div>
    )

}


export default SingleSelectDropdown;