import "../../css/general.css";
import React, {useState, useEffect} from 'react';
import MultiSelect from  'react-multiple-select-dropdown-lite';
import  'react-multiple-select-dropdown-lite/dist/index.css';


const ComparisonOptionsDropdown = (props) => {

    //const [value, setValue] = useState('');
    //const [options, setOptions] = useState('');
    //const datatypeSeparator = ': ';
    //const conditionSeparator = '_vs_';
    //const genericSeparator = '_';

    const width = (props.width-8)+"px"


    const handleOnchange = indicesString => {
        let choices = indicesString.split(',');
        if (choices.length == 1 && choices[0] == "") {
            choices = [];
        }
        props.change(choices);
    }

    const singleSelect = () => {
        if (props.singleSelect === true) {
            return(true);
        } else {
            return(false);
        }
    }

    /*
    const makeMenuItems = options => {
        if (props.content === undefined) {
            return(null);
        } else {
            let items = []
            for (var i=0; i<options.length; i++) {
                let label;
                if (props.content === "counts") {
                    const {datatype, condition, name} = options[i];
                    label = datatype + datatypeSeparator + condition + genericSeparator + name;
                } else if (props.content === "compare") {
                    const {datatype, base_condition, experimental_condition} = options[i];
                    label = datatype + datatypeSeparator + base_condition + conditionSeparator + experimental_condition;
                } else {
                    const message = "Invalid argument "+props.content+" passed to CountOptions as props.content";
                    throw message;
                }
                items[i] =  {label: label, value: i}
            }
            return(items)
        }
    }
    */

    /*
    const oldmakeMenuItem = data => {
        if (props.content === undefined) {
            return(null);
        }
        if (props.content === "counts") {
            const {datatype, condition, name} = data;
            const label = datatype + datatypeSeparator + condition + genericSeparator + name;
            const item = {label: label, value: data}
            return(item);
        } else if (props.content === "compare") {
            const {datatype, base_condition, experimental_condition} = data;
            const label = datatype + datatypeSeparator + base_condition + conditionSeparator + experimental_condition;
            const item = {label: label, value: data}

            return(item);
        } else {
            const message = "Invalid argument "+props.content+" passed to CountOptions as props.content";
            throw message;
        }
    }
    */

    //const menuItems = props.displayOptions.map(makeMenuItem);

    return (
        <div className="dropdown">
            <MultiSelect
                style={{
                    "paddingTop": "0px",
                    "--input-width": width,
                    "--font-size": "12px",
                    "--border-radius": "1px 1px 1px 1px",
                    "--line-height": "1",
                }}
                onChange={handleOnchange}
                options={props.menuItems}
                singleSelect={singleSelect()}
                clearable={false}
                largeData={true}
            />
        </div>
    )
}
export default ComparisonOptionsDropdown;