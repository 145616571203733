import React from "react";
import { useMemo } from 'react';
import { useTable, useFlexLayout } from 'react-table';

function PlainTable(props) {

//    console.log("My generic table props");
//    console.log(props);


    const columns = useMemo(
        () => props.header, [props.header]);

    let data = useMemo(
        () => props.data, [props.data]);

    /* let columns = useMemo(
        () => [
        { Header: 'sample', accessor: 'sample' },
        { Header: 'log2FC', accessor: 'l2fc' },
        { Header: 'adj. log2FC', accessor: 'adj_l2fc'},
        { Header: 'p', accessor: 'p'},
        { Header: 'adj. p', accessor: 'adjusted_p'}
    ], []);
*/

//    data = useMemo(
//        () => [
//        {sample: "1sample", l2fc: 2, "adj_l2fc": 1.5, p_value: 0.0032, adj_p_value: 0.0078}
//    ], []);


    const tableInstance = useTable({columns, data})

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (
        <div className = "padded4">
            <table {...getTableProps()} style={{ border: 'solid 1px', whiteSpace: 'nowrap'}}>
            {
                props.showHeader === false ? (null) : (
                    <thead>
                    {headerGroups.map((headerGroup, index) => (
                    <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                        <th key={column.id}
                            {...column.getHeaderProps()}
                        >
    
                            {column.render('Header')}
                        </th>
                        ))}
                    </tr>
                    ))}
                    </thead>
                )
            }
            <tbody {...getTableBodyProps()} style={{whiteSpace: 'nowrap'}}>
                {rows.map((row, index) => {
                prepareRow(row);
                return (
                    <tr key={index} {...row.getRowProps()}>
                        {row.cells.map((cell,index) => {
                            return (
                            <td 
                                key={index}
                                {...cell.getCellProps()}
                                style={{
                                whiteSpace: 'nowrap',
                                padding: '2px',
                                border: 'solid 0.6px gray',
                                background: '#fff',
                                }}
                            >
                                <div style={{"display": "flex"}}>{cell.render('Cell')}</div>
                            </td>
                            );
                        })}
                    </tr>
                );
                })}
            </tbody>
            </table>
        </div>
    );

}


export default PlainTable;