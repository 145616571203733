import React from "react";
import SimpleGenericTable from "./SimpleGenericTable";
import "../../css/general.css"

/* A component using this component must have the following state variables:
    magFilterActive: boolean
    magFilterValue: string
    metadata.mag_attributes: object with mag ids as keys
   It must have the following methods:
    fetchMagFilterAbundance(token, magIdentifier)
   It must provide the following props:
    toggleMagFilter()
    magFilterActive: boolean
    magFilterValue: string
    setMagFilterValue(value)
    magAbundanceData: array
    width: number
*/


class MagFilter extends React.Component {
    constructor(props) {
        super(props);
        this.buttonWidth = (this.props.width/2);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        //this.makeAbundanceTableRows = this.makeAbundanceTableRows.bind(this);

        this.state = {
            magIdentifier: this.props.magFilterValue
        }
    }

    handleChange(event) {
        //event.preventDefault();
        this.setState({
            ...this.state,
            magIdentifier: event.target.value
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        const filterValue = this.state.magIdentifier;
        this.props.setMagFilterValue(filterValue);
    }


/*
    makeAbundanceTableRows() {
        const datatypeSeparator = ': ';
        const genericSeparator = '_';
        const precision = 5;
        const roundValue = (value) => {
            if (value === null) {
                return("n/a");
            } else {
                return(value.toFixed(precision));
            }
        }
        const rows = () => {
            if (Object.keys(this.props.magAbundanceData).length === 0) {
                return [];
            } else {
                const rows = this.props.magAbundanceData.map((item) => {
                    const {condition, name, percent_of_community, type} = item;
                    const sampleName = type
                                    + datatypeSeparator
                                    + condition
                                    + genericSeparator
                                    + name;
                    return {
                        sample: sampleName,
                        abundance: roundValue(percent_of_community),
                    }
                });
                return rows;
            }
        };
        return rows
    }
    */


    render() {
        if (this.props.magFilterActive === false) {
            return (
                <div>
                    <div>
                        <button
                            className="buttonActive"
                            style={{"width": this.buttonWidth}}
                            onClick={() => console.log("Scope: already in community mode")}>
                            Community
                            {this.props.extraText && <br />}
                            {this.props.extraText && '& Orthologies'}
                        </button>
                        <button
                            className="buttonInactive"
                            style={{"width": this.buttonWidth}}
                            onClick={() => this.props.toggleMagFilter()}>
                            MAG
                            {this.props.extraText && <br />}
                            {this.props.extraText && '& Genes'}
                        </button>
                    </div>
                </div>
            )
        } else if (this.props.magFilterActive === true) {
            let placeholder;
            if (this.props.magFilterValue === undefined) {
                placeholder = "MAG identifier"
            } else {
                placeholder = this.props.magFilterValue
            }
            return (
                <div>
                    <div>
                        <button
                            className="buttonInactive"
                            style={{"width": this.buttonWidth}}
                            onClick={() => this.props.toggleMagFilter()}>
                            Community
                            {this.props.extraText && <br />}
                            {this.props.extraText && '& Orthologies'}
                        </button>
                        <button
                            className="buttonActive"
                            style={{"width": this.buttonWidth}}
                            onClick={() => console.log("Scope: already in MAG mode")}>
                            MAG
                            {this.props.extraText && <br />}
                            {this.props.extraText && '& Genes'}
                        </button>
                    </div>
                    <div className="padded2">   </div>
                    <form onSubmit={(this.handleSubmit)}>
                        <input
                            className="textInput"
                            type="text"
                            style={{"width": this.buttonWidth*1.5}}
                            placeholder={placeholder}
                            value={this.state.magIdentifier}
                            onChange={this.handleChange}
                        />
                        <input
                            className="button"
                            style={{"width": this.buttonWidth*0.5}}
                            type="submit"
                            value="Set"
                        />
                    </form>
                    <br/>
                    Show MAG abundance
                    <input type="checkbox" checked={this.props.showMagAbundance} onChange={this.props.toggleShowMagAbundance}/>
                    {this.props.showMagAbundance ?
                        <div>
                            <SimpleGenericTable
                                header={    [
                                    {Header: 'sample', accessor: 'sample', width: "68%"},
                                    {Header: '% abundance', accessor: 'abundance', width: "32%"}
                                ]}
                                data={this.props.magAbundanceRows}
                                customWidth={this.props.width}
                            />
                        </div>
                        :<div></div>}
                </div>

            )
        }
    }
}

function setMagFilterValue(magIdentifier) {
    //Check if value is legal
    const legalValues = Object.keys(this.state.metadata.mag_attributes);
    //check if magidentifier is legal
    if (!(legalValues.includes(magIdentifier) || magIdentifier === "unbinned")) {
        alert("No MAG with name " + magIdentifier + " found.");
        return;
    }
    //Change value
    this.setState({
        ...this.state,
        magFilterValue: magIdentifier
    }, function() {
        this.fetchMagFilterAbundance(this.props.token, magIdentifier);
    });
}

function toggleShowMagAbundance() {
    this.setState({
        ...this.state,
        showMagAbundance: !this.state.showMagAbundance
    })
}

export {MagFilter, setMagFilterValue, toggleShowMagAbundance}