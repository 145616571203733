import React from 'react';
import ReactModal from 'react-modal';
import "../../css/general.css";
import FancyGenericTable from './FancyGenericTable';
import LoadingPlaceholder from './LoadingPlaceholder';

class MagPopup extends React.Component {

    constructor(props) {
        super(props);

        this.interfaceScale = 1.0;

        this.scaleWidth = this.scaleWidth.bind(this);

        this.taxonomyTableHeader = [
            { Header: "Domain", accessor: "domain" },
            { Header: "Phylum", accessor: "phylum" },
            { Header: "Class", accessor: "class" },
            { Header: "Order", accessor: "order" },
            { Header: "Family", accessor: "family" },
            { Header: "Genus", accessor: "genus" },
            { Header: "Species", accessor: "species" },
        ];

        this.qualityTableHeader = [
            { Header: "Completeness", accessor: "completeness" },
            { Header: "Contamination", accessor: "contamination" },
            { Header: "Length", accessor: "length" },
        ];

        this.abundanceHeader = [
            { Header: 'Type', accessor: 'type' },
            { Header: 'Condition', accessor: 'condition' },
            { Header: 'Sample', accessor: 'sample' },
            { Header: '% of Community', accessor: 'percent_of_community' },
        ];
    }

    scaleWidth(width) {
        return (width * this.interfaceScale);
    }

    render() {

        if (this.props.magData === undefined) {
            return (
                <div></div>
            )
        }
        const magData = this.props.magData;
        //console.log("MAGPOPUP: magData: ");
        //console.log(magData);
        return (
            <ReactModal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.onRequestClose}
                contentLabel={"KEGG Orthology" + this.props.ko}
                style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.75)',
                      zIndex: 1000
                    },
                    content: {
                      display: "block",
                      top: "15%",
                      left: "25%",
                      bottom: "15%",
                      border: '1px solid #ccc',
                      background: '#fff',
                      overflow: 'auto',
                      WebkitOverflowScrolling: 'touch',
                      borderRadius: '4px',
                      outline: 'none',
                      padding: '20px',
                      width: "50%"
                    }
                  }}
            >
                <div className="text16"><h3>MAG:&nbsp;{this.props.mag}</h3></div>
                <div className="text16">Attributes</div>
                <div className="text16" style={{"display": "flex"}}>
                    <FancyGenericTable
                            header={this.qualityTableHeader}
                            data={[magData.quality]}
                            customWidth={false}
                            key="quality"
                    />
                    <FancyGenericTable
                            header={this.taxonomyTableHeader}
                            data={[magData.taxonomy]}
                            customWidth={false}
                            key="taxonomy"
                    />
                </div>
                <div className="text16"><br/>Abundance</div>
                <FancyGenericTable
                            header={this.abundanceHeader}
                            data={magData.abundance}
                            customWidth={false}
                            key="abundance"
                    />
            </ReactModal>
        );
    }
}

function toggleMagPopup(mag) {
    console.log(mag)
    if (mag === 'unbinned') {
        alert("The 'unbinned' section represents non-binned contigs. It is not possible to view the MAG details these.")
        return
    }
    if (!this.state.magPopupEnabled) {
        this.fetchMagDetails(this.props.token, mag);
    }
    this.setState({
        ...this.state,
        magPopupEnabled: !this.state.magPopupEnabled,
    });
}

export { MagPopup, toggleMagPopup }

/*
                <div>
                    <div className="text16">MAG Quality</div>
                    <FancyGenericTable
                            header={this.qualityTableHeader}
                            data={magData.quality}
                            customWidth={false}
                            key="quality"
                    />
                </div>
                <div>
                    <div className="text16">MAG Taxonomy</div>
                    <FancyGenericTable
                            header={this.taxonomyTableHeader}
                            data={magData.taxonomy}
                            customWidth={false}
                            key="taxonomy"
                    />
                </div>
                <div>
                    <div className="text16">MAG Abundance</div>
                    <FancyGenericTable
                            header={this.abundanceHeader}
                            data={magData.abundance}
                            customWidth={false}
                            key="abundance"
                    />
                </div>
                */