import React from 'react';
import { NavLink } from 'react-router-dom';

//style
import '../css/general.css'




class TopNavbar extends React.Component {
    render () {
      return (
        <nav className="topNavbar" style={{ margin: 10 }}>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? 'navbarlink-active' : 'navbarlink-inactive'}
            >
            Home
          </NavLink>
          <NavLink to="/kegg_orthologies"
            className={({ isActive }) =>
            isActive ? 'navbarlink-active' : 'navbarlink-inactive'}
            >
            Orthologies
          </NavLink>
          <NavLink to="/kegg_modules"
            className={({ isActive }) =>
              isActive ? 'navbarlink-active' : 'navbarlink-inactive'}
            >
            Modules
          </NavLink>
          {/* <NavLink to="/enzymes"
            className={({ isActive }) =>
              isActive ? 'navbarlink-active' : 'navbarlink-inactive'}
            >
            Enzymes
          </NavLink> */}
          <NavLink to="/kegg_pathways"
            className={({ isActive }) =>
              isActive ? 'navbarlink-active' : 'navbarlink-inactive'}
            >
            Pathways
          </NavLink> 
          <NavLink to="/mags"
            className={({ isActive }) =>
              isActive ? 'navbarlink-active' : 'navbarlink-inactive'}
            >
            MAGs
          </NavLink> 

        </nav>
      );
    }
  }

  export default TopNavbar;