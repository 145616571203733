import React from 'react';
import PlainTable from '../general/PlainTable';
import '../../css/general.css';

import { countColor } from "../../functions/Colors";


class ComparisonInfo extends React.Component {
    constructor(props) {
        super(props);
        this.makeTableHeaders = this.makeTableHeaders.bind(this);
    }

    tokenColor(datatype) {
        //Set values to get the 80% intensity color
        const value = .8;
        const maxValue = 1;
        const minValue = 0;
        //Retrieve color hexcode
        const color = countColor(
            value,
            minValue,
            maxValue,
            datatype,
            this.props.allDatatypes
            );
        return color;
    }

    makeTableHeaders() {
        return([
            {
                Header: 'Gene', accessor: 'gene',
                Cell: tableProps => (
                    tableProps.value.map((datatype, index) => {
                        const col = this.tokenColor(datatype)
                        return (<div
                                key={datatype+index}
                                style={{whiteSpace: "nowrap", borderColor: col}}
                                className="token">
                                    {datatype}
                                </div>)                    })
                )
            },
            {
                Header: 'Orthology', accessor: 'ko',
                Cell: tableProps => (
                    tableProps.value.map((datatype, index) => {
                        const col = this.tokenColor(datatype)
                        return (<div
                                key={datatype+index}
                                style={{whiteSpace: "nowrap", borderColor: col}}
                                className="token">
                                    {datatype}
                                </div>)                    })
                )
            }
        ])
    }

    render() {
        return(
            <div className="infobox" style={{"minWidth": "250px"}}>
                <span style={{"fontSize": "14px"}}>
                    Data for&nbsp;&nbsp;
                    <span style={{"fontWeight": "bold"}}>
                        {this.props.data.base_condition}
                    </span> 
                    &nbsp;&nbsp;vs.&nbsp;&nbsp;
                    <span style={{"fontWeight": "bold"}}>
                        {this.props.data.experimental_condition}
                    </span>
                </span>
                <br/><br/>
                <div>
                    <PlainTable
                        data={[this.props.data.datatypes]}
                        header={this.makeTableHeaders()}
                        customWidth={false}
                        showHeader={true}
                    />
                </div>
            </div>
        )
    }
}

export default ComparisonInfo;