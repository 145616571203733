import React from "react";
import "../../css/general.css";
import KoSearchForm from "./KoSearchForm";
import IntegerInputForm from "../general/IntegerInputForm";
import ToplistOptions from "./ToplistOptions";

class QueryForms extends React.Component {
    constructor(props) {
        super(props);
        this.changeQueryType = this.changeQueryType.bind(this);
    }

    changeQueryType(type) {
        //console.log("QUERYFORM: calling changeQueryType with type: " + type);
        this.props.setQueryType(type);
    }

    render() {
        if (this.props.queryType === "search") {
            //console.log("QUERYFORM: Going through search query form");
            return (
                <div>
                    <div>
                        <button
                            className="buttonActive"
                            style={{"width": this.props.leftWidth/2+"px"}}
                            onClick={() => this.changeQueryType("search")}>
                            Search
                        </button>
                        <button
                            className="buttonInactive"
                            style={{"width": this.props.leftWidth/2+"px"}}
                            onClick={() => this.changeQueryType("toplist")}>
                            Toplist
                        </button>
                    </div>
                    <div className="padded2">   </div>
                    <KoSearchForm
                            setSearchTerm={this.props.setSearchTerm}
                            searchTerm={this.props.searchTerm}
                            width={this.props.leftWidth}
                        />
                </div>
            )
        } else if (this.props.queryType === "toplist") {
            //console.log("QUERYFORM: Going through toplist query form");
            return (
                <div>
                    <div>
                        <button
                            className="buttonInactive"
                            style={{"width": this.props.leftWidth/2+"px"}}
                            onClick={() => this.changeQueryType("search")}>
                            Search
                        </button>
                        <button
                            className="buttonActive"
                            style={{"width": this.props.leftWidth/2+"px"}}
                            onClick={() => this.changeQueryType("toplist")}>
                            Toplist
                        </button>
                    </div>
                    <div className="padded2">   </div>
                    <ToplistOptions
                                countMenuItems={this.props.countMenuItems}
                                comparisonMenuItems={this.props.comparisonMenuItems}
                                changeToplistChoice={this.props.changeToplistChoice}
                                width={this.props.leftWidth}
                                magFilterActive={this.props.magFilterActive}
                            />
                            <IntegerInputForm
                                width={this.props.leftWidth}
                                onSubmit={this.props.submitToplistLength}
                                placeholder="number of items"
                            />
                </div>
            )
        } else {
            throw new Error("queryType not set or illegal");
        }
    }
}

export default QueryForms;