import "../../css/general.css";
import React from 'react';

class ScaleRangeForm extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            coloringEnabled: true,
            rangeValues: {}
        }
    }

    handleChange(datatype, event) {
        event.preventDefault();
        const value = event.target.value;
        let newRangeValues = this.state.rangeValues;
        newRangeValues[datatype] = value;
        this.setState({
            ...this.state,
            rangeValues: newRangeValues
        })
    }

    handleSubmit(datatype) {
        const rangeValue = this.state.rangeValues[datatype]
        const validRangeValue = parseFloat(rangeValue);
        if (isNaN(validRangeValue) || validRangeValue<0) {
            console.log("SCALERANGEFORM")
            console.log(validRangeValue);
            alert("Please enter a positive number. Use '.' for decimals.");
        } else {
            this.props.changeScale(datatype, validRangeValue);
        }
    }



    render() {
        return(
            <div>
                
                    <div style={{"font": "400 14px system-ui"}}>
                        Colored Data Values
                        <input type="checkbox" checked={this.state.coloringEnabled} onChange={this.props.toggleColoring}/>
                        <br/><br/>Scale Maximum
                        { this.props.datatypes.map((datatype) => {
                            return(
                                <div key={datatype} style={{"display":"flex", "justifyContent":"space-between"}}>
                                    <div style={{"font": "400 14px system-ui"}}>
                                        {datatype}
                                    </div>
                                    <div>
                                        <input
                                            className="textInput"
                                            type="text"
                                            style={{"width":"60px"}}
                                            placeholder="max"
                                            value={this.state.rangeValues[datatype]}
                                            onChange={e => this.handleChange(datatype, e)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.handleSubmit(datatype);
                                                }
                                            }}
                                        />
                                        <button
                                            className="button"
                                            type="button"
                                            value="Set"
                                            onClick={() => this.handleSubmit(datatype)}
                                            style={{"width":"30px"}}
                                        >Set</button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
            </div>
        )
    }
}

export default ScaleRangeForm;