import "../../css/general.css";
import React from 'react';
import MultiSelect from  'react-multiple-select-dropdown-lite';
import  'react-multiple-select-dropdown-lite/dist/index.css';

const TaxonomicLeveLDropdown = (props) => {

    const taxonomic_levels = ["domain", "phylum", "class", "order", "family", "genus", "species", "mag"];

    const handleOnchange = taxonomicLevel => {
        props.change(taxonomicLevel);
    }

    const makeMenuItems = (levels) => {
        let items = []
        let level
        for (var i=0; i<levels.length; i++) {
            level = levels[i]
            items[i] = {label: level, value: level}
        }
        return(items)
    }

    const menuItems = makeMenuItems(taxonomic_levels)

    return (
        <div style={{"font": "400 14px system-ui"}}>
            {props.title}
            <div className="dropdown">
                <MultiSelect
                    style={{
                        "paddingTop": "0px",
                        "--input-width": props.width + "px",
                        "--font-size": "12px",
                        "--border-radius": "1px 1px 1px 1px",
                        "--line-height": "1"
                    }}
                    onChange={handleOnchange}
                    options={menuItems}
                    singleSelect={true}
                />
            </div>
        </div>
    )

}

export default TaxonomicLeveLDropdown;