import React from "react";
import "../../css/general.css";
import ComparisonOptionsDropdown from "../general/ComparisonOptionsDropdown";
import ScaleRangeForm from "./ScaleRangeForm";
import { decodeComparisonChoices, decodeCountChoices } from "../../functions/StringConversion";
import LoadingPlaceholder from "../general/LoadingPlaceholder";
import DatatypeSelector from "../general/DatatypeSelector";

class DisplayOptions extends React.Component {
    constructor(props) {
        super(props);
    }

/*    componentDidUpdate(prevProps) {
        if (this.props.magFilterActive !== prevProps.magFilterActive) {
            if (this.props.magFilterActive) {
                this.setState({...this.state, mode: "counts"});
            }
        }
    }*/

    setCountMode(contentType) {
        if (contentType === "counts") {
            return(true);
        } else if (contentType === "compare") {
            return(false);
        } else {
            //throw error
            throw new Error("Invalid contentType passed to setCountMode: "+contentType);
        }
    }

    getItemsData() {
        if (this.props.magFilterActive) {
            return(this.props.countItems);
        } else
        if (this.props.contentType === "counts") {
            return(this.props.countItems);
        } else if (this.props.contentType === "compare") {
            return(this.props.comparisonItems);
        } else {
            throw new Error("Invalid contentType passed to getItemsData: "+this.props.contentType);
        }
    }

    render() {
        let countMode, contentType;
        contentType = this.props.contentType;
        if (this.props.magFilterActive) {
            contentType = "counts"
            countMode = true;
        } else {
            contentType = this.props.contentType;
            countMode = this.setCountMode(this.props.contentType);
        }
        return(
            <div>
                <div>
                    <button
                        style={{width: this.props.width/2}}
                        className={countMode ? "buttonInactive" : "buttonActive"}
                        onClick={() => { this.props.changeContentType('compare') }}
                    >
                        Fold Changes
                    </button>
                    <button
                        style={{width: this.props.width/2}}
                        className={countMode ? "buttonActive" : "buttonInactive"}
                        onClick={() => { this.props.changeContentType('counts') }}
                    >
                        Counts
                    </button>
                </div>
                <div>
                    <DatatypeSelector
                        width={this.props.width+8}
                        rangeValues={this.props.rangeValues}
                        itemsData={this.getItemsData()}
                        changeDataChoices={this.props.changeDataChoices}
                        changeRange={this.props.changeRange}
                        logScale={this.props.logScale}
                        changeLogScale={this.props.changeLogScale}
                        subunitMediansInterface={false}
                        subunitMedians={undefined}
                        changeSubunitMedians={undefined}
                        itemsType={contentType}
                        magFilterActive={this.props.magFilterActive}
                    />
                </div>
            </div>
        )
    }
}

export default DisplayOptions;