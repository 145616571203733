import React from 'react';
import "../../css/general.css";
import { koLink, ecLink, moduleLink, pathwayLink } from "../../functions/Linkmaker";

const OrthologyDetails = (props) => {

  const {
    ko,
    ecList,
    moduleList,
    pathwayList,
    shorthand,
    description,
  } = props;

  return (
    <div className="padded4">
      <div style={{ width: props.width - 4 }}>
        <table style={{ width: "100%", tableLayout: "fixed", border: "none" }}>
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "60%" }} />
            <col style={{ width: "20%" }} />
          </colgroup>
          <tbody>
            <tr>
              <td className="invisibleCell">
                <span>Selected:</span>
              </td>
              <td className="invisibleCell">
                <span>
                  <a href={koLink(ko)} target="_blank" rel="noopener noreferrer">
                    {ko}
                  </a>
                  {shorthand !== "" && <>&nbsp;({shorthand})</>}
                </span>
              </td>
              <td className="invisibleCell">
                <div style={{ textAlign: "right" }}>
                  <span>
                    <input
                      className="button"
                      type="button"
                      onClick={props.toggleKoPopup}
                      value="&nbsp;Show Details&nbsp;"
                    />
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: "100%", tableLayout: "fixed", border: "none" }}>
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "80%" }} />
          </colgroup>
          <tbody>
            <tr>
              <td className="invisibleCell">
                <span>EC Numbers:</span>
              </td>
              <td className="invisibleCell">
                <span>
                  {ecList.map((ec) => (
                    <span key={ec}>
                      <a href={ecLink(ec)} target="_blank" rel="noopener noreferrer">
                        {ec}
                      </a>
                      &nbsp;
                    </span>
                  ))}
                </span>
              </td>
            </tr>
            <tr>
              <td className="invisibleCell">
                <span>Modules:</span>
              </td>
              <td className="invisibleCell">
                <span>
                  {moduleList.map((module) => (
                    <span key={module}>
                      <a href={moduleLink(module)} target="_blank" rel="noopener noreferrer">
                        {module}
                      </a>
                      &nbsp;
                    </span>
                  ))}
                </span>
              </td>
            </tr>
            <tr>
              <td className="invisibleCell">
                <span>Description</span>
              </td>
              <td className="invisibleCell">
                <span>{description}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrthologyDetails;
