import React, {useEffect} from "react";
import {koLink, ecLink, moduleLink} from "../../functions/Linkmaker";
import "../../css/general.css";

const KoDataTable = (props) => {
    //props.koData props.comparisons props.count_samples props.contentType
    //const [koData, setKoData] = useState(data);

    const foldChangesHeader = {
        FC_data: "-",
        l2fc: "log2_fold_change",
        adj_l2fc: "shrunken_log2_fold_change",
        p: "p_value",
        adj_p: "adjusted_p_value"
    }
    const datatypeSeparator = ': ';
    const conditionSeparator = '_vs_';
    const genericSeparator = '_';

    /*
    useEffect(() => {
        if (!props.contentType in ["foldChanges", "counts"]) {
            throw new Error("Illegal contentType "+props.contentType+" passed to koDataTable");
        }
    });
    */

    const countSamplesToString = (cs) => {
        const {datatype, condition, name} = cs;
        const s = datatype + datatypeSeparator + condition + genericSeparator + name;
        return(s)
    }

    const comparisonToString = (comp) => { 
        const datatype = comp["datatype"];
        const base_condition = comp["base_condition"];
        const experimental_condition = comp["experimental_condition"];
        const s = datatype + datatypeSeparator + base_condition + conditionSeparator + experimental_condition;
        return(s);
    }

    const makeTableHeader = (contentType, foldChangesHeader) => {
        if (ko==="-") {
            return(null);
        }
        if (contentType === "foldChanges") {
            const header = Object.keys(foldChangesHeader).map((col) => {
                return(<th style={{width: width}}>{col}</th>)
            });
            return(
                <thead style={{width: width}}>
                    <tr>
                        {header}
                    </tr>
                </thead>
            );
        } else if (contentType === "counts") {
            return (
                <thead style={{width: width}}>
                    <tr>
                        <th>count_data</th>
                        <th>count   </th>
                    </tr>
                </thead>
            );
        }
    }

    const checkNull = (value) => {
        const precision = 5;
        if (value === null) {
            return("n/a");
        } else {
            return(value.toFixed(precision));
        }
    }

    const makeTableRows = (contentType, koData, comparisons, count_samples) => {
        if (koData === null) {
            return(null);
        }
        if (contentType === "foldChanges") {
            const rows = comparisons.map((comp) => {
                if ((koData.fold_change_values === undefined) || (koData.fold_change_values === null)) {
                    return(null);
                }
                const name = comparisonToString(comp);
                const {datatype, base_condition, experimental_condition} = comp;
                if (koData.fold_change_values[datatype] === undefined) {
                    return(null);
                }
                const values = koData.fold_change_values[datatype][base_condition][experimental_condition]
                return(
                    <tr style={{width: width}}>
                        <td>{name}</td>
                        <td>{checkNull(values.log2_fold_change)}</td>
                        <td>{checkNull(values.shrunken_log2_fold_change)}</td>
                        <td>{checkNull(values.p_value)}</td>
                        <td>{checkNull(values.adjusted_p_value)}</td>
                    </tr>
                );
            });
            return (
                <tbody style={{width: width}}>
                    {rows}
                </tbody>
            );
        } else if (contentType === "counts") {
            const rows = count_samples.map((sample) => {
                //console.log("mapping count samples");
                //console.log(sample)
                if (koData.count_values === undefined) {
                    return(null);
                }
                const sampleName = countSamplesToString(sample);
                const {datatype, name, condition} = sample;
                if (koData.count_values[datatype] === undefined) {
                    return(null);
                }
                const value = koData.count_values[datatype][condition][name];
                return(
                    <tr style={{width: width}}>
                        <td>{sampleName}</td>
                        <td>{checkNull(value)}</td>
                    </tr>
                );
            });
            return(
               <tbody style={{width: width}}>
                   {rows}
               </tbody> 
            );
        }
    }

    let ko;
    if (props.koData === null) {
        ko = "-";
        return(<div className="padded4">No data to display.</div>)
    } else {
        ko = props.koData.ko;
    }


    const width = props.width - 10;

    return (
        <div className="padded4" style={{width: width}}>
            <div key="valueTables" style={{width: width}}>
                <div className="koDetailsTable" style={{width: width}} key="counts">
                    <table style={{width: width}}>
                        {makeTableHeader("counts", foldChangesHeader)}
                        {makeTableRows("counts", props.koData, props.comparisons, props.count_samples)}
                    </table>
                </div>
                <br/>
                <div className="koDetailsTable" key="foldChanges">
                    <table style={{width: width}}>
                        {makeTableHeader("foldChanges", foldChangesHeader)}
                        {makeTableRows("foldChanges", props.koData, props.comparisons, props.count_samples)}
                    </table>
                </div>
            </div>
        </div>
    )
}

export default KoDataTable;