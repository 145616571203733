import React from 'react';

import NodeDetails from './NodeDetails.jsx';
import PathwayDiagram from './PathwayDiagram.jsx';
import ChoicesPopup from '../general/ChoicesPopup.jsx';
import SimpleSearchForm from '../general/SimpleSearchForm.jsx';
import LoadingPlaceholder from '../general/LoadingPlaceholder.jsx';
import KoPopup from '../general/KoPopup.jsx';
import { MagPopup, toggleMagPopup } from '../general/MagPopup.jsx';
import { TaxonomyPopup, toggleTaxonomyPopup} from '../general/TaxonomyPopup.jsx';

import KoMagsBarChart from '../moduleMaps/KoMagsBarChart.jsx';
import SingleSelectDropdown from '../general/SingleSelectDropdown.jsx';
import MultiSelectDropdown from '../general/MultiSelectDropdown.jsx';
import TaxonomicLeveLDropdown from '../moduleMaps/TaxonomicLeveLDropdown.jsx';
import DatatypeSelector from '../general/DatatypeSelector.jsx';

import { MagFilter, toggleShowMagAbundance } from "../general/MagFilter";
import { fetchKoDetails, fetchMagDetails, fetchTaxonomyDetails, fetchMagFilterAbundance } from "../../functions/Fetching";
import { getStringSeparators, makeMenuItems } from "../../functions/StringConversion";
import { updateKoMagData } from "../../functions/KoContributions";


import OrthologyDetails from '../general/OrthologyDetails.jsx';


import "../../css/general.css";

class Pathways extends React.Component {

    constructor(props) {
        super(props);

        this.setMagFilterValue = this.setMagFilterValue.bind(this);
        this.toggleShowMagAbundance = toggleShowMagAbundance.bind(this);
        this.fetchMagFilterAbundance = fetchMagFilterAbundance.bind(this);
        this.makeMenuItems = makeMenuItems.bind(this);
        this.updateKoMagData = updateKoMagData.bind(this);
        this.fetchKoDetails = fetchKoDetails.bind(this);
        this.fetchTaxonomyDetails = fetchTaxonomyDetails.bind(this);
        this.fetchMagDetails = fetchMagDetails.bind(this);

        this.toggleMagFilter = this.toggleMagFilter.bind(this);
        this.toggleChoicesPopup = this.toggleChoicesPopup.bind(this);
        this.toggleSubunitMediansEnabled = this.toggleSubunitMediansEnabled.bind(this);
        this.updateScaleRange= this.updateScaleRange.bind(this);
        //this.changeCountChoices = this.changeCountChoices.bind(this);
        this.changeDataChoices = this.changeDataChoices.bind(this);
        this.updateRenderData = this.updateRenderData.bind(this);
        this.fetchMetadata = this.fetchMetadata.bind(this);
        this.fetchPathwayData = this.fetchPathwayData.bind(this);   
        this.fetchCommunityPathwayData = this.fetchCommunityPathwayData.bind(this);
        this.fetchMagPathwayData = this.fetchMagPathwayData.bind(this);     
        this.handleDiagramScaleChange = this.handleDiagramScaleChange.bind(this);
        this.orthologNodeClick = this.orthologNodeClick.bind(this);
        this.mapNodeClick = this.mapNodeClick.bind(this);
        this.koClick = this.koClick.bind(this);
        this.toggleKoPopup = this.toggleKoPopup.bind(this);
        this.changeKoMagTaxonomicLevel = this.changeKoMagTaxonomicLevel.bind(this);
        this.changeKoMagSampleSelection = this.changeKoMagSampleSelection.bind(this);
        this.changeKoMagDatatype = this.changeKoMagDatatype.bind(this);
        this.toggleTaxonomyPopup = toggleTaxonomyPopup.bind(this);
        this.toggleMagPopup = toggleMagPopup.bind(this);
        this.updateLogScale = this.updateLogScale.bind(this);


        this.fetchGetInit = { //fetchGetInit from props does not work for some reason, time is short and this works fine
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + this.props.token
            }
        }
        this.flaskAdress = this.props.flaskAdress;
        this.leftWidth = 280;

        this.state = {
            selectedKo: 'K00001',
            koMagRenderData: {samples_without_data: [], taxonomic_level: "", taxonomies: [], values: [], other_taxonomies: []},
            loadingMetadata: false,
            loading: false,
            loadingMags: false,
            metadata: undefined,
            choicesPopupEnabled: false,
            subunitMediansEnabled: {},
            scaleRange: {},
            communityScaleRange: {},
            magScaleRange: {},
            logScale: {},
            diagramScale: 1,
            pathwayId: "ko00010",
            pathwayData: undefined,
            renderData : {imageUrl: "",
                            "orthologs": [],
                            "maps": [],
                            "compounds": []},
            choices: {"ko00680": "Glycolysis / Gluconeogenesis", "ko00620": "Citrate cycle (TCA cycle)"},
            countChoices: [],
            magFilterActive: false,
            magFilterValue: undefined,
            showMagAbundance: false,
            scopeMagAbundanceTableRows: [],
            chosenNode: null,
        }
    }

    componentDidMount() {
        /* Initializing the component */
        this.fetchMetadata()
            .then(() => this.fetchPathwayData());
        this.fetchKoDetails(this.props.token);
    }

    fetchMetadata() {
        /* fetch metadata (conditions, comparisons, count ranges, mag data) */
        this.setState({...this.state, loadingMetadata: true});
        const metadataUrl = this.flaskAdress + "/pathway_map_metadata";
        return fetch(metadataUrl, this.fetchGetInit)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Error fetching pathway maps metadata");
                }
            })
            .then(data => {
                this.props.refreshToken(data);
                this.updateMetadata(data);
                
            })
            .catch(error => this.setState({
                ...this.state,
                errorMessage: error.message,
                loadingMetadata: false}));
    }

    updateMetadata(newMetadata) {
        /* Compute new scale ranges based on metadata, then update state */
        //Adjust Scale Ranges
        let scaleRange = this.state.scaleRange;
        let logScale = {};
        let subunitMediansEnabled = {};
        const datatypes = Object.keys(newMetadata.count_ranges);
        datatypes.forEach((datatype) => {
            scaleRange[datatype] = newMetadata.count_ranges[datatype].percentile75;
            logScale[datatype] = false;
            subunitMediansEnabled[datatype] = false;
        })
        //Update State
        this.setState({
            ...this.state,
            metadata: newMetadata,
            scaleRange: scaleRange,
            logScale: logScale,
            subunitMediansEnabled: subunitMediansEnabled,
            loadingMetadata: false
        }, function() {
            this.fetchKoMagData()
        });
    }

    updateLogScale(datatype, newLogScaleValue) {
        /* Update the log scale for the given datatype */
        let newLogScale = this.state.logScale;
        newLogScale[datatype] = newLogScaleValue;
        // log2 or unlog2 the scaleRange value of the datatype. Set to 1
        // if scaleRange would be <1
        let newScaleRange = this.state.scaleRange;
        if (newLogScaleValue) {
            newScaleRange[datatype] = Math.log2(newScaleRange[datatype]);
            if (newScaleRange[datatype] < 1) {
                newScaleRange[datatype] = 1;
            }
        } else {
            newScaleRange[datatype] = Math.pow(2, newScaleRange[datatype]);
        }
        this.setState({
            ...this.state,
            logScale: newLogScale,
            scaleRange: newScaleRange
        }, function() {
            this.updateRenderData();
        });
    }

    updateScaleRange(datatype, newScaleValue) {
        /* Update the scale range for the given datatype */
        let newScaleRange = this.state.scaleRange;
        newScaleRange[datatype] = newScaleValue;
        this.setState({
            ...this.state,
            scaleRange: newScaleRange
        }, function() {
            this.updateRenderData();
        });
    }

    toggleSubunitMediansEnabled(datatype) {
        /* Toggle the subunit medians enabled state for the input datatype */
        let newValues = this.state.subunitMediansEnabled;
        newValues[datatype] = !newValues[datatype];
        this.setState({
            ...this.state,
            subunitMediansEnabled: newValues
        }, function() {
            this.updateRenderData();
        });
    }

    fetchKoMagData() {
        if (this.state.selectedKo === null || this.state.koMagSampleSelection === null || this.state.koMagTaxonomicLevel === null) {
            return;
        }
        if (this.state.selectedKo === "" || this.state.koMagSampleSelection === "" || this.state.koMagTaxonomicLevel === "") {
            return;
        }
        if (this.state.koMagTaxonomicLevel === undefined || this.state.koMagSampleSelection === undefined) {
            return;
        }
        this.setState({
            ...this.state,
            loadingMags: true
        })
        let samples = this.state.koMagSampleSelection;
        samples = samples.toString();
        const koMagUrl = this.flaskAdress
                         + "mag_ko_plot_data/"
                         + this.state.selectedKo + '/'
                         + this.state.koMagTaxonomicLevel + '/'
                         + this.state.koMagDatatype + '/'
                         + samples;
        const fetchGetInit= { //fetchGetInit from props does not work for some reason, time is short and this works fine
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + this.props.token
            }
        }
        fetch(koMagUrl, fetchGetInit)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch MAG abundances from '+koMagUrl);
                    this.setState({...this.state, loadingMags: false})
                }
            })
            .then(data => {
                this.props.refreshToken(data);
                this.updateKoMagData(data)
            })
            .catch(error => this.setState({...this.state, errorMessage: error.message, loadingMags: false}))
    }

    /*updateKoMagData(newData) {
        this.setState({
            ...this.state,
            koMagRenderData: newData,
            loadingMags: false
        })
    }*/


        

    fetchPathwaySearch(searchTerm) {
        /* For the user provided searchTerm, fetch the list of matching results.
           Display the results in a popup and let the user choose one. */
        this.setState({...this.state, loading: true});
        const url = this.flaskAdress + "pathway_search/" + searchTerm;
        fetch(url, this.fetchGetInit)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Error fetching pathway search results");
                }
            })
            .then(data => {
                this.props.refreshToken(data);
                this.setState({
                    ...this.state,
                    choices: data,
                    loading: false,
                    choicesPopupEnabled: true
                });
            })
            .catch(error => this.setState({
                ...this.state,
                errorMessage: error.message,
                loading: false}));    
    }

    fetchPathwayData() {
        this.setState({
            ...this.state,
            loading: true,
            choicesPopupEnabled: false,
        }, function() {
            if (this.state.magFilterActive) {
                this.fetchMagPathwayData();
            } else {
                this.fetchCommunityPathwayData();
            }
        });
    }

    fetchCommunityPathwayData() {
        /* For the input pathwayId, fetch data that is needed to render the
              pathway map including abundances, fold changes etc. */
        const pathwayId = this.state.pathwayId;
        const url = this.flaskAdress + "/pathway_map_community_data/" + pathwayId;
        fetch(url, this.fetchGetInit)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Error fetching community pathway map data");
                }
            })
            .then(data => {
                this.props.refreshToken(data);
                this.setState({
                    ...this.state,
                    loading: false,
                    choicesPopupEnabled: false,
                    pathwayData: data,
                }, function() {
                    this.updateRenderData();
                });
            })
            .catch(error => this.setState({
                ...this.state,
                errorMessage: error.message,
                loading: false}));
    }

    fetchMagPathwayData() {
        /* For the input pathwayId, fetch data that is needed to render the
                pathway map including abundances, fold changes etc. */
        const pathwayId = this.state.pathwayId;
        const magId = this.state.magFilterValue;
        const url = this.flaskAdress + "/pathway_map_mag_data/" + pathwayId + "/" + magId;
        fetch(url, this.fetchGetInit)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Error fetching mag pathway map data");
                }
            })
            .then(data => {
                this.props.refreshToken(data);
                //If a scale range is present in the metadata but not present in the
                //mag data, add a dummy value (1) to the magScaleRange
                let newScaleRange = data.scale_ranges;
                for (let datatype in this.state.metadata.count_ranges) {
                    if (!(datatype in data.scale_ranges)) {
                        newScaleRange[datatype] = 1.0;
                    }
                }
                this.setState({
                    ...this.state,
                    loading: false,
                    choicesPopupEnabled: false,
                    pathwayData: data,
                    magScaleRange: data.scale_ranges,
                    scaleRange: data.scale_ranges,
                }, function() {
                    this.updateRenderData();
                });
            })
            .catch(error => this.setState({
                ...this.state,
                errorMessage: error.message,
                loading: false}));
    }

    /*
    changeCountChoices(choiceIndices) {
        // Update the count choices and trigger update of renderData
        const menuItems = makeMenuItems(this.state.metadata.count_samples, "counts");
        const newChoices = decodeCountChoices(choiceIndices, menuItems)
        console.log(newChoices)
        this.setState({
            ...this.state,
            countChoices: newChoices,
        }, function() {
            this.updateRenderData();
        });
    }
    */

    changeDataChoices(itemsType, datatype, newChoices) {
        if (!(itemsType === "counts")) {
            alert("Error: changeDataChoices called with itemsType " + itemsType);
        }
        let updatedChoices = this.state.countChoices;
        //Iterate through the choice. Remove any that have the input datatype.
        //Then add the new choices.
        for (let i = 0; i < updatedChoices.length; i++) {
            if (updatedChoices[i].datatype === datatype) {
                updatedChoices.splice(i, 1);
                i--;
            }
        }
        for (let i = 0; i < newChoices.length; i++) {
            updatedChoices.push(newChoices[i]);
        }
        this.setState({
            ...this.state,
            countChoices: updatedChoices,
        }, function() {
            this.updateRenderData();
        });
    }

    
    updateRenderData() {
        /* Convert the data fetched from the server into data that is used
           to instruct the PathwayDiagram component for rendering the module
           map. */
        const data = this.state.pathwayData;
        console.log("PATHWAYS: pathwayData is", data);
        const newImageUrl = "https://www.kegg.jp/kegg/pathway/ko/" + this.state.pathwayId + ".png";
        let renderData = {
            imageUrl: newImageUrl,
            "orthologs": [],
            "maps": [],
            "compounds": []
        };
        if (!(data === undefined)) {
            // Process data for compunds nodes which are in data['compound_nodes']
            for (let nodeId in data['compound_nodes']) {
                const nodeData = data['compound_nodes'][nodeId];
                const nodeRenderData = this.compoundNodeRenderData(nodeId, nodeData);
                renderData['compounds'].push(nodeRenderData);
            }
            // Process data for ortholog nodes which are in data['ortholog_nodes']
            for (let nodeId in data['ortholog_nodes']) {
                const nodeData = data['ortholog_nodes'][nodeId];
                const nodeRenderData = this.orthologNodeRenderData(nodeId, nodeData);
                renderData['orthologs'].push(nodeRenderData);
            }
            // Process data for map nodes which are in data['map_nodes']
            for (let nodeId in data['map_nodes']) {
                const nodeData = data['map_nodes'][nodeId];
                const nodeRenderData = this.mapNodeRenderData(nodeId, nodeData);
                renderData['maps'].push(nodeRenderData);
            }
        }
        // Update state
        this.setState({
            ...this.state,
            renderData: renderData
        });
    }

    orthologNodeRenderData(nodeId, nodeData) {
        /* Compute the render data for an ortholog node */
        const nodeRenderData = {
            "id": nodeId,
            "name": nodeData['name'],
            "link": nodeData['link'],
            "label": nodeData['label'],
            "x": nodeData.coordinates['x_left'],
            "width": nodeData.coordinates['x_right'] - nodeData.coordinates['x_left'],
            "y": nodeData.coordinates['y_top'],
            "height": nodeData.coordinates['y_bottom'] - nodeData.coordinates['y_top'],
            "orthologies": nodeData['orthologies']
        }
        if (this.state.subunitMediansEnabled) {
            nodeRenderData['counts'] = nodeData['cumulative_subunit_median_counts'];
        } else {
            nodeRenderData['counts'] = nodeData['cumulative_counts'];
        }
        return nodeRenderData;
    }

    compoundNodeRenderData(nodeId, nodeData) {
        /* Compute the render data for a compound node */
        const nodeRenderData = {
            "id": nodeId,
            "name": nodeData['name'],
            "link": nodeData['link'],
            "label": nodeData['label'],
            "x": nodeData.coordinates['x_left'],
            "width": nodeData.coordinates['x_right'] - nodeData.coordinates['x_left'],
            "y": nodeData.coordinates['y_top'],
            "height": nodeData.coordinates['y_bottom'] - nodeData.coordinates['y_top'],
        } 
        return nodeRenderData;
    }

    mapNodeRenderData(nodeId, nodeData) {
        /* Compute the render data for a map node */
        const nodeRenderData = {
            "id": nodeId,
            "name": nodeData['name'],
            "link": nodeData['link'],
            "label": nodeData['label'],
            "x": nodeData.coordinates['x_left'],
            "width": nodeData.coordinates['x_right'] - nodeData.coordinates['x_left'],
            "y": nodeData.coordinates['y_top'],
            "height": nodeData.coordinates['y_bottom'] - nodeData.coordinates['y_top'],
        }
        return nodeRenderData;
    }



    toggleChoicesPopup() {
        this.setState({...this.state, choicesPopupEnabled: !this.state.choicesPopupEnabled});
    }

    commitPathwayChoice(choice) {
        /* Commit the user's choice and close the choices popup */
        this.setState({
            ...this.state,
            pathwayId: choice,
        }, function() {
            this.fetchPathwayData();
        });
    }

    toggleMagFilter() {
        /* Toggle the MAG filter */
        //Backup the current community or mag scale range and put in the other
        //scale range
        let newScaleRange, newMagScaleRange, newCommunityScaleRange;
        if (this.state.magFilterActive) {
            newScaleRange = this.state.communityScaleRange;
            newMagScaleRange = this.state.scaleRange;
            newCommunityScaleRange = this.state.communityScaleRange;
        } else {
            newScaleRange = this.state.magScaleRange;
            newMagScaleRange = this.state.magScaleRange;
            newCommunityScaleRange = this.state.scaleRange;
        }
        this.setState({
            ...this.state,
            magFilterActive: !this.state.magFilterActive,
            scaleRange: newScaleRange,
            communityScaleRange: newCommunityScaleRange,
            magScaleRange: newMagScaleRange,
        },
        function() {
            this.fetchPathwayData();
        });
    }

    setMagFilterValue(magIdentifier) {
        //Check if value is legal
        const legalValues = Object.keys(this.state.metadata.mag_attributes);
        //check if magidentifier is legalL
        if (!(legalValues.includes(magIdentifier) || magIdentifier === "unbinned")) {
            alert("No MAG with name " + magIdentifier + " found.");
            return;
        }
        //Change value
        this.setState({
            ...this.state,
            magFilterValue: magIdentifier
        }, function() {
            this.fetchMagFilterAbundance(this.props.token, magIdentifier);
            this.fetchPathwayData();
        });
    }

    handleDiagramScaleChange(event) {
        /* Handle the user changing the diagram scale */
        this.setState({
            ...this.state,
            diagramScale: event.target.value,
        });
    }

    mapNodeClick(node) {
        /* When user clicks on a map node, change the pathway to the one
              that the map node links to */
        const pathwayId = node.name.split(":")[1]; 
        this.commitPathwayChoice(pathwayId)
    }

    orthologNodeClick(node) {
        this.setState({
            ...this.state,
            chosenNode: node,
        })
    }

    compoundNodeClick(node) {
        window.open(node.link, '_blank');
    }

    koClick(koId) {
        this.setState({ selectedKo: koId }, () => {
            this.fetchKoDetails(this.props.token);
            this.fetchKoMagData(this.props.token);
        });
    }

    toggleKoPopup() {
        this.setState({
            ...this.state,
            koPopupEnabled: !this.state.koPopupEnabled
        });
    }

    changeKoMagDatatype(datatype) {
        this.setState({
            ...this.state,
            koMagDatatype: datatype
        },
        function() {
            this.fetchKoMagData()
        })
    }

    changeKoMagTaxonomicLevel(taxonomicLevel) {
        this.setState({
            ...this.state,
            koMagTaxonomicLevel: taxonomicLevel
        },
        function() {
            if (!(this.state.koMagTaxonomicLevel === undefined) && !(this.state.koMagTaxonomicLevel === null)) {
                this.fetchKoMagData(this.props.token);
            }
        })
    }

    changeKoMagSampleSelection(sampleSelection) {
        this.setState({
            ...this.state,
            koMagSampleSelection: sampleSelection
        },
        function() {
            if (!(this.state.koMagTaxonomicLevel === undefined) && !(this.state.koMagTaxonomicLevel === null)) {
                this.fetchKoMagData(this.props.token);
            }
        })
    }





    render() {
        if (this.state.metadata === undefined || this.state.metadata === null) {
            return (
                <LoadingPlaceholder/>
            )
        }
        let koEcList = [];
        let koModuleList= [];
        let koPathwayList = [];
        let koShorthand = "";
        let koDescription = "";
        if (!(this.state.koDetails === undefined)) {
            koEcList = this.state.koDetails.ec_list;
            koShorthand = this.state.koDetails.shorthand;
            koDescription = this.state.koDetails.description;
            //go through all objects in this.state.koDetails.modules and push the value behind the "module" key to koModuleList
            koModuleList = [];
            for (let i = 0; i < this.state.koDetails.modules.length; i++) {
                koModuleList.push(this.state.koDetails.modules[i].module);
            }
        }
        let koNotFoundMessage = ""
        if (this.state.koMagRenderData.samples_without_data.length > 0) {
            koNotFoundMessage = "KO not found in the following samples: "
        }
        return(
            <div>
            {this.state.loadingMetadata ? <LoadingPlaceholder/> :
                <div className="padded_flex">
                    <div style={{width: this.leftWidth+20}}>
                        <div className="padded2">
                            Scope
                            <div className="menuBox">
                                <div style={{"font": "400 14px system-ui"}}>
                                    <MagFilter
                                        width={this.leftWidth}
                                        magFilterActive={this.state.magFilterActive}
                                        magFilterValue={this.state.magFilterValue}
                                        setMagFilterValue={this.setMagFilterValue}
                                        toggleMagFilter={this.toggleMagFilter}
                                        toggleShowMagAbundance={this.toggleShowMagAbundance}
                                        showMagAbundance={this.state.showMagAbundance}
                                        magAbundanceRows={this.state.scopeMagAbundanceTableRows}
                                    />                           
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="padded2">
                            Search Pathways
                            <div className="menuBox">
                                <SimpleSearchForm
                                    width={this.leftWidth}
                                    setSearchTerm={(searchTerm) => this.fetchPathwaySearch(searchTerm)}
                                    formPlaceholder={"pathway id or text"}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="padded2">
                                <br/>
                                Node Details
                                <div className="menuBox">
                                    <div className="padded4">
                                        <NodeDetails
                                            node={this.state.chosenNode}
                                            width={this.leftWidth}
                                            koClick={this.koClick}
                                            displayChoices={this.state.countChoices}
                                            logScale={this.state.logScale}
                                            scaleRange={this.state.scaleRange}
                                            orderedConditions={this.state.metadata.count_samples}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="padded2">
                            Show in Nodes
                            <div className="menuBox">
                                <div className="padded4" style={{"font": "400 14px system-ui"}}>
                                    <DatatypeSelector
                                        width={this.leftWidth}
                                        rangeValues={this.state.scaleRange}
                                        itemsData={this.state.metadata.count_samples}
                                        changeDataChoices={this.changeDataChoices}
                                        changeRange={this.updateScaleRange}
                                        logScale={this.state.logScale}
                                        changeLogScale={this.updateLogScale}
                                        subunitMediansInterface={true}
                                        subunitMedians={this.state.subunitMediansEnabled}
                                        changeSubunitMedians={this.toggleSubunitMediansEnabled}
                                        itemsType={"counts"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="padded2">
                                <br/>
                                Zoom
                                <div className="menuBox">
                                    <input type="range"
                                    min="1.0"
                                    max="2.0"
                                    step="0.1"
                                    value={this.state.diagramScale}
                                    onChange={this.handleDiagramScaleChange}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="padded2" style={{minWidth: "600px", flexShrink: 0}}>
                        Pathway Map {this.state.pathwayId}
                        {this.state.loading ? <LoadingPlaceholder/> :
                            <PathwayDiagram
                                diagramScale={this.state.diagramScale}
                                renderData={this.state.renderData}
                                displayChoices={this.state.countChoices}
                                scaleRange={this.state.scaleRange}
                                orthologNodeClick={this.orthologNodeClick}
                                compoundNodeClick={this.compoundNodeClick}
                                mapNodeClick={this.mapNodeClick}
                                selectedNode={this.state.chosenNode}
                                logScale={this.state.logScale}
                            />
                        }
                    </div>
                    <div>
                        <div className="padded4">
                            Selected Orthology
                            <div className="infobox" style={{"overflow": "hidden", "width": "600px", "font": "400 14px system-ui"}}>
                                <OrthologyDetails
                                    width={600}
                                    ko={this.state.selectedKo}
                                    ecList={koEcList}
                                    moduleList={koModuleList}
                                    pathwayList={koPathwayList}
                                    shorthand={koShorthand}
                                    description={koDescription}
                                    toggleKoPopup={this.toggleKoPopup}
                                />
                            </div>


                        </div>
                        <div className="padded4">
                            Taxonomic Contributions to {this.state.selectedKo} Abundance
                            <div className="infobox" style={{width: "600px"}}>
                                <div className="padded4" style={{"display": "flex","font": "400 14px system-ui"}}>
                                    <SingleSelectDropdown
                                        title="Datatype"
                                        change={this.changeKoMagDatatype}
                                        values={Object.keys(this.state.scaleRange)}
                                        width={120}
                                    />
                                    <TaxonomicLeveLDropdown
                                        title="Tax. Level"
                                        change={this.changeKoMagTaxonomicLevel}
                                        width={100}
                                    />
                                    <MultiSelectDropdown
                                        title="Taxonomy Data"
                                        change={this.changeKoMagSampleSelection}
                                        datasets={this.state.metadata.mag_abundance_samples}
                                        width={370}
                                    />
                                </div>
                                <div className="padded4">
                                    {this.state.loadingMags?
                                        <LoadingPlaceholder/> :
                                        <KoMagsBarChart
                                            values={this.state.koMagRenderData.values}
                                            other_taxonomies={this.state.koMagRenderData.other_taxonomies}
                                            taxonomicLevel={this.state.koMagRenderData.taxonomic_level}
                                            taxonomies={this.state.koMagRenderData.taxonomies}
                                            loading={this.state.loadingMags}
                                            magClick={this.toggleMagPopup}
                                            taxonomyClick={this.toggleTaxonomyPopup}
                                        />
                                    }
                                    {koNotFoundMessage}{this.state.koMagRenderData.samples_without_data.join(", ")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ChoicesPopup
                        isOpen={this.state.choicesPopupEnabled}
                        onRequestClose={() => this.toggleChoicesPopup()}
                        choices={this.state.choices}
                        commitChoice={(choice) => this.commitPathwayChoice(choice)}
                    />    
                </div>
            }
            <KoPopup
                ko={this.state.selectedKo}
                koData={this.state.koDetails}
                isOpen={this.state.koPopupEnabled}
                onRequestClose={() => this.toggleKoPopup()}
            />
            <MagPopup
                mag={this.state.selectedMag}
                magData={this.state.magDetails}
                isOpen={this.state.magPopupEnabled}
                onRequestClose={this.toggleMagPopup}
            />
            <TaxonomyPopup
                data={this.state.taxonomyDetails}
                isOpen={this.state.taxonomyPopupEnabled}
                onRequestClose={this.toggleTaxonomyPopup}
            />
        </div>
        )
    }

}

export default Pathways;

/*
<ComparisonOptionsDropdown
key="counts"
placeholder={"please choose"}
menuItems={countMenuItems}
change={this.changeCountChoices}
width={this.leftWidth+8}
/>

*/