import React, {useEffect, useRef, useState} from "react";
import Tippy from '@tippyjs/react';
import {followCursor} from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import { countColor, drawCountColorScale } from "../../functions/Colors"

function PathwayDiagram(props) {

    const canvasRef = useRef(null)
    const [tooltipContent, setTooltipContent] = useState(null);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const canvasDisplayScale = props.diagramScale;
    let canvasContext;
    let renderData, scaleRange;
    const keggPathwayImageMargin = 1; //Thickness of the black box around the image

    const legendWidth = 50;
    const canvasDisplayWidthMargin = 50 + legendWidth * ( Object.keys(props.scaleRange).length -1 ); //High, because we also need space for the colorscale

    const style = {
        scale: canvasDisplayScale,
        targetOffset: 1,
        borderColor: "black",
        borderWidth: 3,
        fontSize: 10*canvasDisplayScale,
        fontFamily: 'Arial',
        textAlign: 'center',
        textBaseline: 'middle',
        titleMaxLength: 5,
        selectedBorderColor: "black",
        selectedBorderWidth: 4,
    };

    /*
    const countColorsheet = [
        {
            minColor: "fbceb1",
            midColor: "ffffff",
            maxColor: "008210",
            nullColor: "919191"
        },
        {
            minColor: "fbceb1",
            midColor: "ffffff",
            maxColor: "9e000",
            nullColor: "919191"
        },
        {
            minColor: "fbceb1",
            midColor: "ffffff",
            maxColor: "a5790a",
            nullColor: "919191"
        },
        {
            minColor: "fbceb1",
            midColor: "ffffff",
            maxColor: "00379e",
            nullColor: "919191"
        },
    ]
    */

    let datatypesInUse = [];
    for (let i=0; i<props.displayChoices.length; i++) {
        const datatype = props.displayChoices[i].datatype;
        if (!datatypesInUse.includes(datatype)) {
            datatypesInUse.push(datatype);
        }
    }
    const allDatatypes = Object.keys(props.scaleRange);

    useEffect(() => {
        const canvasElement = canvasRef.current;
        canvasContext = canvasElement.getContext("2d");
        const image = new Image();
        image.src = props.renderData.imageUrl;
        const imageLoadPromise = new Promise((resolve) => {
            image.onload = () => {
                canvasElement.width = (image.width + canvasDisplayWidthMargin) * canvasDisplayScale;
                canvasElement.height = image.height * canvasDisplayScale;
                canvasContext.fillStyle = "white";
                canvasContext.fillRect(0, 0, canvasElement.width, canvasElement.height);
                canvasContext.drawImage(image,
                    keggPathwayImageMargin,
                    keggPathwayImageMargin,
                    image.width - (2*keggPathwayImageMargin*canvasDisplayScale),
                    image.height - (2*keggPathwayImageMargin*canvasDisplayScale),
                    0,
                    0,
                    (image.width - (2*keggPathwayImageMargin*canvasDisplayScale))*canvasDisplayScale,
                    (image.height - (2*keggPathwayImageMargin*canvasDisplayScale))*canvasDisplayScale,
                );
                resolve();
            };
        });
        imageLoadPromise.then(() => {
            applyLogScale();
            draw();
            addEventListeners();
        });
    }, [props.renderData, props.displayChoices, props.diagramScale, props.scaleRange, props.selectedNode]);
    
    const applyLogScale = () => {
        //Apply log2 scaling to props.renderData and props.scaleRange for
    // each datatype in props.logScale. Write the results to renderData
    // and scaleRange.
        renderData = {
            imageUrl: props.renderData.imageUrl,
            orthologs: [],
            compounds: props.renderData.compounds,
            maps: props.renderData.maps,
        }
        for (let i=0; i<props.renderData.orthologs.length; i++) {
            let newOrtholog = {
                id: props.renderData.orthologs[i].id,
                x: props.renderData.orthologs[i].x,
                y: props.renderData.orthologs[i].y,
                height: props.renderData.orthologs[i].height,
                width: props.renderData.orthologs[i].width,
                link: props.renderData.orthologs[i].link,
                name: props.renderData.orthologs[i].name,
                label: props.renderData.orthologs[i].label,
                orthologies: props.renderData.orthologs[i].orthologies,
                counts: {},
            }
            //Iterate through the datatypes in counts
            for (let datatype in props.renderData.orthologs[i].counts) {
                //Iterate through the conditions and samples and apply log2 scaling if
                //defined in props.logScale
                newOrtholog.counts[datatype] = {};
                for (let condition in props.renderData.orthologs[i].counts[datatype]) {
                    newOrtholog.counts[datatype][condition] = {};
                    for (let samplename in props.renderData.orthologs[i].counts[datatype][condition]) {
                        let count = props.renderData.orthologs[i].counts[datatype][condition][samplename];
                        if (props.logScale[datatype] && count != 0) {
                            if (count < 1) {   
                                count = 0;
                            } else {
                                count = Math.log2(count);
                            }
                        }
                        newOrtholog.counts[datatype][condition][samplename] = count;
                    }
                }
            }
            renderData.orthologs.push(newOrtholog);
        }
    }

    const draw = () => {
        //draw orthology nodes with data
        for (let i=0; i<renderData.orthologs.length; i++) {
            drawOrthologNode(renderData.orthologs[i]);
        }
        //draw legends
        drawScales();
    }

    const extractValues = (counts, datatype, condition, name) => {
        /* Try to get the value for the given datatype, condition, and name 
        from the counts object. If it doesn't exist, return null. */
        if (datatype in counts) {
            if (condition in counts[datatype]) {
                if (name in counts[datatype][condition]) {
                    return counts[datatype][condition][name];
                }
            }
        }
        return null;
    }

    const drawOrthologNode = (nodeData) => {
        //Draw one colored section of the rectangle for each entry in 
        // props.displayChoices
        const sectionWidth = nodeData.width / props.displayChoices.length;
        canvasContext.strokeStyle = style.borderColor;
        canvasContext.lineWidth = style.borderWidth;
        canvasContext.strokeWidth = style.borderWidth;
        
        //if displayChoices is empty, draw a rectangle in nullcolor
        if (props.displayChoices.length === 0) {
            canvasContext.beginPath();
            canvasContext.fillStyle = "grey";
            canvasContext.fillRect(
                nodeData.x * style.scale,
                nodeData.y * style.scale,
                nodeData.width * style.scale,
                nodeData.height * style.scale);
            canvasContext.stroke();
        }
        for (let i=0; i<props.displayChoices.length; i++) {
            const displayChoice = props.displayChoices[i];
            const {datatype, condition, name} = displayChoice;
            //const {minColor, midColor, maxColor, nullColor} = countColor(datatype);
            const value = extractValues(nodeData.counts, datatype, condition, name);
            // const color = colorScale(
            //     value,
            //     0,
            //     props.scaleRange[datatype],
            //     minColor,
            //     midColor,
            //     maxColor,
            //     nullColor
            // )
            const scaleMinimum = 0;
            const color = countColor(value,
                                     scaleMinimum,
                                     props.scaleRange[datatype],
                                     datatype,
                                     allDatatypes)
            const sectionXleft = nodeData.x + i*sectionWidth -1; 
            const sectionXright = nodeData.x + (i+1)*sectionWidth;
            canvasContext.beginPath();
            canvasContext.fillStyle = color;
            canvasContext.fillRect(
                sectionXleft * style.scale,
                nodeData.y * style.scale,
                (sectionXright - sectionXleft) * style.scale,
                nodeData.height * style.scale);
            canvasContext.stroke();
        }
        /* Write a title on the node. The title will be the shorthand of
           the first orthology in the node. If the node has more than 
           1 orthologies, add '...' behind the shorthand. */
        const firstKey = Object.keys(nodeData.orthologies)[0];
        let title = nodeData.orthologies[firstKey].shorthand;
        //Slice the title length, add ... if it's too long. Also add ... if
        // there are multiple orthologies in the node.
        if (Object.keys(nodeData.orthologies).length > 1) {
            if (title.length > style.titleMaxLength)  {
                title = title.slice(0, style.titleMaxLength) + '...';
            } else {
                title = title + '...';
            }
        } else {
            if (title.length > style.titleMaxLength)  {
                title = title.slice(0, style.titleMaxLength) + '...';
            }
        }
        const textX = nodeData.x + ((nodeData.width)/2);
        const textY = nodeData.y + ((nodeData.height)/2);
        canvasContext.font = style.fontSize + 'px ' + style.fontFamily;
        canvasContext.textAlign = style.textAlign;
        canvasContext.textBaseline = style.textBaseline;
        canvasContext.strokeText(title, textX*style.scale, textY*style.scale);
        canvasContext.fillStyle = "white";
        canvasContext.fillText(title, textX*style.scale, textY*style.scale);


        //Draw a thin rectangle around the node
        canvasContext.beginPath();
        canvasContext.strokeStyle = "black";
        canvasContext.lineWidth = 2*style.scale;
        canvasContext.rect(
            nodeData.x * style.scale,
            nodeData.y * style.scale,
           (nodeData.width+1) * style.scale,
           (nodeData.height+1) * style.scale);
        canvasContext.stroke();
        //Draw extra thick rectangle if node currently selected
        if (props.selectedNode) {
            if (props.selectedNode.id === nodeData.id) {
                canvasContext.strokeStyle = style.selectedBorderColor;
                canvasContext.lineWidth = style.selectedBorderWidth * style.scale;
                canvasContext.strokeWidth = style.selectedBorderWidth * style.scale;
                canvasContext.beginPath();
                canvasContext.rect(
                    (nodeData.x-2) * style.scale,
                    (nodeData.y-2) * style.scale,
                    (nodeData.width+4) * style.scale,
                    (nodeData.height+4) * style.scale);
                canvasContext.stroke();
                canvasContext.beginPath();
                canvasContext.strokeStyle = 'white';
                canvasContext.lineWidth = (style.selectedBorderWidth-1) * style.scale;
                canvasContext.strokeWidth = (style.selectedBorderWidth-1) * style.scale;
                canvasContext.rect(
                    (nodeData.x+1) * style.scale,
                    (nodeData.y+1) * style.scale,
                    (nodeData.width-2) * style.scale,
                    (nodeData.height-2) * style.scale);
                canvasContext.stroke();
                canvasContext.strokeStyle = 'black'
            }
        }
    }

    /*
    const getColorsheet = (datatype) => {
            const datatypes = datatypesInUse;
            let index = datatypes.indexOf(datatype);
            index = index%countColorsheet.length;
            return countColorsheet[index];
    }

    const colorScale = (fcValue,
                        min=-1,
                        max=1,
                        minColor = "00379e",
                        midColor="ffffff",
                        maxColor = "9e0000",
                        nullColor="919191") => {
        if (fcValue === null) {
            return "#"+nullColor;
        }
        let percent;
        if (fcValue === 0 || fcValue === 0.0) {
            return "#"+midColor;
        }
        if (fcValue < 0) {
            if (fcValue < (min)) {
                percent = 0;
            } else {
                percent = (1- (fcValue / (min) )  );
            }
        } else {
            if (fcValue > max) {
                percent = 1;
            } else {
                percent = (fcValue / max);
            }
        }
        const minRed = parseInt(minColor.substring(0,2), 16);
        const minGreen = parseInt(minColor.substring(2,4), 16);
        const minBlue = parseInt(minColor.substring(4,6), 16);
        const midRed = parseInt(midColor.substring(0,2), 16);
        const midGreen = parseInt(midColor.substring(2,4), 16);
        const midBlue = parseInt(midColor.substring(4,6), 16);
        const maxRed = parseInt(maxColor.substring(0,2), 16);
        const maxGreen = parseInt(maxColor.substring(2,4), 16);
        const maxBlue = parseInt(maxColor.substring(4,6), 16);
        let redDiffStr, greenDiffStr, blueDiffStr;
        if (fcValue < 0) {
            const redDiff = midRed - minRed;
            const greenDiff = midGreen - minGreen;
            const blueDiff = midBlue - minBlue;
            redDiffStr = ( ( redDiff * percent) + minRed).toString(16).split('.')[0];
            greenDiffStr = ( ( greenDiff * percent) + minGreen).toString(16).split('.')[0];
            blueDiffStr = ( ( blueDiff * percent) + minBlue).toString(16).split('.')[0]; 
        } else {
            const redDiff = maxRed - midRed;
            const greenDiff = maxGreen - midGreen;
            const blueDiff = maxBlue - midBlue;
            redDiffStr = ( ( redDiff * percent) + midRed).toString(16).split('.')[0];
            greenDiffStr = ( ( greenDiff * percent) + midGreen).toString(16).split('.')[0];
            blueDiffStr = ( ( blueDiff * percent) + midBlue).toString(16).split('.')[0]; 
        }
        //colors have to be 2 digits
        if (redDiffStr.length === 1) redDiffStr = '0' + redDiffStr;
        if (greenDiffStr.length === 1) greenDiffStr = '0' + greenDiffStr;
        if (blueDiffStr.length === 1) blueDiffStr = '0' + blueDiffStr;
        //assembly result
        const hexColor = '#' + redDiffStr + greenDiffStr + blueDiffStr;
        return hexColor;
    }
    */

/*
    const drawScale = (logScaled,
        label,
        scaleMin,
        scaleMax,
        minColor,
        midColor,
        maxColor,
        nullColor,
        xLeft,
        steps=9,
        totalHeight=200,
        totalWidth=10,
        //xMargin=20,
        yMargin=30,
        textOffset=10) => {
            const rectHeight = totalHeight/steps;
            const valueSteps = (scaleMax - scaleMin) / (steps-1);
            for (let i=0; i<steps; i++) {
                const value = scaleMin + (valueSteps * i);
                const color = colorScale(value,
                                         scaleMin,
                                         scaleMax,
                                         minColor,
                                         midColor,
                                         maxColor,
                                         nullColor);
                const yTop = yMargin - ((i+1)*rectHeight) + totalHeight;
                canvasContext.beginPath();
                canvasContext.fillStyle = color;
                canvasContext.fillRect(
                    xLeft+(totalWidth/4),
                    yTop,
                    totalWidth/2,
                    rectHeight+1
                );
            }
            let adjustedLabel =  label;
            if (logScaled) {
                adjustedLabel = 'log2('+label+')';
            }
            canvasContext.font = style.fontSize + 'px' + style.fontFamily;
            canvasContext.textAlign = 'center';
            canvasContext.textBaseLine = 'middle';
            //canvasContext.strokeStyle = 1;
            canvasContext.lineWidth = 1;
            //canvasContext.strokeWidth = 1;
            canvasContext.strokeText(
                scaleMax,
                xLeft+(totalWidth/2),
                yMargin-textOffset
            );
            canvasContext.strokeText(
                (scaleMax+scaleMin)/2,
                xLeft+(totalWidth/2),
                yMargin + ( (steps/2) *rectHeight)
            );
            canvasContext.strokeText(
                scaleMin,
                xLeft+(totalWidth/2),
                yMargin + (steps*rectHeight) + textOffset
            );
            canvasContext.font = (style.fontSize+2) + 'px' + style.fontFamily;
            canvasContext.strokeText(
                adjustedLabel,
                xLeft+(totalWidth/2),
                yMargin + (steps*rectHeight) + textOffset*2
            )
        }
        */

    const drawScales = () => {
        const xMargin = 10 * canvasDisplayScale;
        const precision = 3;
        const scaleWidth = legendWidth;
        const scaleHeight = 200 * canvasDisplayScale;
        const scaleSteps = 17;
        // Draw a scale for each datatype
        for (let i=0; i<datatypesInUse.length; i++) {
            const datatype = datatypesInUse[i];
            const scaleMax = props.scaleRange[datatype].toFixed(precision);
            const scaleMin = 0;
            const scaleMid = (props.scaleRange[datatype]/2).toFixed(precision);
            const xLeft = canvasRef.current.width - scaleWidth - xMargin - (scaleWidth * i);
            const logScaled = props.logScale[datatype];
            drawCountColorScale(
                canvasContext,
                style,
                datatype,
                allDatatypes,
                logScaled,
                datatype,
                scaleMin,
                scaleMid,
                scaleMax,
                xLeft,
                scaleSteps,
                scaleHeight,
                scaleWidth,
                30,
                10)
            //const {minColor, midColor, maxColor, nullColor} = getColorsheet(datatype);
            //    drawScale(logScaled,
            //        datatype,
            //        parseFloat(scaleMin),
            //        parseFloat(scaleMax),
            //        minColor,
            //        midColor,
            //        maxColor,
            //        nullColor,
            //        xLeft,
            //        scaleSteps,
            //        scaleHeight,
            //        scaleWidth);
            
        }
    }



    const handleClick = (e) => {
        //get mouse coordinates
        const mouseX = parseInt(e.nativeEvent.offsetX - canvasRef.current.clientLeft);
        const mouseY = parseInt(e.nativeEvent.offsetY - canvasRef.current.clientTop);
        //Iterate through all nodes and check if mouse is inside
        const margin = 3; //if you dont click correctly
        //ortholog nodes
        for (let i=0; i<props.renderData.orthologs.length; i++) {
            const node = props.renderData.orthologs[i];
            const {x, y, width, height} = node;
            if (mouseX >= x*style.scale &&
                mouseX <= x*style.scale + width*style.scale &&
                mouseY >= y*style.scale &&
                mouseY <= y*style.scale + height*style.scale) {
                props.orthologNodeClick(node);
                return;
            }
        }
        //compound nodes
        for (let i=0; i<props.renderData.compounds.length; i++) {
            const node = props.renderData.compounds[i];
            const {x, y, width, height} = node;
            if (mouseX >= x*style.scale - margin &&
                mouseX <= x*style.scale + width*style.scale + margin &&
                mouseY >= y*style.scale - margin &&
                mouseY <= y*style.scale + height*style.scale + margin) {
                props.compoundNodeClick(node);
                return;
            }
        }

        //map nodes
        for (let i=0; i<props.renderData.maps.length; i++) {
            const node = props.renderData.maps[i];
            const {x, y, width, height} = node;
            if (mouseX >= x*style.scale - margin &&
                mouseX <= x*style.scale + width*style.scale + margin &&
                mouseY >= y*style.scale - margin &&
                mouseY <= y*style.scale + height*style.scale + margin) {
                props.mapNodeClick(node);
                return;
            }
        }
    }

    const addEventListeners = () => {
        canvasRef.current.addEventListener('mousemove', function(event) {
            const rect = canvasRef.current.getBoundingClientRect();
            const x = (event.clientX - rect.left);
            const y = (event.clientY - rect.top);
            let hoveredOrthologNode = null;
            let hoveredCompoundNode = null;
            let hoveredMapNode = null;
            props.renderData.orthologs.forEach(node => {
                if (x >= node.x * style.scale &&
                    x <= node.x * style.scale + node.width * style.scale &&
                    y >= node.y * style.scale &&
                    y <= node.y * style.scale + node.height* style.scale) {
                    hoveredOrthologNode = node;
                    return true;
                }
                return false;
            });
            if (!hoveredOrthologNode) {
                props.renderData.compounds.forEach(node => {
                    if (x >= node.x * style.scale &&
                        x <= node.x * style.scale + node.width * style.scale &&
                        y >= node.y * style.scale &&
                        y <= node.y * style.scale + node.height* style.scale) {
                    hoveredCompoundNode = node;
                    return true;
                    }
                    return false;
                });
            }
            if (!(hoveredOrthologNode || hoveredCompoundNode)) {
                props.renderData.maps.forEach(node => {
                    if (x >= node.x * style.scale &&
                        x <= node.x * style.scale + node.width * style.scale &&
                        y >= node.y * style.scale &&
                        y <= node.y * style.scale + node.height* style.scale) {
                    hoveredMapNode = node;
                    return true;
                    }
                    return false;
                });
            }
            if (hoveredOrthologNode) {
                let content = '';
                Object.entries(hoveredOrthologNode.orthologies).forEach(([koNumber, values]) => {
                    content += `<div><span>${koNumber}</span>&emsp;<span><strong>${values.shorthand}</strong></span>&emsp;<span>${values.description}</span></div>`;
                });
                setTooltipContent(content);
                setTooltipVisible(true);
            } else if (hoveredCompoundNode) {
                setTooltipContent(hoveredCompoundNode.label + ' - click to view at kegg.jp');
                setTooltipVisible(true);
            } else if (hoveredMapNode) {
                setTooltipContent('Switch to pathway: '+hoveredMapNode.label);
                setTooltipVisible(true);
            } else {
                setTooltipContent(null);
                setTooltipVisible(false);
            }
        });
    }

    return (
        <div>
          <Tippy
            content={<div dangerouslySetInnerHTML={{__html: tooltipContent}} />}
            followCursor={true}
            plugins={[followCursor]}
            visible={tooltipVisible}
            >
          </Tippy>
          <canvas
              onClick={handleClick}
              ref={canvasRef}
            />
        </div>
      );

}

export default PathwayDiagram;