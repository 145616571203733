import React from "react";
import { addDecimalSeparators } from "../../functions/StringConversion";
import "../../css/general.css"

class DatasetInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="padded2">
                    <div className="infobox" style={{"width":this.props.leftBarWidth+"px"}}>
                        <div className="row">
                            <div className="wordbreakColumn">
                                <span style={{"fontWeight": "bold"}}>
                                    User:<br/>
                                    Dataset:<br/>
                                </span>
                            </div>
                            <div className="wordbreakColumn">
                                <span style={{"textAlign": "right"}}>
                                    {this.props.userName}<br/>
                                    {this.props.datasetId}<br/>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="padded2">
                    <div className="infobox" style={{"width":this.props.leftBarWidth+"px"}}>
                        <div className="row">
                            <div className="column">
                                <span style={{"fontWeight": "bold"}}>
                                    Total Genes:<br/>
                                    Assigned to KO:<br/>
                                    Assigned to MAG:<br/>
                                </span>
                            </div>
                            <div className="column">
                                <span style={{"textAlign": "right"}}>
                                    {addDecimalSeparators(this.props.totalGenes)}<br/>
                                    {addDecimalSeparators(this.props.genesWithKo)}<br/>
                                    {addDecimalSeparators(this.props.genesWithMag)}<br/>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="padded2">
                    <div className="menuBox padded4" style={{"width":this.props.leftBarWidth+"px"}}>
                        <div className="padded2">
                            <input className="button"
                                type="button"
                                onClick={this.props.rebuildDatabaseExperiment}
                                value="Analysis Database Rebuild"
                                style={{"width":this.props.leftBarWidth-4+"px"}}
                            />
                        </div>
                        <div className="padded2">
                            <input className="button"
                                type="button"
                                onClick={this.props.rebuildDatabaseAll}
                                value="Complete Database Rebuild"
                                style={{"width":this.props.leftBarWidth-4+"px"}}
                            />
                        </div>
                        <div className="padded2">
                            <input className="button"
                                type="button"
                                onClick={this.props.deleteDatabase}
                                value="Delete Database"
                                style={{"width":this.props.leftBarWidth-4+"px"}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DatasetInfo;