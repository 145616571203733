import React from "react";
import { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import LoadingPlaceholder from "../general/LoadingPlaceholder";

import { countColor, foldChangeColor } from "../../functions/Colors";

import "../../css/stickyTable.css";

function KoValuesTable(props) {

    const columns = useMemo(
        () => props.headers, [props.headers]);

/*    const data = useMemo(
        () => [
        {ko: 'K00001', eclist: ['3.2.1.1; 3.2.1.2'], description: 'alcohol dehydrogenase', max_dna_count: '0.542', max_rna_count: '116', max_dna_l2fc: '1.2', max_rna_l2fc: '5.9'},
        {ko: 'K10138', eclist: ['3.2.8.64'], description: 'bratinov bratagenose', max_dna_count: '2.8', max_dna_l2fc: '0.3174281'}
    ], []);
*/

    const data = useMemo(
        () => props.rows, [props.rows]
    );

    const precision = 5;

    
    //console.log("KOVALUETABLES: ColoringEnabled = " + props.coloringEnabled);
    //console.log("KOVALUETABLES: The headers i received were")
    //console.log(props.headers);
    //console.log("KOVALUETABLES: and the rows are");
    //console.log(props.rows);


    const tableInstance = useTable(
        {columns, data},
        useSortBy)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;


    
    const colorForCount = (value, datatype) => {
        if (props.coloringEnabled === false) {
            return {text: "black", background: "white"}
        }
        let txtColor, bgColor;
        const colorMax = props.scaleRange[datatype]
        let fraction = value / colorMax;
        //Calculate background color
        bgColor = countColor(
            value,
            0,
            colorMax,
            datatype,
            Object.keys(props.scaleRange).filter(key => key !== "FoldChange")
        )
        //Calculate text color
        if (fraction > 0.6) {
            txtColor = "white";
        } else {
            txtColor = "black";
        }
        /*
        if (fraction > 1) {
            fraction = 1;
        }
        if (datatype === Object.keys(props.scaleRange)[1]) { //Keys[0] is foldChange
            const green = 255;
            const blueRed = Math.round(255*(1-(1*fraction)));
            bgColor = ("#" + ((1 << 24) + (blueRed << 16) + (green << 8) + blueRed).toString(16).slice(1));
        } else if (datatype === Object.keys(props.scaleRange)[2]) {
            const red = 255;
            const green = Math.round(255*(1-(1*fraction)));
            bgColor = ("#" + ((1 << 24) + (red << 16) + (green << 8) + green).toString(16).slice(1));
            if (fraction > 0.5) {
                txtColor = "white";
            }
        } else if (datatype === Object.keys(props.scaleRange)[3]) {
            if (fraction>0.5) {
                txtColor = "white";
            }
            const green = 255*(1-fraction);
            const blueRed = Math.round(255*(1-(0.5*fraction)));
            bgColor = ("#" + ((1 << 24) + (blueRed << 16) + (green << 8) + blueRed).toString(16).slice(1));
        } else {
            if (fraction>0.5) {
                txtColor = "white";
            }
            const red = 255*(1-fraction);
            const green = Math.round(255*(1-(0.5*fraction)));
            bgColor = ("#" + ((1 << 24) + (red << 16) + (green << 8) + green).toString(16).slice(1));
        }
        */
        return {text: txtColor, background: bgColor}
    }

    const colorForFoldChange = (value, datatype) => {
        if (props.coloringEnabled === false) {
            return {text: "black", background: "white"}
        }
        let txtColor, bgColor;
        const colorMax = props.scaleRange["FoldChange"]
        let fraction = Math.abs(value) / colorMax;
        if (fraction > 0.6) {
            txtColor = "white";
        } else {
            txtColor = "black";
        }
        bgColor = foldChangeColor(
            value,
            -colorMax,
            colorMax,
            datatype,
            Object.keys(props.scaleRange).filter(key => key !== "FoldChange")
        )
        /*
        if (fraction > 1) {
            fraction = 1;
        }
        let txtColor;
        if (fraction > 0.5) {
            txtColor = "white";
        } else {
            txtColor = "black"
        }
        const green = Math.round(255*(1-(0.5*fraction)));
        let red, blue;
        if (value >= 0) {
            red = 255;
            blue = Math.round(255*(1-fraction));
        } else {
            red = Math.round(255*(1-fraction));
            blue = 255;
        }
        const bgColor = ("#" + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1));
        */
        return {text: txtColor, background: bgColor}
    }

    const getColor = (cell) => {
        if (cell.column.Header === "Gene") {
            if (cell.value == props.selectedGene) {
                return {text: "black", background: "lightblue"}
            }
        }
        if (cell.column.Header === "KEGG Orthology") {
            if (cell.value == props.selectedKo) {
                return {text: "black", background: "lightblue"}
            }
        }
        if (cell.column.colored === false || cell.value === undefined ) {
            return({text: "black", background: "white"});
        }
        if (cell.column.type == "count") {
            return(colorForCount(cell.value, cell.column.datatype));            
        }
        if (cell.column.type == "foldChange") {
            return(colorForFoldChange(cell.value, cell.column.datatype));
        }
    }

    if (props.loading) {
        return (
            <div>
                <table {...getTableProps()} style={{border: 'solid 1px blue', 'tableLayout': "fixed", "wordWrap": "break-word", "overflow": "hidden"}}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                style={{
                                background: '#5f8aa3',
                                color: 'white',
                                fontWeight: 'bold',
                                width: column.width,
                                }}
                            >
                            <span>
                                {column.isSorted ? (column.isSortedDesc ? "🔽 " : "🔼 ") : "🟦 "}
                            </span>
                                {column.render('Header')}
                            </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                </table>
                <LoadingPlaceholder/>
            </div>
        )
    }

    return (
        <div className="scrollable-table">
            <table {...getTableProps()}
            className = "table sticky"
            style={{border: 'solid 1px blue', 'tableLayout': "fixed", "wordWrap": "break-word"}}>
                <thead className="header">
                    {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                        <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            style={{
                            background: '#5f8aa3',
                            color: 'white',
                            fontWeight: 'bold',
                            width: column.width,
                            }}
                        >
                            <span>
                                {column.isSorted ? (column.isSortedDesc ? "🔽 " : "🔼 ") : "🟦 "}
                            </span>
                                {column.render('Header')}
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} style={{"overflow": "scroll", "height": "100px"}}>
                    {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} onClick={() => props.rowClick(row.original.gene, row.original.ko)}>
                        {row.cells.map(cell => {
                            let value = cell.value;
                            if (props.logScale[cell.column.datatype]) {
                                if (value === undefined) {
                                    value = undefined;
                                } else if (value < 1) {   
                                    value = 0;
                                } else {
                                    value = Math.log2(value);
                                }
                            }
                            if (typeof value === "number") {
                                value = parseFloat(value.toFixed(precision));
                            }
                            return (
                            <td
                                {...cell.getCellProps()}
                                style={{
                                padding: '10px',
                                border: 'solid 0.6px gray',
                                background: getColor(cell).background,
                                color: getColor(cell).text
                                }}
                            >
                                {value}
                            </td>
                            );
                        })}
                        </tr>
                    );
                    })}
                </tbody>
            </table>
        </div>
    );

}

// {cell.render('Cell')} statt {cell.value}

export default KoValuesTable;
