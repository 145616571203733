import React, { useState } from 'react';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css';

const TaxonomyOptions = (props) => {

    const [defaultValue, setDefaultValue] = useState([
        {label: "phylum", value: 1},
        {label: "class", value: 2},
        {label: "order", value: 3},
        {label: "family", value: 4},
        {label: "genus", value: 5},
    ]);

    const handleOnchange = indicesString => {
        let choices = indicesString.split(',');
        if (choices.length == 1 && choices[0] == "") {
            choices = [];
        }
        props.change(choices);
    }

    return (
        <div className="dropdown">
            <MultiSelect
                style={{
                    "paddingTop": "0px",
                    "--input-width": props.width,
                    "--font-size": "12px",
                    "--border-radius": "1px 1px 1px 1px",
                    "--line-height": "1",
                }}
                onChange={handleOnchange}
                options={props.options}
                singleSelect={false}
                clearable={false}
                largeData={false}
                defaultValue={defaultValue}
                placeholder={"Select..."}
            />
        </div>
    );
};

export default TaxonomyOptions;
