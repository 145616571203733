function makeScopeMagAbundanceTableRows(magAbundanceData) {
    const datatypeSeparator = ': ';
    const genericSeparator = '_';
    const precision = 5;
    const roundValue = (value) => {
        if (value === null) {
            return("n/a");
        } else {
            return(value.toFixed(precision));
        }
    }
    let tableData;
    if (Object.keys(magAbundanceData).length === 0) {
        tableData = [];
    } else {
        tableData = magAbundanceData.map((item) => {
            const {condition, name, percent_of_community, type} = item;
            const sampleName = type
                            + datatypeSeparator
                            + condition
                            + genericSeparator
                            + name;
            return {
                sample: sampleName,
                abundance: roundValue(percent_of_community),
            }
        });
    };
    return tableData
}

export {makeScopeMagAbundanceTableRows}
