import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './styles.css';
import 'react-tooltip/dist/react-tooltip.css'

//Authentication
import useToken from './components/authentication/UseToken';
import Login from './components/authentication/Login';

//pages
import Home from './components/home/Home';
import KeggModules from './components/moduleMaps/KeggModules';
//import Enzymes from './components/enzymes/Enzymes';
import Mags from './components/mags/Mags';
import KeggOrthologies from './components/orthologies/Orthologies';
import Pathways from './components/pathways/Pathways';
import Imprint from './components/imprint/Imprint';

//components
import TopNavbar from './components/TopNavbar';
import ContigViewer from './components/general/NewContigViewer';



//Set the app element of modal to root to make sure screenreaders etc cannot access the background when a modal is open
ReactModal.setAppElement('#root');

function App () {
  
  const {tokenData, refreshToken, setTokenData, isTokenExpired} = useToken();
  const flaskAdress = "/backend/";
  const fetchGetInit = {
    method: 'GET',
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + tokenData
    }
  }

  const AuthWrapper = () => {
    return isTokenExpired(localStorage.getItem('token'))
    ? <Login setTokenData={setTokenData} flaskAdress={flaskAdress} />
    : <Outlet />
  }

  const logout = () => {
    console.log("emptying token")
    setTokenData();
  };

  const [orthologies_selectedKo,orthologies_setSelectedKo] = useState("K00001");
  const [orthologies_selectedGene,orthologies_setSelectedGene] = useState(undefined);
  const [orthologies_searchTerm,orthologies_setSearchTerm] = useState(undefined);
  const [orthologies_columnHeaders,orthologies_setColumnHeaders] = useState([]);
  const [orthologies_searchHits,orthologies_setSearchHits] = useState([]);
  const [orthologies_countChoices,orthologies_setCountChoices] = useState([]);
  const [orthologies_comparisonChoices,orthologies_setComparisonChoices] = useState([]);
  const [orthologies_scaleRange,orthologies_setScaleRange] = useState({FoldChange: 3});
  const [orthologies_coloringEnabled,orthologies_setColoringEnabled] = useState(true);
  const [orthologies_koMagSampleSelection,orthologies_setKoMagSampleSelection] = useState("");
  const [orthologies_koMagTaxonomicLevel,orthologies_setKoMagTaxonomicLevel] = useState("");
  const [orthologies_koMagDatatype,orthologies_setKoMagDatatype] = useState(undefined);
  const [orthologies_toplistLength,orthologies_setToplistLength] = useState(100);
  const [orthologies_toplistType,orthologies_setToplistType] = useState(undefined);
  const [orthologies_toplistChoice,orthologies_setToplistChoice] = useState(undefined);
  const [orthologies_queryType,orthologies_setQueryType] = useState("search");
  const [orthologies_magFilterActive, orthologies_setMagFilterActive] = useState(false);
  const [orthologies_magFilterValue, orthologies_setMagFilterValue] = useState(undefined);
  const [orthologies_magFilterAbundance, orthologies_setMagFilterAbundance] = useState({});
  const [orthologies_showMagAbundance, orthologies_setShowMagAbundance] = useState(false);
  const [orthologies_scopeMagAbundanceTableRows, orthologies_setScopeMagAbundanceTableRows] = useState([]);
  
  const [modules_magFilterActive, modules_setMagFilterActive] = useState(false);
  const [modules_magFilterValue, modules_setMagFilterValue] = useState(undefined);
  const [modules_magFilterAbundance, modules_setMagFilterAbundance] = useState({});
  const [modules_showMagAbundance, modules_setShowMagAbundance] = useState(false);
  const [modules_scopeMagAbundanceTableRows, modules_setScopeMagAbundanceTableRows] = useState([]);

  return (
    <div className='App'>
      <BrowserRouter>
        {
          (!tokenData.token || tokenData.token === "null" || tokenData.token === "undefined") ?
            <Routes>
              <Route
                path="/imprint"
                element={
                  <Imprint/>
                }
              >
              </Route>
              <Route path="*" element={
                <div>
                  <div style={{alignSelf: 'flex-end', marginTop: -5, right: 25, position: 'absolute'}}>
                    <div className='imprintHeader'>
                      <a href="/imprint">Imprint</a>
                    </div>
                  </div>
                  <Login setTokenData={setTokenData} flaskAdress={flaskAdress} />
                </div>
              } />
            </Routes>
          :(
            <>
              <div style={{alignSelf: 'flex-end', marginTop: -5, right: 25, position: 'absolute'}}>
                <div style={{display: 'flex'}}>
                  <a href="/" className='imprintHeader' style={{marginRight: '10px'}} onClick={logout}>Logout</a>
                  <div className='imprintHeader'>
                    <a href="/imprint">Imprint</a>
                  </div>
                </div>
              </div>

              <TopNavbar />
              <Routes>
                <Route element={<AuthWrapper />}>
                  <Route
                    path="/"
                    element={ 
                      <Home  
                        token={tokenData.token}
                        flaskAdress={flaskAdress}
                        fetchGetInit={fetchGetInit}
                        setToken={setTokenData}
                        refreshToken={refreshToken}
                      />
                    } 
                  />
                </Route>
                <Route element={<AuthWrapper />}>
                  <Route
                    path="/imprint"
                    element={
                      <Imprint/>
                    }
                  />
                </Route>
                <Route path="/mgd" element={<MgdError />} />
                <Route element={<AuthWrapper />}>
                  <Route 
                    path="/kegg_modules"
                    element={
                      <KeggModules
                        token={tokenData.token}
                        flaskAdress={flaskAdress}
                        fetchGetInit={fetchGetInit}
                        setToken={setTokenData}
                        refreshToken={refreshToken}
                        magFilterActive={modules_magFilterActive}
                        setMagFilterActive={modules_setMagFilterActive}
                        magFilterValue={modules_magFilterValue}
                        setMagFilterValue={modules_setMagFilterValue}
                        magFilterAbundance={modules_magFilterAbundance}
                        setMagFilterAbundance={modules_setMagFilterAbundance}
                        showMagAbundance={modules_showMagAbundance}
                        setShowMagAbundance={modules_setShowMagAbundance}
                        scopeMagAbundanceTableRows={modules_scopeMagAbundanceTableRows}
                        setScopeMagAbundanceTableRows={modules_setScopeMagAbundanceTableRows}
                      />
                    }
                  />
                </Route>
                <Route element={<AuthWrapper />}>
                  <Route
                    path="/kegg_orthologies"
                    element={
                      <KeggOrthologies
                        token={tokenData.token}
                        flaskAdress={flaskAdress}
                        fetchGetInit={fetchGetInit}
                        setToken={setTokenData}
                        refreshToken={refreshToken}
                        selectedKo={orthologies_selectedKo}
                        setSelectedKo={orthologies_setSelectedKo}
                        selectedGene={orthologies_selectedGene}
                        setSelectedGene={orthologies_setSelectedGene}
                        searchTerm={orthologies_searchTerm}
                        setSearchTerm={orthologies_setSearchTerm}
                        columnHeaders={orthologies_columnHeaders}
                        searchHits={orthologies_searchHits}
                        setSearchHits={orthologies_setSearchHits}
                        setColumnHeaders={orthologies_setColumnHeaders}
                        countChoices={orthologies_countChoices}
                        setCountChoices={orthologies_setCountChoices}
                        comparisonChoices={orthologies_comparisonChoices}
                        setComparisonChoices={orthologies_setComparisonChoices}
                        scaleRange={orthologies_scaleRange}
                        setScaleRange={orthologies_setScaleRange}
                        coloringEnabled={orthologies_coloringEnabled}
                        setColoringEnabled={orthologies_setColoringEnabled}
                        koMagSampleSelection={orthologies_koMagSampleSelection}
                        setKoMagSampleSelection={orthologies_setKoMagSampleSelection}
                        koMagTaxonomicLevel={orthologies_koMagTaxonomicLevel}
                        setKoMagTaxonomicLevel={orthologies_setKoMagTaxonomicLevel}
                        koMagDatatype={orthologies_koMagDatatype}
                        setKoMagDatatype={orthologies_setKoMagDatatype}
                        toplistLength={orthologies_toplistLength}
                        setToplistLength={orthologies_setToplistLength}
                        toplistType={orthologies_toplistType}
                        setToplistType={orthologies_setToplistType}
                        toplistChoice={orthologies_toplistChoice}
                        setToplistChoice={orthologies_setToplistChoice}
                        queryType={orthologies_queryType}
                        setQueryType={orthologies_setQueryType}
                        magFilterActive={orthologies_magFilterActive}
                        setMagFilterActive={orthologies_setMagFilterActive}
                        magFilterValue={orthologies_magFilterValue}
                        setMagFilterValue={orthologies_setMagFilterValue}
                        magFilterAbundance={orthologies_magFilterAbundance}
                        setMagFilterAbundance={orthologies_setMagFilterAbundance}
                        showMagAbundance={orthologies_showMagAbundance}
                        setShowMagAbundance={orthologies_setShowMagAbundance}
                        scopeMagAbundanceTableRows={orthologies_scopeMagAbundanceTableRows}
                        setScopeMagAbundanceTableRows={orthologies_setScopeMagAbundanceTableRows}
                      />
                    } 
                  />
                </Route>
                {/* <Route element={<AuthWrapper />}>
                  <Route 
                    path="/enzymes"
                    element={<Enzymes token={tokenData.token} setToken={setTokenData} refreshToken={refreshToken}/>}
                    />
                </Route> */}
                <Route element={<AuthWrapper />}>
                  <Route
                    path="/mags"
                    element={
                      <Mags
                        token={tokenData.token}
                        flaskAdress={flaskAdress}
                        fetchGetInit={fetchGetInit}
                        setToken={setTokenData}
                        refreshToken={refreshToken}
                      />
                    }
                  />
                </Route>
                <Route element={<AuthWrapper />}>
                  <Route
                    path="/kegg_pathways"
                    element={
                      <Pathways
                        token={tokenData.token}
                        flaskAdress={flaskAdress}
                        fetchGetInit={fetchGetInit}
                        setToken={setTokenData}
                        refreshToken={refreshToken}
                      />
                    }
                  />
                </Route>
                <Route element={<AuthWrapper />}>
                  <Route
                    path="/devtest"
                    element={
                      <ContigViewer
                        token={tokenData.token}
                        data={{
                          contigId: "k141_1072",
                          mag: "80",
                          contigLength: 3000,
                          selectedGene:"k141_1072_3",
                          genes:[
                            { id: "k141_1072_2", start: 211, end: 518, ko: "K00001" },
                            { id: "k141_1072_3", start: 589, end: 771, ko: "K00002" },
                            { id: "k141_1072_4", start: 1112, end: 1403, ko: null },
                            { id: "k141_1072_5", start: 1503, end: 1880, ko: "K00184" },
                            { id: "k141_1072_6", start: 1903, end: 2580, ko: "K00111" },
                            { id: "k141_1072_7", start: 2903, end: 2999, ko: "K00112" },
                          ]
                        }}
                      />
                    }
                  />
                </Route>
                <Route path="*" element={<Error />} />
              </Routes>
            </>
          )
        }
      </BrowserRouter>
    </div>
  );
    
}

function Error() {
  return (
    <div style={{font: "400 20px Arial"}}>
      <br/>
      <h1>Invalid URL</h1>
    </div>
  )
}

function MgdError() {
  return (
    <h1>MGD</h1>
  )
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);