import React from "react";
import DatasetInfo from "./DatasetInfo";
import SampleInfo from "./SampleInfo";
import ComparisonInfo from "./ComparisonInfo";
import LoadingPlaceholder from "../general/LoadingPlaceholder";
import "../../css/general.css"

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.leftBarWidth = 250;
        this.flaskAdress = "/backend/";
        this.fetchGetInit = {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + this.props.token
            }
        }
        this.state = {
            dataVailable: false
        }
    }

    componentDidMount() {
        this.fetchDatasetSummary();
    }

    rebuildDatabaseExperiment() {
        alert("Account does not have permission for this action");
    }

    rebuildDatabaseAll() {
        alert("Account does not have permission for this action");
    }

    deleteDatabase() {
        alert("Account does not have permission for this action");
    }

    fetchDatasetSummary() {
        const summaryUrl = this.flaskAdress + "dataset_summary";
        fetch(summaryUrl, this.fetchGetInit)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Failed to fetch dataset summary: '+summaryUrl);
            }
        })
        .then (data => {
            this.updateDatasetSummary(data);
        })
        .catch(error => {
            console.log("HOME: Error when trying to fetch dataset summary: ");
            console.log(error);
        }
        );
    }

    updateDatasetSummary(data) {
        this.setState({
            ...this.state,
            userName: data.generalData.userName,
            datasetId: data.generalData.datasetId,
            totalGenes: data.generalData.totalGenes,
            genesWithKo: data.generalData.assignedToKo,
            genesWithMag: data.generalData.assignedToMag,
            conditionsData: data.conditionsData,
            comparisonsData: data.comparisonsData,
            allDatatypes: data.datatypes,
            dataAvailable: true
        });
    }

    render() {
        // const conditionsData = [ {condition: "Zebra", sampleDatatypes: {SampleA: {gene: ["dna", "rna", "protein"], ko: ["dna", "rna"]},
        // SampleB: {gene: ["dna", "protein"], ko: []}}, magDatatypes: ["dna","protein","rna"]}, {condition: "Loewe", sampleDatatypes: {SampleA: {gene: ["dna", "rna", "protein"], ko: ["dna", "rna"]},
        // SampleB: {gene: ["dna", "protein"], ko: []}}, magDatatypes: ["dna","protein","rna"]}]
        // const conditionsData = [
        //     {
        //         condition: "Zebra",
        //         geneKo: [{sampleName: "S_A1", gene: ["dna","rna","protein"], ko: ["dna","rna"]},
        //                        {sampleName: "S_A2", gene: ["dna","protein"], ko: []}],
        //         mag: [{sampleName: "MAGs", mag: ["dna","protein","rna"]}]
        //     },
        //     {
        //         condition: "Loewe",
        //         geneKo: [{sampleName: "S_A1", gene: ["dna","rna","protein"], ko: ["dna","rna"]},
        //                        {sampleName: "S_A2", gene: ["dna","protein"], ko: []}],
        //         mag: [{sampleName: "MAG abundance", mag: ["dna","protein","rna"]}]
        //     }
        // ] 
        // const comparisonsData = [
        //     {
        //         base_condition: "Zebra",
        //         experimental_condition: "Loewe",
        //         datatypes: {gene: ["dna", "rna"], ko: ["protein"]}
        //     }
        // ]
        if (this.state.dataAvailable) {
            return (
                <div className="center_flex">
                    <div>
                        <div>Dataset</div>
                        <div>
                            <DatasetInfo
                                leftBarWidth={this.leftBarWidth}
                                userName={this.state.userName}
                                datasetId={this.state.datasetId}
                                totalGenes={this.state.totalGenes}
                                genesWithKo={this.state.genesWithKo}
                                genesWithMag={this.state.genesWithMag}
                                rebuildDatabaseExperiment={this.rebuildDatabaseExperiment}
                                rebuildDatabaseAll={this.rebuildDatabaseAll}
                                deleteDatabase={this.deleteDatabase}
                            />
                        </div>
                    </div>
                    <div>
                        <div>Conditions</div>
                        <div>
                            {this.state.conditionsData.length > 0 ? (
                                this.state.conditionsData.map((conditionData) => (
                                    <div className="padded2" key={conditionData.condition}>
                                        <SampleInfo
                                            className="padded2"
                                            condition={conditionData.condition}
                                            sampleDatatypes={conditionData}
                                            allDatatypes={this.state.allDatatypes}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div className="padded2">
                                    <div className="infobox" style={{ minWidth: "250px", minHeight: "50px", textAlign: "center" }}>
                                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>No Data</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div>Comparisons</div>
                        <div>
                            {this.state.comparisonsData.length > 0 ? (
                                this.state.comparisonsData.map((comparisonData) => (
                                    <div className="padded2" key={comparisonData.base_condition + comparisonData.experimental_condition}>
                                        <ComparisonInfo
                                            className="padded2"
                                            data={comparisonData}
                                            allDatatypes={this.state.allDatatypes}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div className="padded2">
                                    <div className="infobox" style={{ minWidth: "250px", minHeight: "50px", textAlign: "center" }}>
                                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>No Data</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <LoadingPlaceholder/>
            )
        }
    }
}

export default Home;