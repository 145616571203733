import React from 'react';
import { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table'

import { countColor, foldChangeColor } from "../../functions/Colors";

import "../../css/stickyTable.css";

function MagTable(props) {

    const columns = useMemo(
        () => props.headers, [props.headers]
    );

    const data = useMemo(
        () => props.rows, [props.rows, props.headers]
    );

    const tableInstance = useTable(
        {columns, data}, useSortBy
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;

    const datatypeColor = (value, datatype) => {
        let txtColor, bgColor;
        const colorMax = props.maxRelAbundancePercent;
        let fraction = value / colorMax;
        //Calculate background color
        bgColor = countColor(
            value,
            0,
            colorMax,
            datatype,
            props.allDatatypes,
        )
        //Calculate text color
        if (fraction > 0.6) {
            txtColor = "white";
        } else {
            txtColor = "black";
        }
        return {txtColor: txtColor, bgColor: bgColor}
    }

/*
    const datatypeColor = (datatypeIndex, fraction) => {
        let green, red, blue, txtColor;
        if (datatypeIndex === 0) {
            green = 255
            red = Math.round( 255 * ( 1 - fraction ) );
            blue = Math.round( 255 * ( 1 - ( 1 * fraction ) ) );
            txtColor = "black"
        } else if (datatypeIndex === 1) {
            green = Math.round( 255 * ( 1 - ( 0.5 * fraction ) ) );
            red = 255;
            blue = Math.round( 255 * ( 1 - fraction ) );
            txtColor = fraction > 0.5 ? "white" : "black";
        } else if (datatypeIndex === 2) {
            blue = 255;
            red = Math.round( 255 * ( 1 - ( 0.5 * fraction ) ) );
            green = Math.round( 255 * ( 1 - ( 1 * fraction ) ) );
            txtColor = fraction > 0.5 ? "white" : "black";
        } else {
            red = Math.round( 255 * ( 1 - ( 1 * fraction ) ) );
            green = Math.round( 255 * ( 1 - ( 1 * fraction ) ) );
            blue = Math.round( 255 * ( 1 - ( 1 * fraction ) ) );
            txtColor = fraction > 0.5 ? "white" : "black";
        }
        return ( {
            red: red,
            green: green,
            blue: blue,
            txtColor: txtColor
        } )

    }
*/

    const getCellColor = (cell) => {
        if (cell.column.colored === false || cell.value === undefined) {
            return {text: "black", background: "white"}
        }
        // const { green, red, blue, txtColor } = datatypeColor(cell.column.datatypeIndex, fraction);
        const {txtColor, bgColor} = datatypeColor(cell.value, cell.column.datatype)
        // const bgColor = ("#" + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1));
        return {txtColor: txtColor, bgColor: bgColor}
    }



    return (
        <div className="scrollable-table">
            <table {...getTableProps()}
            className = "table sticky"
            style={{border: 'solid 1px blue', 'tableLayout': "fixed", "wordWrap": "break-word"}}>
                <thead className="header">
                    {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                        <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            style={{
                            background: '#5f8aa3',
                            color: 'white',
                            fontWeight: 'bold',
                            width: column.width,
                            }}
                        >
                            <span>
                                {column.isSorted ? (column.isSortedDesc ? "🔽 " : "🔼 ") : "🟦 "}
                            </span>
                                {column.render('Header')}
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} style={{"overflow": "scroll", "height": "100px"}}>
                    {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} onClick={() => props.rowClick(row.original.mag)}>
                        {row.cells.map(cell => {
                            const {txtColor, bgColor} = getCellColor(cell);
                            return (
                            <td
                                {...cell.getCellProps()}
                                style={{
                                    padding: '10px',
                                    border: 'solid 0.6px gray',
                                    background: bgColor,
                                    color: txtColor,
                                }}
                            >
                                {cell.render('Cell')}
                            </td>
                            );
                        })}
                        </tr>
                    );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default MagTable;