import React from 'react';
import ReactModal from 'react-modal';

import "../../css/general.css";

const ChoicesPopup = ({ isOpen, choices, onRequestClose, commitChoice }) => {
  return (
    <div>
        <ReactModal isOpen={isOpen} onRequestClose={onRequestClose}>
        <p className="header">Search Results</p>
        {/* Map over the choices and display each one */}
        {Object.entries(choices).map(([id, description]) => (
            <div style={{"marginBottom": "-5px", "display": "flex", "flexDirection": "row", "alignItems": "center"}} key={id}>
                {/* When the user clicks the "Choose" button, call the commitChoice function with the id as an argument */}
                <button
                    className="button"
                    style={{"marginRight": "10px","width": "100px"}}
                    onClick={() => commitChoice(id)}>
                        Choose
                </button>
                <p className="text16" style={{"align": "center"}}>
                    {`${id}: ${description}`}
                </p>
            </div>
        ))}
        {/* When the user clicks the "Cancel" button, call the onRequestClose function */}
        <br/>
        <button
            className="button"
            style={{"marginRight": "10px", "width": "100px"}}
            onClick={onRequestClose}>
                Cancel
            </button>
        </ReactModal>
    </div>
  );
};

export default ChoicesPopup;