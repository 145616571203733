import "../../css/general.css";
import React from 'react';
import ComparisonOptionsDropdown from "../general/ComparisonOptionsDropdown";

class MagColumnOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null
        }
    }

    makeMenuItems(itemList) {
        let results = [];
        for (let i=0; i<itemList.length; i++) {
            results[i] = {label: itemList[i], value: i};
        }
        return results;
    }

    render() {
        const datatypes = Object.keys(this.props.datatypeSamples)
        return (
            <div className="menuBox">
                { datatypes.map((datatype) => {
                    return (
                        <div key={datatype}>
                            <div className="padded4" style={{"font": "400 14px system-ui"}}>
                                {datatype}
                            </div>
                            <div>
                                <ComparisonOptionsDropdown
                                    singleSelect={false}
                                    width={this.props.width-4}
                                    menuItems={
                                        this.makeMenuItems(this.props.datatypeSamples[datatype])
                                    }
                                    change={(choices) => this.props.changeSelection(datatype, choices)}
                                />
                            </div>
                        </div>
                    )})}
            </div>
        )
    }
}

export default MagColumnOptions;