import React, { useEffect, useRef } from "react";
import '../../css/general.css';

import {
    countColor,
    foldChangeColor,
    drawFoldChangeColorScale,
    drawCountColorScale
} from "../../functions/Colors";

function ModuleDiagram(props) {
    //let cWidth = 500; //this is just a default and will be adjusted dynamically if too narrow
    //let cHeight = 900
    const canvas = useRef(); //We use reference instead of state
    let legendWidth = 70
    let canvasDisplayWidth = 300; //Default value - will dynamically adjust if too small
    let canvasDisplayHeight = 300; //Default value - will dynamically adjust if too small
    let canvasDisplayWidthMargin = 30 + legendWidth * ( Object.keys(props.scaleRange).length -1 ); //High, because we also need space for the colorscale
    let canvasDisplayHeightMargin = 20;
    const canvasDisplayScale = 1;
    //updateing a references does not trigger re-rendering
    //"References store infrastructure data, state stores information that is 
    //directly rendered."
    let canvasContext = null;
    let targetKo = null;
    const style = {
        scale: 1,
        targetOffset: 1,
        borderColor: "black",
        borderWidth: 3,
        fontSize: 12,
        fontFamily: 'Arial',
        textAlign: 'center',
        textBaseline: 'middle'
    };
    //let targetBox = null;
    /*const koBoxes = [
        { ko: "K00001", xleft: 10, xright: 80, ytop: 10, ybottom: 30, fcvalue: 2.3 },
        { ko: "K00002", xleft: 100, xright: 170, ytop: 10, ybottom: 30, fcvalue: -3.1},
        { ko: "K00003", xleft: 40, xright: 110, ytop: 40, ybottom: 60, fcvalue: 1.6},
        { ko: "K00004", xleft: 100, xright: 170, ytop: 70, ybottom: 90, fcvalue: 0.0001},
        { ko: "K00005", xleft: 500, xright: 570, ytop: 570, ybottom: 590, fcvalue: -1}
    ]*/
    //let koBoxes = [ { ko: "ModuleDiagram_DUMMY", xleft: 10, xright: 80, ytop: 10, ybottom: 30, fcvalue: 2.3 }]
    //koBoxes = props.moduleData;

    useEffect(() => {
        const canvasElement = canvas.current;
        if (!(props.renderData===undefined)) {
            setWidthAndHeight(canvasElement, props.renderData);
        }
        //canvasElement.width = cWidth;
        //canvasElement.height = cHeight;
        canvasContext = canvasElement.getContext("2d");
        targetKo = props.selectedKo;
        draw();
    });

    /*
    const comparisonColorsheet = [
        {
            minColor: "00379e",
            midColor: "ffffff",
            maxColor: "9e0000",
            nullColor: "919191"
        },
        {
            minColor: "006666",
            midColor: "ffffff",
            maxColor: "a5790a",
            nullColor: "919191"
        },
        {
            minColor: "670067",
            midColor: "ffffff",
            maxColor: "00CE00",
            nullColor: "919191"
        }
    ]
    const countColorsheet = [
        {
            minColor: "fbceb1",
            midColor: "ffffff",
            maxColor: "008210",
            nullColor: "919191"
        },
        {
            minColor: "fbceb1",
            midColor: "ffffff",
            maxColor: "9e0000",
            nullColor: "919191"
        },
        {
            minColor: "fbceb1",
            midColor: "ffffff",
            maxColor: "a5790a",
            nullColor: "919191"
        },
        {
            minColor: "fbceb1",
            midColor: "ffffff",
            maxColor: "00379e",
            nullColor: "919191"
        },
    ]
    */

    /* 
    useEffect(() => {
        console.log("Mounting Module Diagram, 1st UseEffect. Data is");
        console.log("props.moduleData:\n"+props.moduleData);
        //Runs code on any render of a component
        //Here we assign width and height of the canvas dynamically
        const canvasElement = canvas.current;
        canvasElement.width = canvasElement.clientWidth;
        canvasElement.height = canvasElement.clientHeight;
        //get context of the canvas elemtn
        canvasContext = canvasElement.getContext("2d");
        return () => {
            console.log("Unmounting Module Diagram, 1st UseEffect");
        };
    },[]);

    useEffect(() => {
        //console.log("Mounting Module Diagram, 2nd UseEffect");
        //call drawBoxes function whenever we render the component
        draw();
        //draw(props.moduleData, props.comparison);
        //return () => {
        //    console.log("Unmounting Module Diagram, 2nd UseEffect");
        //};
    },[]);*/

    const setWidthAndHeight = (canvasElement, renderData) => {
        for (let i=0; i<renderData.length; i++) {
            const right = renderData[i].xright;
            const bottom = renderData[i].ybottom;
            if (canvasDisplayWidth < (right + canvasDisplayWidthMargin)) {
                canvasDisplayWidth = right + canvasDisplayWidthMargin;
            }
            if (canvasDisplayHeight < (bottom + canvasDisplayHeightMargin)) {
                canvasDisplayHeight = bottom + canvasDisplayHeightMargin;
            }
        }
        canvasElement.style.width = canvasDisplayWidth + 'px';
        canvasElement.style.height = canvasDisplayHeight + 'px';
        canvasElement.width = canvasDisplayWidth * canvasDisplayScale;
        canvasElement.height = canvasDisplayHeight * canvasDisplayScale;
    }

    

    const draw = () => {
        if (props.renderData === undefined) {
            return
        }
        /*
        let colorsheet;
        if (props.contentType === "counts") {
            colorsheet = countColorsheet;
        } else if (props.contentType === "compare") {
            colorsheet = comparisonColorsheet;
        }
        */
        canvasContext = canvas.current.getContext("2d");
        //canvasContext.clearRect(0,0, canvas.current.clientWidth, canvas.current.clientHeight);
        canvasContext.clearRect(0,0, canvasDisplayWidth, canvasDisplayHeight);
        //props.moduleData.map(nodeData => drawKoBox(nodeData, props.comparison));
        props.renderData.map(nodeData => drawKoBox(nodeData));
        props.renderData.map(nodeData => drawKoSelector(nodeData));
            //props.moduleData.forEach(function(entry) {
            //    console.log("DRAWING");
            //    console.log(entry);
            //});
        //iterate through datatypes in props.scaleRange and draw a color scale
        //for each datatype
        drawScales()
        // for (let i=0; i<datatypes.length; i++) {
        //     const datatype = datatypes[i];
        //     const scaleMax = props.scaleRange[datatype].toFixed(precision);
        //     let scaleMin = 0;
        //     if (datatype === "FoldChange") {
        //         scaleMin = -scaleMax;
        //     }
        //     const xLeft = canvasDisplayWidth - scaleWidth - xMargin - (scaleWidth*i);
        //     const colorsheetIndex = i%colorsheet.length;
        //     const {minColor, midColor, maxColor, nullColor} = colorsheet[colorsheetIndex];
        //     drawScale(datatype,
        //               scaleMin,
        //               scaleMax,
        //               minColor,
        //               midColor,
        //               maxColor,
        //               nullColor,
        //               xLeft,
        //               scaleSteps,
        //               scaleHeight,
        //               scaleWidth
        //               )
        //     }
    }

    const drawScales = (
        xMargin=-10,
        precision=3,
        scaleWidth=legendWidth,
    ) => {
        const allDatatypes = Object.keys(props.scaleRange).filter(key => key !== "FoldChange");
        let datatypesInUse = [];
        for (let i=0; i<props.renderData.length; i++) {
            const nodeData = props.renderData[i];
            const datatypeValues = nodeData.datatypeValues;
            for (let k=0; k<datatypeValues.length; k++) {
                const datatype = datatypeValues[k].datatype;
                if (!(datatype === null)) {
                    if (!(datatypesInUse.includes(datatype))) {
                        datatypesInUse.push(datatype);
                    }
                }
            }
        }
        for (let i=0; i<datatypesInUse.length; i++) {
            const xLeft = canvasDisplayWidth - scaleWidth - xMargin - (scaleWidth*i);
            const datatype = datatypesInUse[i];
            if (props.contentType === "compare") {
                const scaleMax = props.scaleRange["FoldChange"].toFixed(precision);
                const scaleMin = (-props.scaleRange["FoldChange"]).toFixed(precision);
                const scaleMid = 0;
                const logScaled = false;
                drawFoldChangeColorScale(
                    canvasContext,
                    style,
                    datatype,
                    allDatatypes,
                    logScaled,
                    datatype,
                    scaleMin,
                    scaleMid,
                    scaleMax,
                    xLeft,
                    9,
                    200,
                    30,
                    30,
                    10,
                )
            } else if (props.contentType === "counts") {
                const scaleMax = props.scaleRange[datatype].toFixed(precision);
                const scaleMin = 0;
                const scaleMid = (props.scaleRange[datatype]/2).toFixed(precision);
                drawCountColorScale(
                    canvasContext,
                    style,
                    datatype,
                    allDatatypes,
                    props.logScaled,
                    datatype,
                    scaleMin,
                    scaleMid,
                    scaleMax,
                    xLeft,
                    9,
                    200,
                    30,
                    30,
                    10
                )
            } else {
                //Throw an exception because those are the only 2 legal contentTypes
                throw new Error("Illegal contentType: "+props.contentType);
            }
        }
    }
    /*
    const drawScales = () => {
        const xMargin = 20;
        const precision=3;
        const scaleWidth = legendWidth;
        const scaleHeight = 200;
        const scaleSteps = 17;
        // console.log("drawscales. scalerange is")
        // console.log(props.scaleRange)
        // iterate through props.renderData to find the datatypes that are used
        let datatypesInUse = []
        for (let i=0; i<props.renderData.length; i++) {
            const nodeData = props.renderData[i];
            const datatypeValues = nodeData.datatypeValues;
            for (let k=0; k<datatypeValues.length; k++) {
                const datatype = datatypeValues[k].datatype;
                if (!(datatype === null)) {
                    if (!(datatypesInUse.includes(datatype))) {
                        datatypesInUse.push(datatype);
                    }
                }
            }
        }
        for (let i=0; i<datatypesInUse.length; i++) {
            const datatype = datatypesInUse[i];
            let scaleMax;
            let scaleMin;
            if (props.contentType === "compare") {
                scaleMax = props.scaleRange["FoldChange"].toFixed(precision);
                scaleMin = -scaleMax;
            } else {
                scaleMax = props.scaleRange[datatype].toFixed(precision)
                scaleMin = 0;
            }
            scaleMin = scaleMin.toFixed(precision)
            const xLeft = canvasDisplayWidth - scaleWidth - xMargin - (scaleWidth*i);
            const {minColor, midColor, maxColor, nullColor} = getColorsheet(datatype);
            drawScale(datatype,
                      parseFloat(scaleMin),
                      parseFloat(scaleMax),
                      minColor,
                      midColor,
                      maxColor,
                      nullColor,
                      xLeft,
                      scaleSteps,
                      scaleHeight,
                      scaleWidth
                      )
            }
    }
    */

    /*
    const drawScale = (label,
                       scaleMin,
                       scaleMax,
                       minColor,
                       midColor,
                       maxColor,
                       nullColor,
                       xLeft,
                       steps=9,
                       totalHeigth=200,
                       totalWidth=10,
                       //xMargin=20,
                       yMargin=30,
                       textOffset=10) => {
        const rectHeight = totalHeigth/steps;
        const valueSteps = (scaleMax - scaleMin) / (steps-1);
        for (let i=0; i<steps; i++) {
            const value = scaleMin + (valueSteps * i);
            const color = colorScale(value,
                                     scaleMin,
                                     scaleMax,
                                     minColor,
                                     midColor,
                                     maxColor,
                                     nullColor);
            const yTop = yMargin - ((i+1)*rectHeight) + totalHeigth;
            canvasContext.beginPath();
            canvasContext.fillStyle = color;
            canvasContext.fillRect(
                xLeft+(totalWidth/4),
                yTop,
                totalWidth/2,
                rectHeight+1
            );
        }
        canvasContext.font = style.fontSize + 'px' + style.fontFamily;
        canvasContext.textAlign = 'center';
        canvasContext.textBaseLine = 'middle';
        //canvasContext.strokeStyle = 1;
        canvasContext.lineWidth = 1;
        //canvasContext.strokeWidth = 1;
        canvasContext.strokeText(
            scaleMax,
            xLeft+(totalWidth/2),
            yMargin-textOffset
        );
        canvasContext.strokeText(
            (scaleMax+scaleMin)/2,
            xLeft+(totalWidth/2),
            yMargin + ( (steps/2) *rectHeight)
        );
        canvasContext.strokeText(
            scaleMin,
            xLeft+(totalWidth/2),
            yMargin + (steps*rectHeight) + textOffset
        );
        canvasContext.font = (style.fontSize+2) + 'px' + style.fontFamily;
        canvasContext.strokeText(
            label,
            xLeft+(totalWidth/2),
            yMargin + (steps*rectHeight) + textOffset*2
        )
    }
    */

    /*
    const getColorsheet = (datatype) => {
        if (datatype === null) {
            return(comparisonColorsheet[0]);
        } else {
            const datatypes = Object.keys(props.scaleRange);
            let index = datatypes.indexOf(datatype) - 1; //because foldchange is at index 0
            if (props.contentType === "compare") {
                index = index%comparisonColorsheet.length;
                return(comparisonColorsheet[index]);
            } else {
                index = index%countColorsheet.length;
                return(countColorsheet[index]);
            }
        }
    }
    */

    const drawKoBox = (nodeData) => {
        const allDatatypes = Object.keys(props.scaleRange).filter(key => key !== "FoldChange");
        // console.log("Calling drawkobox with node data and scalerange:");
        // console.log(nodeData);
        const {ko, xleft, xright, ytop, ybottom, datatypeValues} = nodeData;
        // Each entry in datatypeValues is one section. Draw a rectangle for each section.
        const sectionWidth = (xright - xleft) / datatypeValues.length;
        for (let i=0; i<datatypeValues.length; i++) {
            const {datatype, value} = datatypeValues[i];
            let color;
            if (props.contentType === "compare") {
                const scaleMax = props.scaleRange["FoldChange"];
                const scaleMin = -scaleMax;
                color = foldChangeColor(
                    value,
                    scaleMin,
                    scaleMax,
                    datatype,
                    allDatatypes,
                );
            } else if (props.contentType === "counts") {
                const scaleMax = props.scaleRange[datatype];
                const scaleMin = 0;
                color = countColor(
                    value,
                    scaleMin,
                    scaleMax,
                    datatype,
                    allDatatypes,
                );
            } else {
                //Throw an exception because those are the only 2 legal contentTypes
                throw new Error("Illegal contentType: "+props.contentType);
            }
            const sectionXleft = xleft + (i*sectionWidth) -1
            const sectionXright = xleft + ((i+1)*sectionWidth)
            //Draw the rectangle
            canvasContext.beginPath();
            canvasContext.strokeStyle = style.borderColor;
            canvasContext.lineWidth = style.borderWidth;
            canvasContext.strokeWidth = style.borderWidth;
            canvasContext.fillStyle = color;
            canvasContext.fillRect(
                sectionXleft*style.scale,
                ytop*style.scale,
                (sectionXright*style.scale)-(sectionXleft*style.scale),
                (ybottom*style.scale)-(ytop*style.scale)
            );
            canvasContext.stroke();
        }
        /*
        for (let i=0; i<datatypeValues.length; i++) {
            const {datatype, value} = datatypeValues[i];
            //const {minColor, midColor, maxColor, nullColor} = getColorsheet(datatype);
            let valueMaximum;
            let valueMinimum;
            if (props.contentType === "compare") {
                valueMaximum = props.scaleRange["FoldChange"];
                valueMinimum = -valueMaximum;
            } else {
                valueMaximum = props.scaleRange[datatype];
                valueMinimum = 0;
            }
            const color = colorScale(value,
                                     valueMinimum,
                                     valueMaximum,
                                     minColor,
                                     midColor,
                                     maxColor,
                                     nullColor);
            // console.log("drawing node section with color")
            // console.log(color)
            const sectionXleft = xleft + (i*sectionWidth) -1
            const sectionXright = xleft + ((i+1)*sectionWidth)
            //Draw the rectangle
            canvasContext.beginPath();
            canvasContext.strokeStyle = style.borderColor;
            canvasContext.lineWidth = style.borderWidth;
            canvasContext.strokeWidth = style.borderWidth;
            canvasContext.fillStyle = color;
            canvasContext.fillRect(
                sectionXleft*style.scale,
                ytop*style.scale,
                (sectionXright*style.scale)-(sectionXleft*style.scale),
                (ybottom*style.scale)-(ytop*style.scale)
            );
            canvasContext.stroke();
        }
        */
        //Draw a thin rectangle around the box
        canvasContext.beginPath();
        canvasContext.strokeStyle = "grey";
        canvasContext.lineWidth = 2;
        canvasContext.borderWidth = 1;
        canvasContext.rect(
            xleft*style.scale,
            ytop*style.scale,
            (xright*style.scale)-(xleft*style.scale),
            (ybottom*style.scale)-(ytop*style.scale)
        );
        canvasContext.stroke();
        canvasContext.strokeStyle = style.borderColor;
        canvasContext.lineWidth = style.borderWidth;
        canvasContext.strokeWidth = style.borderWidth;
        //Draw the text
        const textX = xleft + ((xright-xleft)/2);
        const textY = ytop + ((ybottom-ytop)/2);
        canvasContext.font = style.fontSize + 'px ' + style.fontFamily;
        canvasContext.textAlign = style.textAlign;
        canvasContext.textBaseline = style.textBaseline;
        canvasContext.strokeText(ko, textX*style.scale, textY*style.scale);
        canvasContext.fillStyle = "white";
        canvasContext.fillText(ko, textX*style.scale, textY*style.scale);
        if (ko === targetKo) {
            canvasContext.beginPath()
            canvasContext.rect(
                ( xleft - style.targetOffset ) * style.scale,
                ( ytop - style.targetOffset ) * style.scale,
                ( ( xright - xleft ) + (2*style.targetOffset) ) * style.scale,
                ( ( ybottom - ytop ) + (2*style.targetOffset) ) * style.scale)
            canvasContext.stroke();
        }
    }

    // const drawKoBox = (nodeData) => {
    //     console.log("scalerange is");
    //     console.log(props.scaleRange);
    //     const {ko, xleft, xright, ytop, ybottom, datatypeValues} = nodeData;
    //     const sectionWidth = (xright-xleft)/(datatypeValues.length);
    //     //Draw all sections of the KO box
    //     for (var i=0; i<datatypeValues.length; i++) {
    //         const datatype = datatypeValues[i]["datatype"];
    //         const value = datatypeValues[i]["value"];
    //         const valueMaximum = props.scaleRange[datatype];
    //         let valueMinimum;
    //         if (props.contentType === "compare") {
    //             valueMinimum = -valueMaximum;
    //         } else {
    //             valueMinimum = 0;
    //         }
    //         const {minColor, midColor, maxColor, nullColor} = getColorsheet(datatype);
    //         const color = colorScale(value,
    //                                  valueMinimum,
    //                                  valueMaximum,
    //                                  minColor,
    //                                  midColor,
    //                                  maxColor,
    //                                  nullColor);
    //         const sectionXleft = xleft + (i*sectionWidth) -1
    //         const sectionXright = xleft + ((i+1)*sectionWidth)
    //         //Draw the rectangle
    //         canvasContext.beginPath();
    //         canvasContext.strokeStyle = style.borderColor;
    //         canvasContext.lineWidth = style.borderWidth;
    //         canvasContext.strokeWidth = style.borderWidth;
    //         canvasContext.fillStyle = color;
    //         canvasContext.fillRect(
    //             sectionXleft*style.scale,
    //             ytop*style.scale,
    //             (sectionXright*style.scale)-(sectionXleft*style.scale),
    //             (ybottom*style.scale)-(ytop*style.scale)
    //         );
    //         canvasContext.stroke();        }
    //     //Draw a thin rectangle around the box
    //     canvasContext.beginPath();
    //     canvasContext.strokeStyle = "grey";
    //     canvasContext.lineWidth = 2;
    //     canvasContext.borderWidth = 1;
    //     canvasContext.rect(
    //         xleft*style.scale,
    //         ytop*style.scale,
    //         (xright*style.scale)-(xleft*style.scale),
    //         (ybottom*style.scale)-(ytop*style.scale)
    //     );
    //     canvasContext.stroke();
    //     canvasContext.strokeStyle = style.borderColor;
    //     canvasContext.lineWidth = style.borderWidth;
    //     canvasContext.strokeWidth = style.borderWidth;
    //     //Draw the text
    //     const textX = xleft + ((xright-xleft)/2);
    //     const textY = ytop + ((ybottom-ytop)/2);
    //     canvasContext.font = style.fontSize + 'px ' + style.fontFamily;
    //     canvasContext.textAlign = style.textAlign;
    //     canvasContext.textBaseline = style.textBaseline;
    //     canvasContext.strokeText(ko, textX*style.scale, textY*style.scale);
    //     canvasContext.fillStyle = "white";
    //     canvasContext.fillText(ko, textX*style.scale, textY*style.scale);
    //     if (ko === targetKo) {
    //         canvasContext.beginPath()
    //         canvasContext.rect(
    //             ( xleft - style.targetOffset ) * style.scale,
    //             ( ytop - style.targetOffset ) * style.scale,
    //             ( ( xright - xleft ) + (2*style.targetOffset) ) * style.scale,
    //             ( ( ybottom - ytop ) + (2*style.targetOffset) ) * style.scale)
    //         canvasContext.stroke();
    //     }
    // }

    const drawKoSelector = (nodeData, style={}) => {
        const {ko, xleft, xright, ytop, ybottom, values} = nodeData;
        if (ko === targetKo) {
            canvasContext.beginPath();
            canvasContext.strokeStyle = style.borderColor;
            canvasContext.strokeWidth = style.borderWidth+3;
            canvasContext.rect(
                ( xleft - style.targetOffset ) * style.scale,
                ( ytop - style.targetOffset ) * style.scale,
                ( ( xright - xleft ) + (2*style.targetOffset) ) * style.scale,
                ( ( ybottom - ytop ) + (2*style.targetOffset) ) * style.scale);
            canvasContext.stroke();
        }
    }



/*
    const old_drawKoBox = (nodeData, style={}) => {
        const scale = 1;
        const targetOffset = 1;
        const {fontSize=14, fontFamily='Arial', textAlign='center', textBaseline='middle'} = style;
        const {ko, coordinates, fold_change_values, count_values} = nodeData;
        const {xleft, xright, ytop, ybottom} = coordinates;
        const values = fold_change_values //TEMP WORKAROUND
        const {datatype, base_condition, experimental_condition} = comparison;
        let targetValue = null;
        if (datatype in values) {
            targetValue = values[datatype][base_condition][experimental_condition];
        }
        let fcColor = "#919191"; //the "not found" color
        let fcvalue = null; 
        if (!(targetValue == null)) {
            fcvalue = targetValue["log2_fold_change"];
            fcColor = foldChangeColor(fcvalue);
        }
        const {borderColor = 'black', backgroundColor = fcColor, borderWidth=3} = style;
        //Draw the rectangle
        canvasContext.beginPath()
        canvasContext.strokeStyle = borderColor;
        canvasContext.lineWidth = borderWidth;
        canvasContext.fillStyle = backgroundColor;
        canvasContext.fillRect(xleft*scale, ytop*scale, (xright*scale)-(xleft*scale), (ybottom*scale)-(ytop*scale));
        canvasContext.stroke();
        //Draw the text
        const textX = xleft + ((xright-xleft)/2);
        const textY = ytop + ((ybottom-ytop)/2);
        canvasContext.font = fontSize + 'px ' + fontFamily;
        canvasContext.textAlign = textAlign;
        canvasContext.textBaseline = textBaseline;
        //let textColor = "white";
        //if (( (fcvalue>-1) && (fcvalue<1))) {
        //    textColor = "black";
        //}
        canvasContext.strokeText(ko, textX, textY);
        canvasContext.fillStyle = "white";
        canvasContext.fillText(ko, textX, textY);
        if (ko === targetKo) {
            canvasContext.beginPath()
            canvasContext.rect(
                ( xleft - targetOffset ) * scale,
                ( ytop - targetOffset ) * scale,
                ( ( xright - xleft ) + (2*targetOffset) ) * scale,
                ( ( ybottom - ytop ) + (2*targetOffset) ) * scale)
            canvasContext.stroke();
        }
    }
    */

    //function foldChangeColor(fcValue, minMax=3,
    //    minColor = "00379e", midColor="ffffff", maxColor = "9e0000")  {
        /*
    const colorScale = (fcValue,
                        min=-1,
                        max=1,
                        minColor = "00379e",
                        midColor="ffffff",
                        maxColor = "9e0000",
                        nullColor="919191") => {
        // console.log("calling colorscale with parameters")
        // console.log(fcValue)
        // console.log(min)
        // console.log(max)
        // console.log(minColor)
        // console.log(midColor)
        // console.log(maxColor)
        // console.log(nullColor)
        if (fcValue === null) {
            return "#"+nullColor;
        }
        let percent;
        if (fcValue === 0 || fcValue === 0.0) {
            return "#"+midColor;
        }
        if (fcValue < 0) {
            if (fcValue < (min)) {
                percent = 0;
            } else {
                percent = (1- (fcValue / (min) )  );
            }
        } else {
            if (fcValue > max) {
                percent = 1;
            } else {
                percent = (fcValue / max);
            }
        }
        const minRed = parseInt(minColor.substring(0,2), 16);
        const minGreen = parseInt(minColor.substring(2,4), 16);
        const minBlue = parseInt(minColor.substring(4,6), 16);
        const midRed = parseInt(midColor.substring(0,2), 16);
        const midGreen = parseInt(midColor.substring(2,4), 16);
        const midBlue = parseInt(midColor.substring(4,6), 16);
        const maxRed = parseInt(maxColor.substring(0,2), 16);
        const maxGreen = parseInt(maxColor.substring(2,4), 16);
        const maxBlue = parseInt(maxColor.substring(4,6), 16);
        let redDiffStr, greenDiffStr, blueDiffStr;
        if (fcValue < 0) {
            const redDiff = midRed - minRed;
            const greenDiff = midGreen - minGreen;
            const blueDiff = midBlue - minBlue;
            redDiffStr = ( ( redDiff * percent) + minRed).toString(16).split('.')[0];
            greenDiffStr = ( ( greenDiff * percent) + minGreen).toString(16).split('.')[0];
            blueDiffStr = ( ( blueDiff * percent) + minBlue).toString(16).split('.')[0]; 
        } else {
            const redDiff = maxRed - midRed;
            const greenDiff = maxGreen - midGreen;
            const blueDiff = maxBlue - midBlue;
            redDiffStr = ( ( redDiff * percent) + midRed).toString(16).split('.')[0];
            greenDiffStr = ( ( greenDiff * percent) + midGreen).toString(16).split('.')[0];
            blueDiffStr = ( ( blueDiff * percent) + midBlue).toString(16).split('.')[0]; 
        }
        //colors have to be 2 digits
        if (redDiffStr.length === 1) redDiffStr = '0' + redDiffStr;
        if (greenDiffStr.length === 1) greenDiffStr = '0' + greenDiffStr;
        if (blueDiffStr.length === 1) blueDiffStr = '0' + blueDiffStr;
        //assembly result
        const hexColor = '#' + redDiffStr + greenDiffStr + blueDiffStr;
        return hexColor;
    }
    */

    const boxHitScan = (x,y) => {
        let hit = false;
        for (let i=0; i<props.renderData.length; i++) {
            const node = props.renderData[i];
            if (x >= node.xleft && x <= node.xright && y >= node.ytop && y <= node.ybottom) {
                hit = true;
                targetKo = node.ko;
                break;
            }
        }
        return hit;
    }




/*
    const boxHitScan = (x,y) => {
        let hit = false;
        for (let i = 0; i < props.moduleData.length; i++) {
            const node = props.moduleData[i]
            const coordinates = node["coordinates"]
            if (x >= coordinates.xleft && x <= coordinates.xright && y >= coordinates.ytop && y <= coordinates.ybottom) {
                hit = true;
                targetKo = node.ko;
                break;
            }
        }
        return hit;
    }
    */

    //function handleCanvasClick(e) {
    const handleCanvasClick = e => {
        const mouseX = parseInt(e.nativeEvent.offsetX - canvas.current.clientLeft);
        const mouseY = parseInt(e.nativeEvent.offsetY - canvas.current.clientTop);
        const hit = boxHitScan(mouseX,mouseY);
        if (hit) {
            props.selectKo(targetKo);
            draw();
        } else {
            targetKo = null;
            props.selectKo(targetKo);
            draw();
        }
    }

    //console.log("Hello this is ModuleDiagram.jsx. My RenderData is:");
    //console.log(props.renderData);

    return (
        <div className="module_diagram">
            <div>
                <canvas 
                    onClick={handleCanvasClick}
                    ref={canvas}></canvas>
            </div>
        </div>
    )
}



export default ModuleDiagram;

// useEffect runs code on any render of a component