import "../../css/general.css";
import React from 'react';
import { Tooltip } from 'react-tooltip'

import MultiSelect from  'react-multiple-select-dropdown-lite';
import  'react-multiple-select-dropdown-lite/dist/index.css';

import { makeMenuItems } from "../../functions/StringConversion";
import { decodeComparisonChoices, decodeCountChoices } from "../../functions/StringConversion";


class DatatypeSelector extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            logScaling: {},
            rangeValues: {},
        }

        this.getMenuItems = this.getMenuItems.bind(this);
        this.handleRangeSubmit = this.handleRangeSubmit.bind(this);
        this.handleSelectionChange = this.handleSelectionChange.bind(this);

        this.makeMenuItems = makeMenuItems.bind(this);
    }

    getMenuItems(datatype) {
        //From the array props.items, extract all objects that have the datatype
        //specified by the argument, and return them as an array.
        const targetItems = this.props.itemsData.filter(item => item.datatype === datatype);
        let menuItems;
        if (this.props.itemsType === "counts") {
            menuItems = this.makeMenuItems(targetItems, "counts");
        } else if (this.props.itemsType === "compare") {
            menuItems = this.makeMenuItems(targetItems, "compare");
        } else {
            throw new Error("Invalid argument "+this.props.itemsType+" passed to DatatypeSelector as props.itemsType");
        }
        return(menuItems);
    }

    handleSelectionChange(datatype, indicesString) {
        if (indicesString === "") {
            this.props.changeDataChoices("counts", datatype, []);
            return;
        }
        const indices = indicesString.split(",").map(Number);
        if (this.props.itemsType === "counts") {
            const menuItems = this.getMenuItems(datatype);
            const newChoices = decodeCountChoices(indices, menuItems);
            this.props.changeDataChoices("counts", datatype, newChoices);
        } else if (this.props.itemsType === "compare") {
            const menuItems = this.getMenuItems(datatype);
            const newChoices = decodeComparisonChoices(indices, menuItems);
            this.props.changeDataChoices("compare", datatype, newChoices);
        } else {
            throw new Error("Invalid argument "+this.props.itemsType+" passed to DatatypeSelector as props.itemsType");
        }
    }

    handleRangeChange(event, datatype) {
        let newRangeValues = this.state.rangeValues;
        newRangeValues[datatype] = event.target.value;
        this.setState({
            ...this.state,
            rangeValues: newRangeValues,
        })
    }

    handleRangeSubmit(event, datatype) {
        const rangeValue = this.state.rangeValues[datatype]
        const validRangeValue = parseFloat(rangeValue);
        if (isNaN(validRangeValue) || validRangeValue<0) {
            alert("Please enter a positive number. Use '.' for decimals.");
        } else {
            this.props.changeRange(datatype, validRangeValue);
        }
    }

    render() {

        const foldChangeMode = (this.props.itemsType === "compare")
        //Set the datatypes. If props.itemsType is counts, remove "FoldChange"
        //from the list. Otherwise, just keep "FoldChange".
        const datatypes = Object.keys(this.props.rangeValues).filter(datatype => datatype !== "FoldChange");
        let singleSelect = false;
        if (this.props.singleSelect) {
            singleSelect = this.props.singleSelect;
        }
        const clearingHelper = (this.props.itemsType === "counts")
        let noData = false;
        if (datatypes.length === 0) {
            noData = true;
        }
        let processedDefaultValues;
        if (this.props.itemsType === "counts") {
            processedDefaultValues = makeMenuItems(this.props.defaultValue, "counts");
        } else if (this.props.itemsType === "compare") {
            processedDefaultValues = makeMenuItems(this.props.defaultValue, "compare");
        }
        console.log("the processed default values are")
        console.log(processedDefaultValues)
        return (
            <div>
                {!foldChangeMode ? null : 
                    <div className="padded4" style={{display: "flex", justifyContent: "flex-end", textAlign: "right", font: "400 14px system-ui", textAlign: "right"}}>
                        fold change colorscale&nbsp;
                        <input className="textInput"
                            type="text"
                            style={{width: "50px"}}
                            placeholder="max"
                            value={this.state.rangeValues["FoldChange"]}
                            onChange={(event) => this.handleRangeChange(event, "FoldChange")}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    this.handleRangeSubmit(event, "FoldChange");
                                }
                            }}
                        />
                        <button
                            className="button"
                            style={{width: "30px"}}
                            value="Set"
                            onClick={() => this.handleRangeSubmit(null, "FoldChange")}
                        >Set</button>
                    </div>
                }
                { noData ? 
                    <div style={{font: "400 14px system-ui"}}>
                        <br/>no data available for this module and scope<br/>&nbsp;
                    </div>
                :
                    null                    
                }
                { datatypes.map((datatype) => {
                    return (
                        <div key={datatype} style={{width: this.props.width-8}}>
                            <div className="padded2" style={{font: "400 14px system-ui"}}>
                                {datatype}
                            </div>
                            <div className="menuBox">

                                <div className="dropdown">
                                    { clearingHelper ? 
                                        <div>
                                            <span></span>
                                            <MultiSelect
                                                style={{
                                                    "paddingTop": "0px",
                                                    "--input-width": (this.props.width),
                                                    "--font-size": "12px",
                                                    "--border-radius": "1px 1px 1px 1px",
                                                    "--line-height": "1",
                                                }}
                                                onChange={(indices) => this.handleSelectionChange(datatype, indices)}
                                                options={this.getMenuItems(datatype)}
                                                singleSelect={false}
                                                clearable={false}
                                                largeData={false}
                                            />
                                        </div>
                                    :
                                        <div>
                                            <MultiSelect
                                            style={{
                                                "paddingTop": "0px",
                                                "--input-width": (this.props.width),
                                                "--font-size": "12px",
                                                "--border-radius": "1px 1px 1px 1px",
                                                "--line-height": "1",
                                            }}
                                            onChange={(indices) => this.handleSelectionChange(datatype, indices)}
                                            options={this.getMenuItems(datatype)}
                                            singleSelect={false}
                                            clearable={false}
                                            largeData={false}
                                            />
                                        </div>
                                    }
                                    
                                </div>
                                {foldChangeMode ? null : 
                                    <div style={{ display: "flex",
                                            justifyContent: "space-between",
                                            font: "400 14px system-ui" }}>
                                        <div data-tooltip-id="tooltip-log2">
                                            log2 
                                            <input
                                                type="checkbox"
                                                checked={this.props.logScale[datatype]}
                                                onChange={() => {this.props.changeLogScale(datatype, !this.props.logScale[datatype])}}
                                            />
                                        </div>
                                        <div data-tooltip-id="tooltip-colorscale">
                                            colorscale&nbsp;
                                            <input className="textInput"
                                                type="text"
                                                style={{width: "50px"}}
                                                placeholder="max"
                                                value={this.state.rangeValues[datatype]}
                                                onChange={(event) => this.handleRangeChange(event, datatype)}
                                                onKeyDown={(event) => {
                                                    if (event.key === "Enter") {
                                                        this.handleRangeSubmit(event, datatype);
                                                    }
                                                }}
                                            />
                                            <button
                                                className="button"
                                                style={{width: "30px"}}
                                                value="Set"
                                                onClick={() => this.handleRangeSubmit(null, datatype)}
                                            >
                                            Set
                                            </button>
                                        </div>
                                    
                                    </div>
                                }
                                    {this.props.subunitMediansInterface? 
                                        <div style={{textAlign: "right"}} data-tooltip-id="tooltip-subunit_medians">
                                            subunit medians
                                            <input
                                                type="checkbox"
                                                checked={this.props.subunitMedians[datatype]}
                                                onChange={() => {this.props.changeSubunitMedians(datatype)}}
                                            />
                                        </div>
                                    : null}
                                </div>
                        </div>
                    )
                }) }
                <Tooltip id="tooltip-log2" place="top" content="Apply log2-scaling when calculating color values." />
                <Tooltip id="tooltip-subunit_medians" place="top" html="Unchecked: Color depends on the sum of counts for all orthologies in a node. <br />Checked: When orthologies are subunits of the same enzyme, node color depends on their median count." />
                <Tooltip id="tooltip-colorscale" content="Colorscale will go from 0 up to this value." />
            </div>
        )
    }

}

export default DatatypeSelector;