function getStringSeparators() {
    return ({
        datatypeSeparator: ': ',
        conditionSeparator: '_vs_',
        genericSeparator: '|'
    });
}

function getComparisonValueTypes() {
    return ({
        primaryValueType: "shrunken_log2_fold_change",
        secondaryValueType: "log2_fold_change",
    })
}

function makeMenuItems(options, sampleType) {
    const {datatypeSeparator, conditionSeparator, genericSeparator} = getStringSeparators();
    if (options === undefined || options === null) {
        return([]);
    }
    let items = []
    for (var i=0; i<options.length; i++) {
        let label;
        if (sampleType === "counts") {
            const {datatype, condition, name} = options[i];
            label = datatype + datatypeSeparator + condition + genericSeparator + name;
        } else if (sampleType === "compare") {
            const {datatype, base_condition, experimental_condition} = options[i];
            label = datatype + datatypeSeparator + base_condition + conditionSeparator + experimental_condition;
        } else {
            const message = "Invalid argument "+sampleType+" passed to CountOptions as samples";
            throw message;
        }
        items[i] =  {label: label, value: i}
    }
    return(items);
}

function decodeCountChoices(indices, menuItems) {
    const {datatypeSeparator, conditionSeparator, genericSeparator} = getStringSeparators();
    let choices = [];
    for (var i=0; i<indices.length; i++) {
        let choice = menuItems[indices[i]];
        let datatype = choice.label.split(datatypeSeparator)[0];
        let condition = choice.label.split(datatypeSeparator)[1].split(genericSeparator)[0];
        let name = choice.label.split(datatypeSeparator)[1].split(genericSeparator)[1];
        choices[i] = {datatype: datatype, condition: condition, name: name};
    }
    return(choices);
}

function decodeComparisonChoices(indices, menuItems) {
    const {datatypeSeparator, conditionSeparator, genericSeparator} = getStringSeparators();
    const valueTypes = getComparisonValueTypes();
    let choices = [];
    for (var i=0; i<indices.length; i++) {
        let choice = menuItems[indices[i]];
        let datatype = choice.label.split(datatypeSeparator)[0];
        let base_condition = choice.label.split(datatypeSeparator)[1].split(conditionSeparator)[0];
        let experimental_condition = choice.label.split(datatypeSeparator)[1].split(conditionSeparator)[1];
        choices[i] = {
            datatype: datatype,
            base_condition: base_condition,
            experimental_condition: experimental_condition,
            primaryValueType: valueTypes.primaryValueType,
            secondaryValueType: valueTypes.secondaryValueType
        };
    }
    return(choices);
}

function addDecimalSeparators(number) {
    const asString = number.toString();
    return(asString.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
}



export {makeMenuItems, getStringSeparators, decodeCountChoices, decodeComparisonChoices, addDecimalSeparators};

/*
const {datatypeSeparator, conditionSeparator, genericSeparator} = getStringSeparators();
let decoded = [];
for (var i=0; i<indices.length; i++) {
    const index = indices[i];
    const item = menuItems[index];
    const label = item.label;
    const datatype = label.split(datatypeSeparator)[0];
    const condition = label.split(datatypeSeparator)[1];
    const name = label.split(datatypeSeparator)[2];
    const decodedItem = {datatype: datatype, condition: condition, name: name};
    decoded[i] = decodedItem;
}
return(decoded);
*/