import React from 'react';
import ReactModal from 'react-modal';
import "../../css/general.css";
import FancyGenericTable from './FancyGenericTable';

class TaxonomyPopup extends React.Component {

    constructor(props) {
        super(props);

        this.interfaceScale = 1.0;

        this.cellWidth = 70;

        this.scaleWidth = this.scaleWidth.bind(this);

        this.taxonomyTableHeader = [
            { Header: "Domain", accessor: "domain" },
            { Header: "Phylum", accessor: "phylum" },
            { Header: "Class", accessor: "class" },
            { Header: "Order", accessor: "order" },
            { Header: "Family", accessor: "family" },
            { Header: "Genus", accessor: "genus" },
            { Header: "Species", accessor: "species" },
        ];

        this.abundanceTableHeader = [
            { Header: 'Type', accessor: 'type' },
            { Header: 'Sample', accessor: 'sample' },
            { Header: 'Condition', accessor: 'condition' },
            { Header: 'Name', accessor: 'name' },
            { Header: '% of Community', accessor: 'percent_of_community' },
        ];

        this.magTableHeader = [
            { Header: 'MAG', accessor: 'mag' },
            { Header: 'Completeness', accessor: 'completeness' },
            { Header: 'Contamination', accessor: 'contamination' },
            { Header: 'Length', accessor: 'length' },
            { Header: 'Domain', accessor: 'domain' },
            { Header: 'Phylum', accessor: 'phylum' },
            { Header: 'Class', accessor: 'class' },
            { Header: 'Order', accessor: 'order' },
            { Header: 'Family', accessor: 'family' },
            { Header: 'Genus', accessor: 'genus' },
            { Header: 'Species', accessor: 'species' }
        ];

    }

    scaleWidth(width) {
        return (width * this.interfaceScale);
    }

    render() {

        if (this.props.data === undefined) {
            return (
                <div></div>
            )
        }
        //console.log("TAXONOMYPOPUP: Rendering TaxonomyPopup: ");
        //console.log(this.props.data);
        const {taxonomy, abundance, mags, name} = this.props.data;
        return (
            <ReactModal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.onRequestClose}
                contentLabel={"KEGG Orthology" + this.props.ko}
                style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.75)',
                      zIndex: 1000
                    },
                    content: {
                      display: "block",
                      top: "15%",
                      left: "15%",
                      bottom: "15%",
                      border: '1px solid #ccc',
                      background: '#fff',
                      overflow: 'auto',
                      WebkitOverflowScrolling: 'touch',
                      borderRadius: '4px',
                      outline: 'none',
                      padding: '20px',
                      width: "70%"
                    }
                  }}
            >
                <div className="text16"><h3>{name}</h3></div>
                <div className="text16">Taxonomy</div>
                <FancyGenericTable
                        header={this.taxonomyTableHeader}
                        data={[taxonomy]}
                        customWidth={Math.round(this.cellWidth*this.interfaceScale)}
                        key="quality"
                    />
                <div className="text16"><br/>Abundance</div>
                <FancyGenericTable
                        header={this.abundanceTableHeader}
                        data={abundance}
                        customWidth={Math.round(this.cellWidth*this.interfaceScale)}
                        key="taxonomy"
                    />
                <div className="text16"><br/>Related MAGs</div>
                <FancyGenericTable
                        header={this.magTableHeader}
                        data={mags}
                        customWidth={Math.round(this.cellWidth*this.interfaceScale)}
                        key="abundance"
                    />
            </ReactModal>
        );
    }
}

function toggleTaxonomyPopup(taxonomic_level, taxonomy) {
    if (taxonomy === 'unbinned') {
        alert("The 'unbinned' section represents non-binned contigs. It is not possible to view the taxonomy details these.")
        return
    }
    if (!this.state.taxonomyPopupEnabled) {
        this.fetchTaxonomyDetails(this.props.token, taxonomic_level, taxonomy);
    }
    this.setState({
        ...this.state,
        taxonomyPopupEnabled: !this.state.taxonomyPopupEnabled
    });
}

export { TaxonomyPopup, toggleTaxonomyPopup }

/*
                <div>
                    <div className="text16">MAG Quality</div>
                    <FancyGenericTable
                            header={this.qualityTableHeader}
                            data={magData.quality}
                            customWidth={false}
                            key="quality"
                    />
                </div>
                <div>
                    <div className="text16">MAG Taxonomy</div>
                    <FancyGenericTable
                            header={this.taxonomyTableHeader}
                            data={magData.taxonomy}
                            customWidth={false}
                            key="taxonomy"
                    />
                </div>
                <div>
                    <div className="text16">MAG Abundance</div>
                    <FancyGenericTable
                            header={this.abundanceHeader}
                            data={magData.abundance}
                            customWidth={false}
                            key="abundance"
                    />
                </div>
                */