import React from "react";
import { moduleLink } from "../../functions/Linkmaker";
import "../../css/general.css";

class ModuleIdForm extends React.Component {
    constructor(props) {
        super(props);
        this.highestModuleNumber = 99999;
        this.lowestModuleNumber = 0;
        this.state = {searchTerm: "M00001"};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.previousModule = this.previousModule.bind(this);
        this.nextModule = this.nextModule.bind(this);
        this.prevNextModule = this.prevNextModule.bind(this);
    }

    componentDidMount() {
    }

    setDescription() {
        /*
        console.log(this.props.moduleDescriptions);
        console.log(this.props.moduleId);
        const moduleDescriptions = this.props.moduleDescriptions;
        const moduleId = this.state.moduleId;
        let newDescription;
        if (moduleDescriptions === null || moduleDescriptions === undefined) {
            newDescription = moduleId;
        } else if (!moduleId in moduleDescriptions) {
            newDescription = moduleId;
        } else {
            newDescription = moduleId + ": " + moduleDescriptions[moduleId];
        }
        this.setState({
            ...this.state,
            description: newDescription
        })
        */
    }
    
    handleChange(e) {
        this.setState({searchTerm: e.target.value});
    }

    handleSubmit(e) {
        e.preventDefault();
         this.props.searchModule(this.state.searchTerm);
    }

    previousModule(e) {
        let i = -1;
        while (!this.prevNextModule(i)) {
            i--;
        }
    }

    nextModule(e) {
        let i = 1;
        while (!this.prevNextModule(i)) {
            i++;
        }
    }

    prevNextModule(increment) {
        const currentModuleId = this.props.moduleId;
        const currentModuleNumber = parseInt(currentModuleId.slice(1,));
        const newModuleNumber = (currentModuleNumber+increment).toString();
        if (newModuleNumber > this.highestModuleNumber || newModuleNumber < this.lowestModuleNumber) {
            return(true); //Dont do anything
        }
        const newModuleId = 'M' + (newModuleNumber.padStart(5,'0'));
        if (newModuleId in this.props.moduleDescriptions) {
            this.props.changeModule(newModuleId);
            return(true);
        } else {
            return(false);
        }
    }

    render() {
        return (
            <div className="infobox" style={{width: this.props.width}}>
                <form onSubmit={this.handleSubmit}>
                    <label>
                        <input
                            className="textInput"
                            type="text"
                            style={{width: this.props.width}}
                            value={this.state.moduleId}
                            onChange={this.handleChange} />
                    </label>
                    <br/>
                    <input
                        className="button"
                        type="submit"
                        style={{width: this.props.width}}
                        value="Search Module" />
                </form>
                <div>
                    <button className="button"  style={{width: this.props.width/2}} onClick={this.previousModule}>Previous ID</button>
                    <button className="button"  style={{width: this.props.width/2}} onClick={this.nextModule}>Next ID</button>
                </div>
                <div>
                    <p className="moduleDescriptionText"  style={{width: this.props.width-2}}>
                    {this.props.currentDescription}&nbsp;<a href={moduleLink(this.state.moduleId)}>view@KEGG</a>
                    </p>
                </div>
            </div>
        );
    }
}

export default ModuleIdForm;