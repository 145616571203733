import React from "react";
import "../../css/general.css"
import PlainTable from "../general/PlainTable";

import { countColor } from "../../functions/Colors";

class SampleInfo extends React.Component {
    constructor(props) {
        super(props);
        
        this.makeGeneKoTableHeaders = this.makeGeneKoTableHeaders.bind(this);
        this.makeMagTableHeaders = this.makeMagTableHeaders.bind(this);
    }

    tokenColor(datatype) {
        //Set values to get the 80% intensity color
        const value = .8;
        const maxValue = 1;
        const minValue = 0;
        //Retrieve color hexcode
        const color = countColor(
            value,
            minValue,
            maxValue,
            datatype,
            this.props.allDatatypes
            );
        return color;
    }

    makeGeneKoTableHeaders() {
        return([
            {
                Header: 'Sample', accessor: 'sampleName',
                Cell: tableProps => (<div>{tableProps.value}</div>)
            },
            {
                Header: () => (<div style={{textAlign:"right"}}>Gene</div>), accessor: 'gene',
                Cell: tableProps => (
                    tableProps.value.map((datatype, index) => {
                            const col = this.tokenColor(datatype)
                            return (<div
                                    key={datatype+index}
                                    style={{whiteSpace: "nowrap", borderColor: col}}
                                    className="token">
                                        {datatype}
                                    </div>)
                        })
                )
            },
            {
                Header: () => (<div style={{textAlign:"right"}}>KO</div>), accessor: 'ko',
                Cell: tableProps => (
                        tableProps.value.map((datatype, index) => {
                            const col = this.tokenColor(datatype)
                            return (<div
                                    key={datatype+index}
                                    style={{whiteSpace: "nowrap", borderColor: col}}
                                    className="token">
                                        {datatype}
                                    </div>)
                        })

                )
            }
        ])
    }

    makeMagTableHeaders() {
        return([
            {
                Header: '', accessor: 'sampleName',
                Cell: tableProps => (<div>{tableProps.value}</div>)
            },
            {
                Header: '', accessor: 'mag',
                Cell: tableProps => (
                        tableProps.value.map((datatype, index) => {
                            const col = this.tokenColor(datatype)
                            return (<span
                                    key={datatype+index}
                                    className="token"
                                    style={{whiteSpace: "nowrap", borderColor: col}}>
                                        {datatype}
                                    </span>)
                        })
                )
            }
        ])
    }

    render() {
        return(
            <div className="infobox" style={{"minWidth": "500px"}}>
                <span style={{"fontSize": "14px"}}>
                    Data for condition&nbsp;&nbsp;<span style={{"fontWeight": "600"}}>{this.props.condition}</span>
                </span>
                <br/><br/>
                <div>
                    <PlainTable key="geneKoTable"
                        header={this.makeGeneKoTableHeaders()}
                        data={this.props.sampleDatatypes.geneKo}
                        customWidth={false}
                        showHeader={true}
                    />
                </div>
                <br/>
                <div>
                    <PlainTable key="magTable"
                        data={this.props.sampleDatatypes.mag}
                        header={this.makeMagTableHeaders()}
                        customWidth={false}
                        showHeader={false}
                    />
                </div>
            </div>
        )
    }



 /*    render() {
        return (
            <div className="infobox" style={{"minWidth": "300px"}}>
                <span style={{"fontSize": "15px"}}>
                    Condition: {this.props.condition}
                </span>
                <br/>
                <div className="row">
                    <div className="noWordbreakColumn" style={{"minWidth": "100px"}}>
                        <span style={{"fontWeight": "bold"}}>
                            <span className="bold padded4">&nbsp;<br/></span>
                            {Object.keys(this.props.sampleDatatypes).map((sampleName) => {
                                return (
                                    <span className="bold padded4" key={sampleName}>
                                        {sampleName}<br/><br/>
                                    </span>
                                )
                            })}
                        </span>
                    </div>
                    <div className="noWordbreakColumn" style={{"minWidth": "100px", "textAlign": "right"}}>
                        <span>
                            <span className="bold padded8">Gene<br/></span>
                            {Object.keys(this.props.sampleDatatypes).map((sampleName) => {
                                const sampleData = this.props.sampleDatatypes[sampleName].gene;
                                return (
                                    <div key={"gene"+sampleName}>
                                        {sampleData.map((datatype) => {
                                            return (
                                                <div className="token" key={"gene"+sampleName+datatype}>
                                                    <div>
                                                        {datatype}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <br/><br/>
                                    </div>
                                )
                            })}
                        </span>
                    </div>
                    <div className="noWordbreakColumn" style={{"minWidth": "100px", "textAlign": "right"}}>
                        <span>
                            <span className="bold padded8">Orthology<br/></span>
                            {Object.keys(this.props.sampleDatatypes).map((sampleName) => {
                                const sampleData = this.props.sampleDatatypes[sampleName].ko;
                                console.log("sampledata is")
                                console.log(sampleData)
                                return (
                                    <div key={"ko"+sampleName}>
                                        {sampleData.map((datatype) => {
                                            console.log("datatype is")
                                            console.log(datatype)
                                            return (
                                                <div className="token" key={"ko"+sampleName+datatype}>
                                                    <div>
                                                        {datatype}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <br/><br/>
                                    </div>
                                )
                            })}
                        </span>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="wordbreakColumn">
                        <span className="bold padded4">
                            MAG Abundance
                        </span>
                    </div>
                    <div className="wordbreakColumn">
                        <span style={{"margin": "auto"}}>
                            {this.props.magDatatypes.map((datatype) => {
                                return (
                                    <div className="token" key={datatype}>
                                        <div>
                                            {datatype}
                                        </div>
                                    </div>
                                )
                            })}
                        </span>
                    </div>
                </div>
            </div>
        )
    } */
}

export default SampleInfo;